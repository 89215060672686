
<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="contact-dialog">
    <div class="contact__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{ 'GENERAL_SETTINGS_LABEL' | translate }}
        </div>
        <div class="desktop-close-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="contact__dialog-body">
        <div class="content">
            <mat-form-field>
                <mat-select [(ngModel)]="selectedLanguage" placeholder="{{ 'LANGUAGE_LABEL' | translate }}" (selectionChange)="updateOptions()">
                    <mat-option value="en">{{ 'ENGLISH_LABEL' | translate }}</mat-option>
                    <mat-option value="de">{{ 'DEUTSCH_LABEL' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="showServerSettings">
                    <input matInput class="form-control" placeholder="{{'SERVER_URL' | translate}}"
                    [(ngModel)]="serverURL" #serverurl="ngModel" name="serverurl"
                    autocomplete="nope" (change)="updateOptions()"/>
            </mat-form-field>
            <div *ngIf="!isCordova && isSupport2FA">
                <mat-checkbox color="primary" [(ngModel)]="use2FA">{{ 'ENABLE_TWO_FACTOR_AUTHENTICATION' | translate }}</mat-checkbox>
                <div *ngIf="!isCordova && use2FA && !is2FAEnabled">
                    <div class="start-authenticator-app" (click)="setAuthenticationApp()">
                        {{'START_AUTHENTICATOR_APP' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="contact__dialog-footer">
        <div class="desktop-footer">
            <button mat-button (click)="close()">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button (click)="saveSettings()">
                {{ 'SAVE' | translate }}
            </button>
        </div>
        <div class="mobile-footer" *ngIf="isUpdated">
            <div class="title">
                {{ 'SAVE' | translate }}
            </div>
            <div class="save_footer_btn">
                <button mat-button (click)="saveSettings()">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>