
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, OnDestroy, ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfirmDialogType } from "src/app/common/models/dialog.model";
import { Store } from "@ngrx/store";
import { RootState, getRouteId } from "src/app/reducers";
import { AppConstants } from "src/app/common/utils/app-constants";

@Component({
    selector: "vp-confirmation-dialog",
    templateUrl: "./confirmation-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConfirmationDialogComponent implements OnDestroy, OnInit {
    confirmationType: ConfirmDialogType;
    dialogType = ConfirmDialogType;
    private isAlive$ = new Subject<boolean>();
    message_title: string;
    message_body: string;
    isInTrash: boolean = false;
    total: any;
    duplicateTag: string = "EMAIL";
    constructor(
        private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        private broadcaster: Broadcaster,
        private store: Store<RootState>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.message_body = this.data.message_body;
        this.message_title = this.data.message_title;
        this.total = this.data.total;
        this.duplicateTag = this.data.duplicateTag;
        if (this.data && this.data.dialogType) {
            this.confirmationType = this.data.dialogType;
        }
    }

    close(value?: string): void {
        this.dialogRef.close({ confirmation: value });
    }

    ngOnInit() {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_CONFIRMATION_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(presence => {
            this.close();
        });
        this.store.select(getRouteId).pipe(takeUntil(this.isAlive$)).subscribe(getRouteId => {
            if (getRouteId === AppConstants.TRASH_CONTACT) {
                this.isInTrash = true;
            } else {
                this.isInTrash = false;
            }
        });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

}