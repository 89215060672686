
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { Update } from "@ngrx/entity/src/models";
import { AppActionTypes } from "../../../actions/app";
import { Contact } from "src/app/common/models";

export class GroupContactsAcTypes extends AppActionTypes {
// load Contacts
static LOAD_GROUP_CONTACTS = "[Contacts] Load group Contacts";
static LOAD_GROUP_CONTACTS_FAIL = "[Contacts] Load group Contacts Fail";
static LOAD_GROUP_CONTACTS_SUCCESS = "[Contacts] Load group Contacts Success";

// load nex Contacts
static NEXT_LOAD_GROUP_CONTACTS = "[Contacts] Next Load group Contacts";
static NEXT_LOAD_GROUP_CONTACTS_FAIL = "[Contacts] Next Load group Contacts Fail";
static NEXT_LOAD_GROUP_CONTACTS_SUCCESS = "[Contacts] Next Load group Contacts Success";

// create Contact
static CREATE_GROUP_CONTACT = "[Contacts] Create group Contact";
static CREATE_GROUP_CONTACT_FAIL = "[Contacts] Create group Contact Fail";
static CREATE_GROUP_CONTACT_SUCCESS = "[Contacts] Create group Contact Success";
// update Contact
static UPDATE_GROUP_CONTACT = "[Contacts] Update group Contact";
static UPDATE_GROUP_CONTACT_FAIL = "[Contacts] Update group Contact Fail";
static UPDATE_GROUP_CONTACT_SUCCESS = "[Contacts] Update group Contact Success";
// remove Contact
static REMOVE_GROUP_CONTACT = "[Contacts] Remove group Contact";
static REMOVE_GROUP_CONTACT_FAIL = "[Contacts] Remove group Contact Fail";
static REMOVE_GROUP_CONTACT_SUCCESS = "[Contacts] Remove group Contact Success";

static SEARCH_TOGGLE = "[Contacts] Toggle search mode";
static REMOVE_MANY_GROUP_CONTACT_SUCCESS = "[Contacts] Remove Many group Contact Success";

// remove Contact
static RESTORE_GROUP_CONTACT = "[Contacts] Restore group Contact";
static RESTORE_GROUP_CONTACT_FAIL = "[Contacts] Restore group Contact Fail";
static RESTORE_GROUP_CONTACT_SUCCESS = "[Contacts] Restore group Contact Success";

static START_GROUP_LOADING = "[Contacts] Start group Loading";
static STOP_GROUP_LOADING = "[Contacts] Stop group Loading";

static STORE_GROUP_SEARCHPARAMS = "[Contacts] Store group search parameter";

static EMPTY_GROUP_CONTACTS_SUCCESS = "[Contacts] Empty group Contacts";

static UPDATE_MANY_GROUP_CONTACT_SUCCESS = "[Contacts] Update Many group Contact Success";

}

export class LoadGroupContacts implements Action {
  readonly type = GroupContactsAcTypes.LOAD_GROUP_CONTACTS;
}

export class SetSearchMode implements Action {
  readonly type = GroupContactsAcTypes.SEARCH_TOGGLE;
  constructor(public payload: boolean) { }
}

export class StoreGroupSearchParams implements Action {
  readonly type = GroupContactsAcTypes.STORE_GROUP_SEARCHPARAMS;
  constructor(public payload: any) { }
}

export class LoadGroupContactsFail implements Action {
  readonly type = GroupContactsAcTypes.LOAD_GROUP_CONTACTS_FAIL;
}

export class LoadGroupContactsSuccess implements Action {
  readonly type = GroupContactsAcTypes.LOAD_GROUP_CONTACTS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreContacts: boolean, contact: Contact[] }) { }
}

export class NextLoadGroupContacts implements Action {
  readonly type = GroupContactsAcTypes.NEXT_LOAD_GROUP_CONTACTS;
}

export class NextLoadGroupContactsFail implements Action {
  readonly type = GroupContactsAcTypes.NEXT_LOAD_GROUP_CONTACTS_FAIL;
}

export class NextLoadGroupContactsSuccess implements Action {
  readonly type = GroupContactsAcTypes.NEXT_LOAD_GROUP_CONTACTS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreContacts: boolean, contacts: Contact[] }) { }
}

export class CreateGroupContact implements Action {
  readonly type = GroupContactsAcTypes.CREATE_GROUP_CONTACT;
}

export class CreateGroupContactFail implements Action {
  readonly type = GroupContactsAcTypes.CREATE_GROUP_CONTACT_FAIL;
}

export class CreateGroupContactSuccess implements Action {
  readonly type = GroupContactsAcTypes.CREATE_GROUP_CONTACT_SUCCESS;
  constructor(public payload: Contact) { }
}

export class UpdateGroupContact implements Action {
  readonly type = GroupContactsAcTypes.UPDATE_GROUP_CONTACT;
}

export class UpdateGroupContactFail implements Action {
  readonly type = GroupContactsAcTypes.UPDATE_GROUP_CONTACT_FAIL;
}

export class UpdateGroupContactSuccess implements Action {
  readonly type = GroupContactsAcTypes.UPDATE_GROUP_CONTACT_SUCCESS;
  constructor(public payload: Update<Contact>) { }
}

export class RemoveGroupContact implements Action {
  readonly type = GroupContactsAcTypes.REMOVE_GROUP_CONTACT;
}

export class RemoveGroupContactFail implements Action {
  readonly type = GroupContactsAcTypes.REMOVE_GROUP_CONTACT_FAIL;
}

export class RemoveGroupContactSuccess implements Action {
  readonly type = GroupContactsAcTypes.REMOVE_GROUP_CONTACT_SUCCESS;
  constructor(public payload: string) { }
}

export class RemoveMultipleGroupContactSuccess implements Action {
  readonly type = GroupContactsAcTypes.REMOVE_MANY_GROUP_CONTACT_SUCCESS;
  constructor(public payload: string[]) { }
}

export class EmptyGroupContactSuccess implements Action {
  readonly type = GroupContactsAcTypes.EMPTY_GROUP_CONTACTS_SUCCESS;
  constructor() { }
}

export class RestoreGroupContact implements Action {
  readonly type = GroupContactsAcTypes.RESTORE_GROUP_CONTACT;
}

export class RestoreGroupContactFail implements Action {
  readonly type = GroupContactsAcTypes.RESTORE_GROUP_CONTACT_FAIL;
}

export class RestoreGroupContactSuccess implements Action {
  readonly type = GroupContactsAcTypes.RESTORE_GROUP_CONTACT_SUCCESS;
  constructor(public payload: Contact[]) { }
}

export class StartGroupLoading implements Action {
  readonly type = GroupContactsAcTypes.START_GROUP_LOADING;
}

export class StopGroupLoading implements Action {
  readonly type = GroupContactsAcTypes.STOP_GROUP_LOADING;
}

export class UpdateMultipleGroupContactSuccess implements Action {
  readonly type = GroupContactsAcTypes.UPDATE_MANY_GROUP_CONTACT_SUCCESS;
  constructor(public payload: Update<Contact>[]) { }
}


export type ContactsAction =
  | LoadGroupContacts
  | LoadGroupContactsFail
  | LoadGroupContactsSuccess
  | NextLoadGroupContacts
  | NextLoadGroupContactsFail
  | NextLoadGroupContactsSuccess
  | CreateGroupContact
  | CreateGroupContactFail
  | CreateGroupContactSuccess
  | UpdateGroupContact
  | UpdateGroupContactFail
  | UpdateGroupContactSuccess
  | RemoveGroupContact
  | RemoveGroupContactFail
  | RemoveGroupContactSuccess
  | RemoveMultipleGroupContactSuccess
  | SetSearchMode
  | RestoreGroupContact
  | RestoreGroupContactFail
  | RestoreGroupContactSuccess
  | StartGroupLoading
  | StopGroupLoading
  | StoreGroupSearchParams
  | EmptyGroupContactSuccess
  | UpdateMultipleGroupContactSuccess;
