
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, OnDestroy, ChangeDetectionStrategy, Component, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RootState, getSearchInLists, getSearchInTags } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { ContactFolder } from "src/app/contacts/models/create-folder.model";
import * as _ from "lodash";
import { ContactTag } from "src/app/contacts/models/contact-tag.model";
import { getContactTags } from "src/app/contacts/store";
import { SetSearchInTags } from "src/app/actions/app";

@Component({
    selector: "vp-search-in-tags-dialog",
    templateUrl: "./search-in-tags-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchInTagsDialogComponent implements OnDestroy, OnInit {

    private isAlive$ = new Subject<boolean>();
    searchTags: ContactTag[] = [];
    allTags: ContactTag[] = [];
    selectType = "all-tag";
    isChangeValue: boolean = false;

    constructor(
        private dialogRef: MatDialogRef<SearchInTagsDialogComponent>,
        private broadcaster: Broadcaster,
        private store: Store<RootState>,
        private changeDetectionRef: ChangeDetectorRef
    ) {
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_SEACH_IN_TAGS_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(presence => {
            this.close();
        });
        this.store.select(getContactTags).pipe(takeUntil(this.isAlive$)).subscribe((contactTags: ContactTag[]) => {
            this.allTags = contactTags;
            this.changeDetectionRef.markForCheck();
        });
        this.store.select(getSearchInTags).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.searchTags = res;
            if (!!this.searchTags && this.searchTags.length === 0) {
                this.selectType = "all-tag";
            } else {
                this.selectType = "tags";
            }
            this.changeDetectionRef.markForCheck();
        });

    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    itemSelectionToggle(folder: ContactFolder, ev: any): void {
        if (!this.isSelected(folder)) {
            this.searchTags.push(folder);
        } else {
            const index = _.findIndex(this.searchTags, { id: folder.id });
            this.searchTags.splice(index, 1);
        }
        this.isChangeValue = true;
        this.changeDetectionRef.markForCheck();
    }

    submit(): void {
        const selectedTags = this.searchTags;
        this.broadcaster.broadcast("SEARCH_TAG_ITEMS", {
            data: selectedTags
        });
        if (this.selectType === "all-tag") {
            this.store.dispatch(new SetSearchInTags([]));
        } else {
            this.store.dispatch(new SetSearchInTags(selectedTags));
        }
        this.close();
    }

    radioButtonChange(ev: any): void {
        this.isChangeValue = true;
        this.changeDetectionRef.markForCheck();
    }

    isSelected(list: ContactFolder): boolean {
        let isSelectedTag: boolean = false;
        const folder = this.searchTags.filter(f => f.id === list.id)[0];
        if (!!folder) {
            isSelectedTag = true;
        }
        return isSelectedTag;
    }

}
