
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, NgZone, ChangeDetectorRef, Inject, ViewChild, ElementRef } from "@angular/core";
import { Contact } from "src/app/common/models";
import { Subject } from "rxjs";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { takeUntil } from "rxjs/operators";
import { CreateContactGroupDailogComponent } from "src/app/contacts/components/create-contact-group-dialog/create-contact-group-dialog.component";
import { ContactDetailDialogComponent } from "src/app/contacts/components/contact-detail-dialog/contact-detail-dialog.component";
import { Store } from "@ngrx/store";
import { ContactRootState } from "src/app/contacts/store";
import { RootState, getFederatedApps } from "src/app/reducers";
import { ContactRepository } from "src/app/contacts/repository/contact.repository";
import { CommonUtil } from "src/app/common/utils/common.utils";

@Component({
    selector: "vp-mobile-contact-group-detail-dialog",
    templateUrl: "./mobile-contact-group-detail-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileContactGroupDetailDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    contact: Contact = null;
    memberContact: Contact[] = [];
    isSearchView: boolean = false;
    searchText: string = "";
    fedrateApps: any;
    isShowSendEmailOption: boolean = true;
    isShowTalkOptions: boolean = true;
    isShowCalendarOption: boolean = true;
    isOnMobile: boolean;
    @ViewChild("searchTextInput", {static: false}) searchTextInput: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<MobileContactGroupDetailDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        private matDialog: MatDialog,
        private store: Store<ContactRootState | RootState>,
        private contactRepository: ContactRepository,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.isOnMobile = CommonUtil.isOnMobileDevice();
        if (this.data.contact && this.data.memberContacts) {
            this.contact = this.data.contact;
            this.memberContact = this.data.memberContacts;
            this.changeDetectorRef.markForCheck();
        }
    }

    ngOnInit(): void {
        this.store.select(getFederatedApps).pipe(takeUntil(this.isAlive$)).subscribe(apps => {
            console.log("[getFederatedApps]", apps);
            this.fedrateApps = apps;
            const isMailAppAvailable = this.fedrateApps.filter(fa => fa.name === "vncmail")[0];
            if (!!isMailAppAvailable) {
                this.isShowSendEmailOption = true;
            } else {
                this.isShowSendEmailOption = false;
            }
            const isTalkAppAvailable = this.fedrateApps.filter(fa => fa.name === "vnctalk")[0];
            if (!!isTalkAppAvailable) {
                this.isShowTalkOptions = true;
            } else {
                this.isShowTalkOptions = false;
            }
            const isCalendarAppAvailable = this.fedrateApps.filter(fa => fa.name === "vnccalendar")[0];
            if (!!isCalendarAppAvailable) {
                this.isShowCalendarOption = true;
            } else {
                this.isShowCalendarOption = false;
            }
            this.changeDetectorRef.markForCheck();
        });

        this.broadcaster.on<any>(BroadcastKeys.HIDE_MOBILE_CONTACT_GROUP_DETAIL_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });

        this.broadcaster.on<any>(BroadcastKeys.UPDATE_CONTACT_GROUP_MEMBER).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (!!res && res.contact) {
                const contact = <Contact> res.contact;
                const addIndex = this.memberContact.findIndex((e) => e.id === contact.id);
                if (addIndex !== -1) {
                    this.memberContact[addIndex] = contact;
                    this.changeDetectorRef.markForCheck();
                }
            }
        });

        this.broadcaster.on<any>(BroadcastKeys.DELETE_CONTACT_GROUP_MEMBER).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (!!res && res.contact) {
                const contact = <Contact> res.contact;
                const addIndex = this.memberContact.findIndex((e) => e.id === contact.id);
                if (addIndex !== -1) {
                    this.memberContact.splice(addIndex, 1);
                    this.changeDetectorRef.markForCheck();
                }
            }
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    editContactGroup(): void {
        this.close();
        this.matDialog.open(CreateContactGroupDailogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "create_contactgroup_dialog",
            disableClose: true,
            data: { isEdit: true, contact: this.contact, memberContacts: this.memberContact }
        });
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    openSearchView(value: boolean): void {
        if (value) {
            setTimeout(() => {
                this.searchTextInput.nativeElement.focus();
            }, 200);
        }
        this.isSearchView = value;
        this.changeDetectorRef.markForCheck();
    }

    emptySearchText(): void {
        this.searchText = "";
        this.changeDetectorRef.markForCheck();
    }

    openGroupContactDialog(): void {
        this.close();
        this.matDialog.open(CreateContactGroupDailogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "create_contactgroup_dialog",
            disableClose: true,
            data: { isEdit: true, contact: this.contact, memberContacts: this.memberContact }
        });
    }

    openContact(member: Contact): void {
        console.log("[openContact]: ", member);
        this.matDialog.open(ContactDetailDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            data: { contact: member },
            panelClass: "contact_detail_dialog"
        });
    }

    floatingOperation(operation: string, item: any): void {
        if (operation === "email") {
            this.contactRepository.sendEmail(this.memberContact);
        } else if (operation === "audioCall") {
            this.contactRepository.makeTalkAudioChatVideoOperation(this.memberContact, "audio-call", "group-audio");
        } else if (operation === "videoCall") {
            this.contactRepository.makeTalkAudioChatVideoOperation(this.memberContact, "video-call", "group-audio");
        } else if (operation === "chat") {
            this.contactRepository.makeTalkAudioChatVideoOperation(this.memberContact, "start-chat", "group-audio");
        } else if (operation === "event") {
            this.contactRepository.composeCalendarEvent(this.memberContact);
        } else if (operation === "broadcast") {
            this.contactRepository.makeBroadcastOperation(this.memberContact);
        } else if (operation === "shareScreen") {
            this.contactRepository.makeTalkAudioChatVideoOperation(this.memberContact, "screen-share", "group-screen");
        }
    }
}