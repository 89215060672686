/*
 * VNC Library
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

/* eslint-disable */
@Component({
    selector: 'vnc-icon-container',
    template: `
        <div class="vnc-icon-container" [matTooltip]="tooltip" [ngClass]="classes" (click)="onClick($event)">
            <ng-container>
                <ng-content></ng-content>
            </ng-container>
        </div>
   `,
    styleUrls: ['./icon-container.component.scss']
})

    /* eslint-enable */

export class VNCIconContainerComponent implements OnInit {
    @Input() isClickable = true;
    @Input() hoverClass = "";
    @Input() removeClickAnimation = true;
    @Input() stopPropagation: boolean = true;
    @Input() tooltip;

    @Output() click = new EventEmitter<any>();
    classes: string[] = [];

    constructor() { }

    ngOnInit(): void {
        this.classes = this.getClasses();
    }

    onClick(event: Event) {
        //0 - disable propagation
        if (this.stopPropagation) {event.stopPropagation();}

        //1 - if clickable then emit click event
        if (this.isClickable) {
            this.click.emit(event);
        }
    }

    public getClasses(): string[] {
        if (this.isClickable) {
            if (this.removeClickAnimation) {
                return ["clickable remove-animation", `${this.hoverClass}`];
            } else {
                return ["clickable", `${this.hoverClass}`];
            }
        } else {
            return [`${this.hoverClass}`];
        }
    }
}
