
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "src/app/common/providers/config.service";
import { Observable } from "rxjs";
import { ContactService } from "./contact-service";

@Injectable()
export class TFAService {

  constructor(private http: HttpClient,
    private config: ConfigService,
    private contactService: ContactService) {
  }

  enable2FA(secret: string): Observable<any> {
    let data = {"user": {"totp_secret": secret, "tfa_enabled": "true" }};
    return this.http.post(this.config.API_URL + "/api/2fa/config",
      data, { headers: this.getHeaders() });
  }

  disable2FA() {
    let data = {"user": {"totp_secret": null, "tfa_enabled": "false"}};
    return this.http.post(this.config.API_URL + "/api/2fa/config",
      data, { headers: this.getHeaders() });
  }

  verifyToken(otpCode) {
    let data = {};
    return this.http.post(this.config.API_URL + "/api/2fa/verify/" + otpCode,
      data, { headers: this.getHeaders() });
  }

  get2FAStatus() {
    return this.http.get(this.config.API_URL + "/api/2fa/status", { headers: this.getHeaders() });
  }

  getHeaders() {
    return this.contactService.getHeaders();
  }

  getUrl() {
    return this.contactService.getUrl();
  }
}
