
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { Update } from "@ngrx/entity/src/models";
import { AppActionTypes } from "../../../actions/app";
import { Contact } from "src/app/common/models";

export class TrashContactsAcTypes extends AppActionTypes {
// load Contacts
static LOAD_TRASH_CONTACTS = "[Contacts] Load trash Contacts";
static LOAD_TRASH_CONTACTS_FAIL = "[Contacts] Load trash Contacts Fail";
static LOAD_TRASH_CONTACTS_SUCCESS = "[Contacts] Load trash Contacts Success";

// load nex Contacts
static NEXT_LOAD_TRASH_CONTACTS = "[Contacts] Next Load trash Contacts";
static NEXT_LOAD_TRASH_CONTACTS_FAIL = "[Contacts] Next Load trash Contacts Fail";
static NEXT_LOAD_TRASH_CONTACTS_SUCCESS = "[Contacts] Next Load trash Contacts Success";

// create Contact
static CREATE_TRASH_CONTACT = "[Contacts] Create trash Contact";
static CREATE_TRASH_CONTACT_FAIL = "[Contacts] Create trash Contact Fail";
static CREATE_TRASH_CONTACT_SUCCESS = "[Contacts] Create trash Contact Success";
// update Contact
static UPDATE_TRASH_CONTACT = "[Contacts] Update trash Contact";
static UPDATE_TRASH_CONTACT_FAIL = "[Contacts] Update trash Contact Fail";
static UPDATE_TRASH_CONTACT_SUCCESS = "[Contacts] Update trash Contact Success";
// remove Contact
static REMOVE_TRASH_CONTACT = "[Contacts] Remove trash Contact";
static REMOVE_TRASH_CONTACT_FAIL = "[Contacts] Remove trash Contact Fail";
static REMOVE_TRASH_CONTACT_SUCCESS = "[Contacts] Remove trash Contact Success";

static SEARCH_TOGGLE = "[Contacts] Toggle search mode";
static REMOVE_MANY_TRASH_CONTACT_SUCCESS = "[Contacts] Remove Many trash Contact Success";

// remove Contact
static RESTORE_TRASH_CONTACT = "[Contacts] Restore trash Contact";
static RESTORE_TRASH_CONTACT_FAIL = "[Contacts] Restore trash Contact Fail";
static RESTORE_TRASH_CONTACT_SUCCESS = "[Contacts] Restore trash Contact Success";

static START_TRASH_LOADING = "[Contacts] Start trash Loading";
static STOP_TRASH_LOADING = "[Contacts] Stop trash Loading";

static STORE_TRASH_SEARCHPARAMS = "[Contacts] Store trash search parameter";

static EMPTY_TRASH_CONTACTS_SUCCESS = "[Contacts] Empty trash Contacts";

static UPDATE_MANY_TRASH_CONTACT_SUCCESS = "[Contacts] Update Many trash Contact Success";

}

export class LoadTrashContacts implements Action {
  readonly type = TrashContactsAcTypes.LOAD_TRASH_CONTACTS;
}

export class SetSearchMode implements Action {
  readonly type = TrashContactsAcTypes.SEARCH_TOGGLE;
  constructor(public payload: boolean) { }
}

export class StoreTrashSearchParams implements Action {
  readonly type = TrashContactsAcTypes.STORE_TRASH_SEARCHPARAMS;
  constructor(public payload: any) { }
}

export class LoadTrashContactsFail implements Action {
  readonly type = TrashContactsAcTypes.LOAD_TRASH_CONTACTS_FAIL;
}

export class LoadTrashContactsSuccess implements Action {
  readonly type = TrashContactsAcTypes.LOAD_TRASH_CONTACTS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreContacts: boolean, contact: Contact[] }) { }
}

export class NextLoadTrashContacts implements Action {
  readonly type = TrashContactsAcTypes.NEXT_LOAD_TRASH_CONTACTS;
}

export class NextLoadTrashContactsFail implements Action {
  readonly type = TrashContactsAcTypes.NEXT_LOAD_TRASH_CONTACTS_FAIL;
}

export class NextLoadTrashContactsSuccess implements Action {
  readonly type = TrashContactsAcTypes.NEXT_LOAD_TRASH_CONTACTS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreContacts: boolean, contacts: Contact[] }) { }
}

export class CreateTrashContact implements Action {
  readonly type = TrashContactsAcTypes.CREATE_TRASH_CONTACT;
}

export class CreateTrashContactFail implements Action {
  readonly type = TrashContactsAcTypes.CREATE_TRASH_CONTACT_FAIL;
}

export class CreateTrashContactSuccess implements Action {
  readonly type = TrashContactsAcTypes.CREATE_TRASH_CONTACT_SUCCESS;
  constructor(public payload: Contact) { }
}

export class UpdateTrashContact implements Action {
  readonly type = TrashContactsAcTypes.UPDATE_TRASH_CONTACT;
}

export class UpdateTrashContactFail implements Action {
  readonly type = TrashContactsAcTypes.UPDATE_TRASH_CONTACT_FAIL;
}

export class UpdateTrashContactSuccess implements Action {
  readonly type = TrashContactsAcTypes.UPDATE_TRASH_CONTACT_SUCCESS;
  constructor(public payload: Update<Contact>) { }
}

export class RemoveTrashContact implements Action {
  readonly type = TrashContactsAcTypes.REMOVE_TRASH_CONTACT;
}

export class RemoveTrashContactFail implements Action {
  readonly type = TrashContactsAcTypes.REMOVE_TRASH_CONTACT_FAIL;
}

export class RemoveTrashContactSuccess implements Action {
  readonly type = TrashContactsAcTypes.REMOVE_TRASH_CONTACT_SUCCESS;
  constructor(public payload: string) { }
}

export class RemoveMultipleTrashContactSuccess implements Action {
  readonly type = TrashContactsAcTypes.REMOVE_MANY_TRASH_CONTACT_SUCCESS;
  constructor(public payload: string[]) { }
}

export class EmptyTrashContactSuccess implements Action {
  readonly type = TrashContactsAcTypes.EMPTY_TRASH_CONTACTS_SUCCESS;
  constructor() { }
}

export class RestoreTrashContact implements Action {
  readonly type = TrashContactsAcTypes.RESTORE_TRASH_CONTACT;
}

export class RestoreTrashContactFail implements Action {
  readonly type = TrashContactsAcTypes.RESTORE_TRASH_CONTACT_FAIL;
}

export class RestoreTrashContactSuccess implements Action {
  readonly type = TrashContactsAcTypes.RESTORE_TRASH_CONTACT_SUCCESS;
  constructor(public payload: Contact[]) { }
}

export class StartTrashLoading implements Action {
  readonly type = TrashContactsAcTypes.START_TRASH_LOADING;
}

export class StopTrashLoading implements Action {
  readonly type = TrashContactsAcTypes.STOP_TRASH_LOADING;
}

export class UpdateMultipleTrashContactSuccess implements Action {
  readonly type = TrashContactsAcTypes.UPDATE_MANY_TRASH_CONTACT_SUCCESS;
  constructor(public payload: Update<Contact>[]) { }
}


export type ContactsAction =
  | LoadTrashContacts
  | LoadTrashContactsFail
  | LoadTrashContactsSuccess
  | NextLoadTrashContacts
  | NextLoadTrashContactsFail
  | NextLoadTrashContactsSuccess
  | CreateTrashContact
  | CreateTrashContactFail
  | CreateTrashContactSuccess
  | UpdateTrashContact
  | UpdateTrashContactFail
  | UpdateTrashContactSuccess
  | RemoveTrashContact
  | RemoveTrashContactFail
  | RemoveTrashContactSuccess
  | RemoveMultipleTrashContactSuccess
  | SetSearchMode
  | RestoreTrashContact
  | RestoreTrashContactFail
  | RestoreTrashContactSuccess
  | StartTrashLoading
  | StopTrashLoading
  | StoreTrashSearchParams
  | EmptyTrashContactSuccess
  | UpdateMultipleTrashContactSuccess;
