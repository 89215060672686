import { EMPTY, Observable } from "rxjs";
import { DatabaseInterface } from "./database.interface";

export class DumbDBService implements DatabaseInterface {
  dbName = "ContactsPlusDatabase";

  dbVersion = 1;
  pendingOperations = [];

  constructor() {
    console.log("[dumbDBService][constructor]");
  }

  deleteContacts(ids: any[]): Observable<any> {
    return EMPTY;
  }

  deleteContact(contact: any): Observable<any> {
    return EMPTY;
  }

  getContactId(id: string): Observable<any> {
    return EMPTY;
  }
  createOrUpdateContacts(contacts: any[]): Observable<any> {
    return EMPTY;
  }
  fetchContacts(global?: boolean, deleted?: boolean): Observable<any> {
    return EMPTY;
  }
  fetchAllContacts(): Observable<any> {
    return EMPTY;
  }
  getContactCountInDatabase(): Observable<any> {
    return EMPTY;
  }

  storeAvatar(avatarB64Url: string, email: string): Observable<any> {
    return EMPTY;
  }

  deleteAvatar(email: string): Observable < any > {
      return EMPTY;
    }

  getAvatarByEmail(email: string): Observable<any> {
      return EMPTY;
    }

  fetchAllAvatarFromDatabase(): Observable < any[] > {
      return EMPTY;
    }

  createOrUpdateContactFolders(folders: any[]): Observable<any> {
    return EMPTY;
  }

  getContactLists(): Observable<any[]> {
    return EMPTY;
  }

  getContactListById(id): Observable<any[]> {
    return EMPTY;
  }

  getContactsByList(id): Observable<any[]> {
    return EMPTY;
  }

  createOrUpdateContactGroup(groups: any[]): Observable<any> {
    return EMPTY;
  }

  getAllContactGroup(): Observable<any[]> {
    return EMPTY;
  }

  getContactGroup(id: string): Observable<any> {
    return EMPTY;
  }

  getAllContactIds(): Observable<any[]> {
    return EMPTY;
  }

  addPendingOp(op: any, type: string): Observable<any> {
    return EMPTY;
  }

  addPendingOps(ops: any[], type: string): Observable<any> {
    return EMPTY;
  }

  getAllPendingOperations(): Observable<any[]> {
    return EMPTY;
  }

  getPendingOpsByContact(id): Observable<any[]> {
    return EMPTY;
  }

  deletePendingOpById(id): Observable<any> {
    return EMPTY;
  }

  initDatabase() {
  }

}