
<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-apps-dialog contact-dialog">
  <div class="contact__dialog-header">
    <div class="mobile-back-button">
      <button id="mobile_sidebar_about_backbtn" mat-button (click)="close()">
        <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
      </button>
    </div>
    <div id="mobile_sidebar_about_header" class="header_lbl disable-select">
      {{ 'APPS' | translate }}
    </div>
  </div>
  <div class="contact__dialog-body">
    <div>
      <vp-app-switcher (closeSwitch)="close()" [switchApp]="true"></vp-app-switcher>
    </div>
  </div>
</div>