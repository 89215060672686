
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "./index";
import { UserProfile } from "../shared/models/user-profile";
import { AppState } from "../reducers/app";
import { Contact } from "../common/models";
import { ContactFolder } from "../contacts/models/create-folder.model";
import { ContactTag } from "../contacts/models/contact-tag.model";

export class AppActionTypes {
  static LOGIN_REQUEST = "[App] Login Request";
  static LOGOUT = "[App] logout";
  static SET_SIDEBAR_STATUS = "[App] Expand-Collapse Sidebar";
  static SET_USER_PROFILE = "[App] Set User Profile";
  static APP_ONLINE = "[App] App Online";
  static DEVICE_READY = "[App] Device Ready";

  static RESTORE_SAVED_STATE = "[App] App Restore saved state";
  static SET_AVAILABLE_APPS = "[App] set available apps";

  static SET_LOGIN_STATUS = "[App] App set Login status";
  static SET_ROUTING_TYPE = "[App] App set Routing type";
  static SET_ROUTE_ID = "[App] App set Route id";

  static SET_FAVORITE_GROUP = "[APP] App set favorite Group";

  static SET_LOGGEDIN_USER_CONTACT = "[APP] App set Logged in user contact";

  static SET_STATISTICS = "[APP] App set Statistics";

  static SET_SEARCH_IN_APPS = "[APP] App set search in apps";
  static SET_SEARCH_IN_LISTS = "[APP] App set search in lists";
  static SET_SEARCH_IN_TAGS = "[APP] App set search in tags";

  static SET_SEARCH_TYPE = "[APP] App set search type";
  static SET_SEARCH_QUERY = "[APP] App set search query";

  static SET_SAVE_SEARCH = "[APP] App set save search";

  static SET_QUERY_FILTERS = "[APP] App set query filters";

  static SET_FILTER_FOR_SEARCH = "[APP] App set filter for search";
  static SET_CONTACTS_COUNT  = "[APP] Update contacts count";
}


export class Logout implements Action {
  readonly type = AppActionTypes.LOGOUT;
}

export class LoginRequest implements Action {
  readonly type = AppActionTypes.LOGIN_REQUEST;
}

export class SetSidebarStatus implements Action {
  readonly type = AppActionTypes.SET_SIDEBAR_STATUS;
  constructor(public payload: boolean) {
  }
}

export class SetUserProfile implements Action {
  readonly type = AppActionTypes.SET_USER_PROFILE;
  constructor(public payload: UserProfile) {
  }
}

export class OnlineStatus implements Action {
  readonly type = AppActionTypes.APP_ONLINE;
  constructor(public payload: boolean) {
  }
}

export class DeviceReady implements Action {
    readonly type = AppActionTypes.DEVICE_READY;
    constructor() {

    }
}

export class RestoreSavedState implements Action {
  readonly type = AppActionTypes.RESTORE_SAVED_STATE;

  constructor(public payload: {
    appState: AppState
  }) {
  }
}

export class SetLoginStatus implements Action {
  readonly type = AppActionTypes.SET_LOGIN_STATUS;
  constructor(public payload: boolean) {
  }
}

export class SetAvailableApps implements Action {
  readonly type = AppActionTypes.SET_AVAILABLE_APPS;

  constructor(public payload: any[]) {
  }
}

export class SetRoutingType implements Action {
  readonly type = AppActionTypes.SET_ROUTING_TYPE;
  constructor(public payload: string) {
  }
}

export class SetRouteId implements Action {
  readonly type = AppActionTypes.SET_ROUTE_ID;
  constructor(public payload: string) {
  }
}

export class SetFavoriteGroup implements Action {
  readonly type = AppActionTypes.SET_FAVORITE_GROUP;

  constructor(public payload: any) {
  }
}

export class SetLoggedInUserContact implements Action {
  readonly type = AppActionTypes.SET_LOGGEDIN_USER_CONTACT;
  constructor(public payload: Contact) {
  }
}

export class SetStatistics implements Action {
  readonly type = AppActionTypes.SET_STATISTICS;
  constructor(public payload: any) {
  }
}

export class SetContactsCount implements Action {
  readonly type = AppActionTypes.SET_CONTACTS_COUNT;
  constructor(public payload: any) {
  }
}

export class SetSearchInApp implements Action {
  readonly type = AppActionTypes.SET_SEARCH_IN_APPS;
  constructor(public payload: any) {
  }
}

export class SetSearchInLists implements Action {
  readonly type = AppActionTypes.SET_SEARCH_IN_LISTS;
  constructor(public payload: ContactFolder[]) {
  }
}

export class SetSearchInTags implements Action {
  readonly type = AppActionTypes.SET_SEARCH_IN_TAGS;
  constructor(public payload: ContactTag[]) {
  }
}

export class SetSearchType implements Action {
  readonly type = AppActionTypes.SET_SEARCH_TYPE;
  constructor(public payload: string) {
  }
}

export class SetSearchQuery implements Action {
  readonly type = AppActionTypes.SET_SEARCH_QUERY;
  constructor(public payload: string) {
  }
}

export class SetSaveSearch implements Action {
  readonly type = AppActionTypes.SET_SAVE_SEARCH;
  constructor(public payload: any[]) {
  }
}

export class SetQueryFilters implements Action {
  readonly type = AppActionTypes.SET_QUERY_FILTERS;
  constructor(public payload: any[]) {
  }
}

export class SetFilterForSearch implements Action {
  readonly type = AppActionTypes.SET_FILTER_FOR_SEARCH;
  constructor(public payload: any[]) {
  }
}
