<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="contact-dialog">
    <div class="contact__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{ 'UNDO_CHANGES' | translate }}
        </div>
        <div class="desktop-close-button">
        </div>
    </div>
    <div class="contact__dialog-body">
        <div class="content">
            <div class="content-div">
                <div class="text-div">
                    <span class="change_content_list_text">
                        {{ 'CAHNGE_CONTENT_LIST_BACK_DESCRIPTION' | translate }}
                        <mat-icon class="mdi-20px help-icon" fontSet="mdi" fontIcon="mdi-help-circle-outline">
                        </mat-icon>
                    </span>
                </div>
            </div>
            <div class="content-div margin-30">
                <div class="text-div"><span class="undo_changes_from">{{ 'UNDO_CHANGES_FROM' | translate }}</span></div>
                <mat-radio-group [(ngModel)]="undoChangeTime" (change)="changeRadioButton()">
                    <mat-radio-button value="10m">{{ '10_MIN_AGO' | translate }}</mat-radio-button>
                    <mat-radio-button value="1h">{{ '1_H_AGO' | translate }}</mat-radio-button>
                    <mat-radio-button value="yesterday">{{ 'YESTERDAY' | translate }}</mat-radio-button>
                    <mat-radio-button value="1w">{{ '1_W_AGO' | translate }}</mat-radio-button>
                    <mat-radio-button value="custom">{{ 'CUSTOM_LBL' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="content-div date-all-items" *ngIf="undoChangeTime === 'custom'">
                <div class="date-item">
                    <mat-form-field floatLabel="never">
                        <input matInput maxlength="2" [(ngModel)]="days" placeholder="{{'DAYS' | translate}}" />
                    </mat-form-field>
                </div>
                <div class="date-item">
                    <mat-form-field floatLabel="never">
                        <input matInput maxlength="2" [(ngModel)]="hours" placeholder="{{'HOURS' | translate}}" />
                    </mat-form-field>
                </div>
                <div class="date-item">
                    <mat-form-field floatLabel="never">
                        <input matInput maxlength="2" [(ngModel)]="minutes" placeholder="{{'Minutes' | translate}}" />
                    </mat-form-field>
                </div>
            </div>
            <span *ngIf="showTimePeriodError" class="warning-msg">
                {{ 'PAST_30_DAY_ERROR_MSG' | translate }}
            </span>
        </div>
    </div>
    <div class="contact__dialog-footer">
        <div class="desktop-footer">
            <button mat-button (click)="close()">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button (click)="confirmChange()">
                {{ 'CONFIRM' | translate }}
            </button>
        </div>
        <div class="mobile-footer" *ngIf="isChangeValue">
            <div class="title">
                {{ 'SETTINGS_UPDATED_APPLY_CHANGES' | translate }}
            </div>
            <div class="save_footer_btn">
                <button mat-button (click)="confirmChange()">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>