
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ConfigService } from "src/app/common/providers/config.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { AppConstants } from "src/app/common/utils/app-constants";
import { Subject } from "rxjs";


@Component({
  selector: "vp-legal-notice-dialog",
  templateUrl: "./legal-notice-dialog.component.html"
})
export class LegalNoticeDialogComponent implements OnDestroy {

  private isAlive$ = new Subject<boolean>();
  language: string = "en";
  termsOfUse: string;
  dataPrivacy: string;
  hideImprintMenuItem: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<LegalNoticeDialogComponent>,
    private broadcaster: Broadcaster,
    private ngZone: NgZone,
    private configService: ConfigService
  ) {
    this.broadcaster.on<any>(BroadcastKeys.HIDE_LEGAL_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
    const lang = localStorage.getItem(AppConstants.CONTACT_LANGUAGE);
    if (lang) {
      this.language = lang;
    }
    if (this.language === "en") {
      if (this.configService.URLS.termsOfUseURL) {
        this.termsOfUse = this.configService.URLS.termsOfUseURL;
      } else {
        this.termsOfUse = "https://vnclagoon.com/terms/";
      }

      if (this.configService.URLS.dataPrivacyURL) {
        this.dataPrivacy = this.configService.URLS.dataPrivacyURL;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/data-privacy-policy/";
      }
    } else {
      if (this.configService.URLS.termsOfUseURL) {
        this.termsOfUse = this.configService.URLS.termsOfUseURLde;
      } else {
        this.termsOfUse = "https://vnclagoon.com/de/nutzungsbedingungen/";
      }

      if (this.configService.URLS.dataPrivacyURL) {
        this.dataPrivacy = this.configService.URLS.dataPrivacyURLde;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/de/datenschutzerklaerung/";
      }
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  close(): void {
    this.dialogRef.close();
  }
}
