
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Directive, ElementRef, OnInit, Input } from "@angular/core";

@Directive({
    selector: "[vpResizable]"
})

export class ResizableDirective implements OnInit {


    @Input() resizableGrabWidth = 8;
    @Input() resizableMinWidth = 250;
    @Input() resizableMaxWidth = 500;

    dragging = false;

    constructor(private el: ElementRef) {

        const self = this;

        const EventListenerMode = { capture: true };

        function preventGlobalMouseEvents() {
            document.body.style["pointer-events"] = "none";
        }

        function restoreGlobalMouseEvents() {
            document.body.style["pointer-events"] = "auto";
        }


        const newWidth = (wid) => {
            let newWidth = Math.max(this.resizableMinWidth, wid);
            if (newWidth > this.resizableMaxWidth) {
                newWidth = this.resizableMaxWidth;
            }
            el.nativeElement.style.width = (newWidth) + "px";
        };


        const mouseMoveG = (evt) => {
            if (!this.dragging) {
                return;
            }
            newWidth(evt.clientX - el.nativeElement.offsetLeft);
            evt.stopPropagation();
        };

        const dragMoveG = (evt) => {
            if (!this.dragging) {
                return;
            }
            const newWidth = Math.max(this.resizableMinWidth, (evt.clientX - el.nativeElement.offsetLeft)) + "px";
            el.nativeElement.style.width = (evt.clientX - el.nativeElement.offsetLeft) + "px";
            evt.stopPropagation();
        };

        const mouseUpG = (evt) => {
            if (!this.dragging) {
                return;
            }
            restoreGlobalMouseEvents();
            this.dragging = false;
            evt.stopPropagation();
        };

        const mouseDown = (evt) => {
            if (this.inDragRegion(evt)) {
                this.dragging = true;
                preventGlobalMouseEvents();
                evt.stopPropagation();
            }
        };


        const mouseMove = (evt) => {
            if (this.inDragRegion(evt) || this.dragging) {
                el.nativeElement.style.cursor = "col-resize";
            } else {
                el.nativeElement.style.cursor = "default";
            }
        };

        document.addEventListener("mousemove", mouseMoveG, true);
        document.addEventListener("mouseup", mouseUpG, true);
        el.nativeElement.addEventListener("mousedown", mouseDown, true);
        el.nativeElement.addEventListener("mousemove", mouseMove, true);
    }

    ngOnInit(): void {
    }

    inDragRegion(evt) {
        return evt.target.classList.contains("resizable-handler");
    }

}
