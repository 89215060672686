
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ActionReducerMap, createSelector } from "@ngrx/store";
import {
    _getIsLoggedInUserLoading ,
    _getSidebarExpanderStatus ,
    appReducer ,
    AppState ,
    _getUserProfile , _IsDeviceReady,
    _getOnlineStatus,
    _getFederatedApps,
    _getLoginStatus,
    _getRouteTypeStatus,
    _getRouteIdStatus,
    _getFavoriteGroup,
    _getLoggedInUserContact,
    _getStatistics,
    _getSearchInApps,
    _getSearchInLists,
    _getSearchInTags,
    _getSearchType,
    _getSearchQuery,
    _getSaveSearch,
    _getQueryFilters,
    _getFiltersForSearch,
    _getContactsCount
} from "./app";
import { AppActionTypes } from "../actions/app";

export interface RootState {
  app: AppState;
}

export const reducers: ActionReducerMap<RootState> = {
  app: appReducer,
};

export function reset(reducer) {
  return function (state, action) {

    if (action.type === AppActionTypes.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

/**
 *  App Related Reducers
 */
export const getAppState =  state => state.app;
export const getIsLoggedInUserLoading = createSelector(getAppState, _getIsLoggedInUserLoading);
export const getSidebarExpanderStatus = createSelector(getAppState, _getSidebarExpanderStatus);
export const getUserProfile = createSelector(getAppState, _getUserProfile);
export const getIsDeviceReady = createSelector(getAppState, _IsDeviceReady);
export const getOnlineStatus = createSelector(getAppState, _getOnlineStatus);
export const getFederatedApps = createSelector(getAppState, _getFederatedApps);
export const getLoginStatus = createSelector(getAppState, _getLoginStatus);
export const getRouteType = createSelector(getAppState, _getRouteTypeStatus);
export const getRouteId = createSelector(getAppState, _getRouteIdStatus);
export const getFavoriteGroup = createSelector(getAppState, _getFavoriteGroup);
export const getLoggedInUserContact = createSelector(getAppState, _getLoggedInUserContact);
export const getStatistics = createSelector(getAppState, _getStatistics);
export const getSearchInApps = createSelector(getAppState, _getSearchInApps);
export const getSearchInLists = createSelector(getAppState, _getSearchInLists);
export const getSearchInTags = createSelector(getAppState, _getSearchInTags);
export const getSearchType = createSelector(getAppState, _getSearchType);
export const getSearchQuery = createSelector(getAppState, _getSearchQuery);
export const getSaveSearch = createSelector(getAppState, _getSaveSearch);
export const getQueryFilters = createSelector(getAppState, _getQueryFilters);
export const getSearchFilters = createSelector(getAppState, _getFiltersForSearch);
export const getContactsCount = createSelector(getAppState, _getContactsCount);
