
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export class ContactFolder {
    id: number;
    name: string;
    color_hex: string;
    contacts_count: number;
    created_at: string;
    updated_at: string;

    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.color_hex = json.color_hex;
        this.contacts_count = json.contacts_count;
        this.created_at = json.created_at;
        this.updated_at = json.updated_at;
    }
}