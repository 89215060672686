
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, inject, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";

@Component({
  selector: "vp-snackbar-notification",
  templateUrl: "./snackbar-notification.component.html",
})

export class SnackBarNotificationComponent implements OnInit {
  snackBarRef = inject(MatSnackBarRef);
  message: string = "";
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }
  ngOnInit() {
    this.message = this.data.message ? this.data.message : "";
  }
}
