
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import * as fromTagContacts from "../actions/tag-contacts.action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../../../actions";
import { Contact } from "src/app/common/models";

export interface ContactTagState extends EntityState<Contact> {
  isLoaded: boolean;
  isLoading: boolean;

  isNextPageLoading: boolean;
  isNextPageLoaded: boolean;
  currentOffset: number;
  isMoreContacts: boolean;

  selectedContactsIds: number[];
  isSearchMode: boolean;
  queryParams: any;
}

export const tagContactAdapter: EntityAdapter<Contact> = createEntityAdapter<Contact>({
  selectId: (contact: Contact) => contact.id
});

export function sortByFirstCharacter(contact1: Contact, contact2: Contact): number {
  return contact1.fullName.charCodeAt(0) - contact2.fullName.charCodeAt(0);
}

export const initialState: ContactTagState = tagContactAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  isNextPageLoading: false,
  isNextPageLoaded: false,
  currentOffset: 0,
  isMoreContacts: false,

  selectedContactsIds: [],
  sortBy: null,
  isSearchMode: false,
  queryParams : null
});

export function tagContactReducer(
  state = initialState,
  action: Action
): ContactTagState {
  switch (action.type) {
    case fromTagContacts.TagContactsAcTypes.LOAD_TAG_CONTACTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromTagContacts.TagContactsAcTypes.LOAD_TAG_CONTACTS_SUCCESS: {
      return tagContactAdapter.addAll(action.payload.contact, {
        ...state,
        isLoading: false,
        isLoaded: true,
        currentOffset: action.payload.currOffset,
        isMoreContacts: action.payload.isMoreContacts
      });
    }

    case fromTagContacts.TagContactsAcTypes.LOAD_TAG_CONTACTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromTagContacts.TagContactsAcTypes.NEXT_LOAD_TAG_CONTACTS: {
      return {
        ...state,
        isNextPageLoading: true,
        isLoading: true
      };
    }

    case fromTagContacts.TagContactsAcTypes.NEXT_LOAD_TAG_CONTACTS_SUCCESS: {
      const newState = tagContactAdapter.addMany(action.payload.contacts, {
        ...state,
        isNextPageLoading: false,
        isNextPageLoaded: true,
        isLoading: false,
        isLoaded: true,
        currentOffset: action.payload.currOffset,
        isMoreContacts: action.payload.isMoreContacts
      });

      const changes = action.payload.contacts.map(contact => {
        return {id: contact.id, changes: contact };
      });
      return tagContactAdapter.updateMany(changes, newState);
    }

    case fromTagContacts.TagContactsAcTypes.EMPTY_TAG_CONTACTS_SUCCESS: {
      const newState = tagContactAdapter.removeAll( {
        ...state,
        isLoading: false,
        isLoaded: true,
        currentOffset: 0,
        isMoreContacts: false
      });
      return newState;
    }

    case fromTagContacts.TagContactsAcTypes.NEXT_LOAD_TAG_CONTACTS_FAIL: {
      return {
        ...state,
        isNextPageLoading: false,
        isNextPageLoaded: false,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromTagContacts.TagContactsAcTypes.UPDATE_TAG_CONTACT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromTagContacts.TagContactsAcTypes.UPDATE_TAG_CONTACT_SUCCESS: {
      return tagContactAdapter.updateOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromTagContacts.TagContactsAcTypes.UPDATE_TAG_CONTACT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromTagContacts.TagContactsAcTypes.CREATE_TAG_CONTACT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromTagContacts.TagContactsAcTypes.CREATE_TAG_CONTACT_SUCCESS: {
      return tagContactAdapter.addOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromTagContacts.TagContactsAcTypes.CREATE_TAG_CONTACT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromTagContacts.TagContactsAcTypes.REMOVE_TAG_CONTACT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromTagContacts.TagContactsAcTypes.REMOVE_TAG_CONTACT_SUCCESS: {
      return tagContactAdapter.removeOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromTagContacts.TagContactsAcTypes.REMOVE_MANY_TAG_CONTACT_SUCCESS: {
      return tagContactAdapter.removeMany(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromTagContacts.TagContactsAcTypes.REMOVE_TAG_CONTACT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromTagContacts.TagContactsAcTypes.RESTORE_TAG_CONTACT: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }

    case fromTagContacts.TagContactsAcTypes.RESTORE_TAG_CONTACT_SUCCESS: {
      return tagContactAdapter.addMany(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromTagContacts.TagContactsAcTypes.RESTORE_TAG_CONTACT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromTagContacts.TagContactsAcTypes.SEARCH_TOGGLE: {
      return  {
        ...state,
        isSearchMode: action.payload,
      };
    }

    case fromTagContacts.TagContactsAcTypes.START_TAG_LOADING: {
      return {
        ...state,
        isLoading: true
      };
    }

    case fromTagContacts.TagContactsAcTypes.STOP_TAG_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case fromTagContacts.TagContactsAcTypes.STORE_TAG_SEARCHPARAMS: {
      return {
        ...state,
        queryParams: action.payload,
      };
    }

    case fromTagContacts.TagContactsAcTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.contactState;
      return savedState ? { ...state, ...savedState } : state;
    }

    case fromTagContacts.TagContactsAcTypes.UPDATE_MANY_TAG_CONTACT_SUCCESS: {
      return tagContactAdapter.updateMany(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

  }
  return state;
}

export const _getIsSearchMode = (state: ContactTagState) => state.isSearchMode;
export const _getSelectedTagContactsIds = (state: ContactTagState) => state.selectedContactsIds;
export const _getIsTagContactsLoading = (state: ContactTagState) => state.isLoading;
export const _getIsTagContactsLoaded = (state: ContactTagState) => state.isLoaded;
export const _getCurrentTagOffset = (state: ContactTagState) => state.currentOffset;
export const _getIsTagNextPageLoading = (state: ContactTagState) => state.isNextPageLoading;
export const _getIsTagNextPageLoaded = (state: ContactTagState) => state.isNextPageLoaded;
export const _getTagSearchParams = (state: ContactTagState) => state.queryParams;
export const _getIsTagMoreContacts = (state: ContactTagState) => state.isMoreContacts;
