
<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <div class="snackbar-notificiation-block">
    <div class="message-part">
        {{ message }}
    </div>
    <div class="action">
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>22F9AFA8-E374-4297-96BF-1CF4EC532EE8</title>
            <defs>
                <filter x="-12.8%" y="-95.8%" width="125.6%" height="291.7%" filterUnits="objectBoundingBox" id="filter-1">
                    <feOffset dx="0" dy="8" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.031372549   0 0 0 0 0.176470588   0 0 0 0 0.239215686  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                </filter>
            </defs>
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group-18" transform="translate(-312.000000, -12.000000)" fill="#27AB7F" filter="url(#filter-1)">
                    <g id="Icon/product/check" transform="translate(312.000000, 12.000000)">
                        <g id="Icon-24px" transform="translate(3.000000, 5.000000)">
                            <polygon id="Shape" points="6 11.17 1.83 7 0.41 8.41 6 14 18 2 16.59 0.59"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
  </div>