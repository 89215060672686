
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */


import { Action } from "../actions";
import { AppActionTypes } from "../actions/app";
import { UserProfile } from "../shared/models/user-profile";
import { Contact } from "../common/models";
import { ContactFolder } from "../contacts/models/create-folder.model";
import { ContactTag } from "../contacts/models/contact-tag.model";

export interface AppState {
  loggedInUserLoading: boolean;
  isSidebarCollapsed: boolean;
  userProfile: any;
  isOnline: boolean;
  isDeviceReady: boolean;
  federatedApps: any;
  isLoggedIn: boolean;
  routingType: string;
  routeId: string;
  favoriteGroup: any;
  loggedInUserContact: Contact;
  statistics: any;
  contactsCount: any;
  searchInApps: any;
  searchInLists: ContactFolder[];
  searchInTags: ContactTag[];
  searchType: string;
  searchQuery: string;
  saveSearch: any[];
  queryFilters: any[];
  searchFilters: any[];
}

export const initialState: AppState = {
  loggedInUserLoading: false,
  isSidebarCollapsed: true,
  userProfile: null,
  isOnline: true,
  isDeviceReady: false,
  isLoggedIn: false,
  federatedApps: {},
  routingType: "",
  routeId: "",
  favoriteGroup: {},
  loggedInUserContact: null,
  statistics: {},
  contactsCount: {},
  searchInApps: "VNCcontacts+",
  searchInLists: [],
  searchInTags: [],
  searchType: "contact",
  searchQuery: "",
  saveSearch: [],
  queryFilters: [],
  searchFilters: []
};

export function appReducer(state: AppState = initialState, action: Action): AppState {
  switch (action.type) {

    case AppActionTypes.LOGIN_REQUEST: {
      return {
        ...state,
        loggedInUserLoading: true
      };
    }

    case AppActionTypes.SET_SIDEBAR_STATUS: {
      return {
        ...state,
        isSidebarCollapsed: action.payload
      };
    }

    case AppActionTypes.APP_ONLINE: {
      return {
        ...state,
        isOnline: action.payload
      };
    }

    case AppActionTypes.SET_USER_PROFILE: {
      return {
        ...state,
        userProfile: action.payload
      };
    }

    case AppActionTypes.DEVICE_READY: {
      return {
         ...state,
          isDeviceReady: true
      };
    }


    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.appState;
      return savedState ? {...state, ...savedState} : state;
    }

    case AppActionTypes.SET_AVAILABLE_APPS: {
      return {
        ...state,
        federatedApps: action.payload
      };
    }

    case AppActionTypes.SET_LOGIN_STATUS: {
      return {
         ...state,
         isLoggedIn: action.payload
      };
    }

    case AppActionTypes.SET_ROUTING_TYPE: {
      return {
        ...state,
        routingType: action.payload
      };
    }

    case AppActionTypes.SET_ROUTE_ID: {
      return {
        ...state,
        routeId: action.payload
      };
    }

    case AppActionTypes.SET_FAVORITE_GROUP: {
      return {
        ...state,
        favoriteGroup: action.payload
      };
    }

    case AppActionTypes.SET_LOGGEDIN_USER_CONTACT: {
      return {
        ...state,
        loggedInUserContact: action.payload
      };
    }

    case AppActionTypes.SET_STATISTICS: {
      return {
        ...state,
        statistics: action.payload
      };
    }

    case AppActionTypes.SET_CONTACTS_COUNT: {
      return {
        ...state,
        contactsCount: action.payload
      };
    }

    case AppActionTypes.SET_SEARCH_IN_APPS: {
      return {
        ...state,
        searchInApps: action.payload
      };
    }

    case AppActionTypes.SET_SEARCH_IN_LISTS: {
      return {
        ...state,
        searchInLists: action.payload
      };
    }

    case AppActionTypes.SET_SEARCH_IN_TAGS: {
      return {
        ...state,
        searchInTags: action.payload
      };
    }

    case AppActionTypes.SET_SEARCH_TYPE: {
      return {
        ...state,
        searchType: action.payload
      };
    }

    case AppActionTypes.SET_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload
      };
    }

    case AppActionTypes.SET_SAVE_SEARCH: {
      return {
        ...state,
        saveSearch: action.payload
      };
    }

    case AppActionTypes.SET_QUERY_FILTERS: {
      return {
        ...state,
        queryFilters: action.payload
      };
    }

    case AppActionTypes.SET_FILTER_FOR_SEARCH: {
      return {
        ...state,
        searchFilters: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const _getIsLoggedInUserLoading = (state: AppState) => state.loggedInUserLoading;
export const _getSidebarExpanderStatus = (state: AppState) => state.isSidebarCollapsed;
export const _getUserProfile = (state: AppState) => state.userProfile;
export const _IsDeviceReady = (state: AppState) => state.isDeviceReady;
export const _getOnlineStatus = (state: AppState) => state.isOnline;
export const _getFederatedApps = (state: AppState) => state.federatedApps;
export const _getLoginStatus = (state: AppState) => state.isLoggedIn;
export const _getRouteTypeStatus = (state: AppState) => state.routingType;
export const _getRouteIdStatus = (state: AppState) => state.routeId;
export const _getFavoriteGroup = (state: AppState) => state.favoriteGroup;
export const _getLoggedInUserContact = (state: AppState) => state.loggedInUserContact;
export const _getStatistics = (state: AppState) => state.statistics;
export const _getContactsCount = (state: AppState) => state.contactsCount;
export const _getSearchInApps = (state: AppState) => state.searchInApps;
export const _getSearchInLists = (state: AppState) => state.searchInLists;
export const _getSearchInTags = (state: AppState) => state.searchInTags;
export const _getSearchType = (state: AppState) => state.searchType;
export const _getSearchQuery = (state: AppState) => state.searchQuery;
export const _getSaveSearch = (state: AppState) => state.saveSearch;
export const _getQueryFilters = (state: AppState) => state.queryFilters;
export const _getFiltersForSearch = (state: AppState) => state.searchFilters;
