
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export class AppConstants {
  static SQLITE_SYNC_DELAY = 3;
  static CONTACT_LANGUAGE = "contactLanguage";
  static TASK_URL = "/api/task";
  static PROFILE_USER = "profileUser";
  static DESCENDING = "desc";
  static ASCENDING = "asc";
  static ADDRESS_TYPE = "ADDRESS_TYPE";
  static SEARCH_TYPE = "search";
  static CONTACT_GROUP = "contact_group";
  static GLOBAL_CONTACTS = "global";
  static SERVER_URL = "serverURL";
  static CONTACTS_PLUS_URL = "/api/contactsplus";
  static FREQUENTLY_CONTACTED = "frequently_contacted";
  static TRASH_CONTACT = "trash";
  static DUPLICATE_CONTACT = "duplicate_contact";
  static LIST = "List";
  static TAG = "Tag";
  static CONTACTS_ALL = "contactplus/all";
  static CONTACTS = "contacts";
  static SORT_TYPE = "SORT_TYPE";
  static IS_SEARCH_IN_RECENT_ACTIVITY = "isSearchInRecentActivity";
  static FAVOURITE_LIST_NAME = "favourite__";
  static HIDDEN_REMOVED_LIST_NAME = "hidden__";
}
