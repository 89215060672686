
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, OnDestroy, ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RootState, getSearchInApps } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { CommonUtil } from "src/app/common/utils/common.utils";

@Component({
    selector: "vp-search-in-apps-dialog",
    templateUrl: "./search-in-apps-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchInAppsDialogComponent implements OnDestroy, OnInit {

    private isAlive$ = new Subject<boolean>();
    searchApps: any;
    contactIcon: string;

    constructor(
        private dialogRef: MatDialogRef<SearchInAppsDialogComponent>,
        private broadcaster: Broadcaster,
        private store: Store<RootState>
    ) {
        this.contactIcon = CommonUtil.getFullUrl("/assets/VNCcontacts+.svg");
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_SEACH_IN_APPS_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(presence => {
            this.close();
        });
        this.store.select(getSearchInApps).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.searchApps = res;
        });

    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

}