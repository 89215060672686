<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="contact-dialog">
    <div class="contact__dialog-header-item">
        <div class="header_lbl text disable-select">
            {{ 'ADD_LIST_LABEL' | translate }}
        </div>
        <div class="header_lbl disable-select">
            <mat-form-field floatLabel="never">
                <input matInput #searchTextInput class="form-control" [(ngModel)]="searchText" name="label"
                    placeholder="{{ 'SEARCH_DOT' | translate }}" autocomplete="nope" />
                <button mat-button matSuffix mat-icon-button aria-label="Clear">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
    <div class="contact__dialog-body">
        <div class="content">
            <div *ngFor="let list of contactFolders | vpListsSearch : searchText" class="folder-list-item">
                <div class="name disable-select">
                    <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-account-box-multiple"
                        [ngStyle]="{'color': !!list.color_hex && list.color_hex !== null ? list.color_hex : '#888888'}">
                    </mat-icon>
                    {{ list.name}}
                </div>
                <div>
                    <mat-checkbox [checked]="isChecked(list)" (change)="changeItem($event, list)"></mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div class="create-new-list">
        <span class="new-list-span" (click)="addList()">
            <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
            <div class="text">{{ 'CREATE_NEW_LIST' | translate }}</div>
        </span>
    </div>
    <div class="footer-item">
        <button mat-button (click)="close()">
            {{ 'CANCEL' | translate }}
        </button>
        <button mat-button (click)="addListToContact()">
            {{ 'OK' | translate }}
        </button>
    </div>
</div>