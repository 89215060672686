/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, NgZone, ChangeDetectorRef } from "@angular/core";
import { Subject } from "rxjs";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { take, takeUntil } from "rxjs/operators";
import { ToastService } from "src/app/common/service/tost.service";
import { ContactRepository } from "../../repository/contact.repository";
import { CommonUtil } from "src/app/common/utils/common.utils";
import { ContactPlusListSelectionComponent } from "../contact-list-selection/contact-list-selection.component";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/reducers";
import { ContactRootState, getContactFolders } from "../../store";

@Component({
    selector: "vp-contact-import-dialog",
    templateUrl: "./import-contact-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ImportContactDialogComponent implements OnInit, OnDestroy {
    private isAlive$ = new Subject<boolean>();
    allowedExtension = [
        "csv",
        "vcf"
    ];
    files: any = null;
    blob: any = null;
    csvHeaders: string[] = [];
    csvFirstName: any;
    csvLastName: any;
    csvMiddleName: any;
    csvCompany: any;
    csvJobTitle: any;
    csvPhones: any;
    csvEmails: any;
    isMobileScreen: boolean = CommonUtil.isOnMobileDevice();
    selectedList: any = [];
    contactLists: any = [];

    importContactsAddress :any = {
        importHomeAddress: [],
        importWorkAddress: [],
        importOtherAddress: []
    };
    importContactsEventsDates: any = {
        importBirthDay: [],
        importAnniversary: [],
        importCustom: [],
    };

    importURLs: any = {
        home: [],
        work: [],
        other: [],
    };

    importIMData: any = {
        xmpp: [],
        mastodon: [],
        twitter: [],
        others: [],
    };
    importNotes: string = "";
    customFields: any[] = [];

    multipleAddressList: any = [];
    multipleImportIMData: any = [];
    isMultipleVCF: boolean = false;
    multipleContactsEvents: any = [];
    multipleContactsNotes: any = [];
    multipleContactsUrls: any = [];
    constructor(
        public dialogRef: MatDialogRef<ImportContactDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private changeDetectionRef: ChangeDetectorRef,
        private toastService: ToastService,
        private contactRepository: ContactRepository,
        private matDialog: MatDialog,
        private store: Store<RootState | ContactRootState>,

    ) {
        this.store.select(getContactFolders).pipe(takeUntil(this.isAlive$)).subscribe((contactFolders: any[]) => {
            this.contactLists = contactFolders;
        });
    }

    ngOnInit(): void {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_IMPORT_CONTACT_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    parseVCardToJson(vcfContent: any) {
        const lines = vcfContent.split("\n");
        const vCardData: any = {};

        for (const line of lines) {
          if (line.startsWith("BEGIN:VCARD") || line.startsWith("END:VCARD")) {
            continue;
          }

          const parts = line.split(":");
          const propertyName = parts[0];
          const propertyValue = parts.slice(1).join(":").trim();

          if (propertyName === "VERSION") {
            vCardData[propertyName] = propertyValue;
          } else {
            if (!vCardData[propertyName]) {
              vCardData[propertyName] = [];
            }
            vCardData[propertyName].push(propertyValue);
          }
        }

        return vCardData;
      }


    uploadImport(files: any, event): void {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const fileReader = new FileReader();

            fileReader.onload = (e) => {
                let data  = e.target?.result;
                data = data.toString().replace(/"/g, "");
                const vcfContent = e.target?.result;
                const parsedData = this.parseVCardToJson(vcfContent);
                this.multipleUploadsData(vcfContent);
                this.importContactsAddress.importHomeAddress = (parsedData["ADR;home;CHARSET=utf-8"] || []).map(address => address.split(";") || address.split(","));
                this.importContactsAddress.importWorkAddress = (parsedData["ADR;work;CHARSET=utf-8"] || []).map(address => address.split(";") || address.split(","));
                this.importContactsAddress.importOtherAddress = (parsedData["ADR;other;CHARSET=utf-8"] || []).map(address => address.split(";") || address.split(","));

                const IMData = (parsedData["IMPP;TYPE=personal,pref"] || []);
                IMData.map(im=>{
                    let parts = im.split("::");
                    if (parts[0] === "xmpp") {
                        this.importIMData.xmpp.push(parts[1]);
                    }
                    if (parts[0] === "mastodon") {
                        this.importIMData.mastodon.push(parts[1]);
                    }
                    if (parts[0] === "twitter") {
                        this.importIMData.twitter.push(parts[1]);
                    }
                    if (parts[0] !== "twitter" && parts[0] !== "mastodon" && parts[0] !== "xmpp") {
                        this.importIMData.others.push(parts[1]);
                    }
                });

                if (parsedData["ADR"]) {
                    const address: any[] = (parsedData["ADR"] || []).map(address => address.split(";") || address.split(","));
                    address.map(ad => {
                        this.importContactsAddress.importHomeAddress.push(ad);
                    });
                }


                const bdayArray = parsedData["BDAY"] || [];
                this.importContactsEventsDates.importBirthDay = bdayArray.map(birthday => {
                // Assuming 'birthday' is in the format 'YYYY-MM-DD'
                return birthday.split(";")[0];
                });

                const anniversaryArray = parsedData["ANNIVERSARY"] || [];
                this.importContactsEventsDates.importAnniversary = anniversaryArray.map(anniversary => {
                    return anniversary.split(";")[0];
                });

                const customArray = parsedData["CUSTOM"] || [];
                this.importContactsEventsDates.importCustom = customArray.map(custom => {
                    return custom.split(";")[0];
                });

                const URLs = parsedData["URL"] || parsedData["URL;home"] || [];
                this.importURLs.home = URLs.map(urls => {
                return urls.split(";")[0];
                });

                const URLWorks = parsedData["URL;work"] || [];
                this.importURLs.work = URLWorks.map(urls => {
                return urls.split(";")[0];
                });

                const URLOthers = parsedData["URL;others"] || [];
                this.importURLs.others = URLOthers.map(urls => {
                return urls.split(";")[0];
                });


                if (parsedData["NOTE"]) {
                    const notesArray = parsedData["NOTE"] || [];
                    this.importNotes = notesArray[0];
                } else if (parsedData["NOTE;CHARSET=utf-8"]) {
                    const notesArray = parsedData["NOTE;CHARSET=utf-8"] || [];
                    this.importNotes = notesArray[0];
                }



                // Iterate through the properties of the data object
                for (const propertyName in parsedData) {
                    let customFieldData = {};
                    if (propertyName.startsWith("X-CUSTOM-FIELD.")) {
                        const customFieldName = propertyName.substring("X-CUSTOM-FIELD.".length);
                        customFieldData["field_name"] = customFieldName;
                        customFieldData["field_value"] = parsedData[propertyName][0];
                       this.customFields.push(customFieldData);
                    }
                }

                console.log("customFieldData", this.customFields);
                console.log("parsedData", parsedData);
            };
            fileReader.readAsText(selectedFile);
        }

        const dialogElement = <HTMLElement> document.querySelector(".import_contact_dialog");
        this.files = null;
        const extension = files[0].name.replace(/^.*\./, "").toLowerCase();
        const allowed = this.allowedExtension.filter(ext => ext === extension);
        const isAllowExtension = allowed.length > 0 ? true : false;
        this.files = files;
        if (!isAllowExtension) {
            this.files = null;
            return;
        }
        let reader: FileReader = new FileReader();
        reader.onloadend = (loadEvent: any) => {
            const base64Data = loadEvent.target.result.split(",")[1];
            let blob = this.b64toBlob(base64Data, files[0].type);
            const extension = this.files[0].name.replace(/^.*\./, "").toLowerCase();
            if (extension === "csv") {
                blob.text().then(text => {
                    if (text !== "") {
                        const headers = text.split(/\r\n|\n/);
                        console.log("[CSVheader]: ", headers);
                        this.csvHeaders = headers[0].replace(/'/g, "").replace(/"/g, "").split(",");
                        this.changeDetectionRef.markForCheck();
                        if (dialogElement !== null && !this.isMobileScreen) {
                            dialogElement.style.height = "auto";
                        }
                    }
                });
            } else {
                this.csvHeaders = [];
                this.changeDetectionRef.markForCheck();
                if (dialogElement !== null) {
                    dialogElement.removeAttribute("style");
                }
            }
            this.processToImport(blob);
        };
        reader.readAsDataURL(files[0]);
        this.changeDetectionRef.markForCheck();
    }


    multipleUploadsData(parsedData) {
        this.isMultipleVCF = false;
        const vCards = parsedData.split("END:VCARD");
        if (vCards.length > 2) {
            this.isMultipleVCF = true;
        }
        vCards.forEach((vCard, index) => {
            if (vCard.trim() !== "") {
                const singleVCard = vCard + "END:VCARD";
                const parsedSingleVCard = this.parseVCardToJson(singleVCard);
                const homeAddresses = parsedSingleVCard["ADR;home;CHARSET=utf-8"] || [];
                this.multipleAddressList[index] = {importHomeAddress:[], importWorkAddress:[], importOtherAddress:[]};
                this.multipleAddressList[index].importHomeAddress = homeAddresses.map(address => address.split(";"));
                this.multipleAddressList[index].importWorkAddress = (parsedSingleVCard["ADR;work;CHARSET=utf-8"] || []).map(address => address.split(";"));
                this.multipleAddressList[index].importOtherAddress = (parsedSingleVCard["ADR;other;CHARSET=utf-8"] || []).map(address => address.split(";"));

                if (parsedSingleVCard["ADR"]) {
                    const address: any[] = (parsedSingleVCard["ADR"] || []).map(address => address.split(","));
                    address.map(ad => {
                        this.multipleAddressList[index].importHomeAddress.push(ad);
                    });
                }

                const IMData = (parsedSingleVCard["IMPP;TYPE=personal,pref"] || []);
                const mastodonData = parsedSingleVCard["IM-MASTODON"] || [];
                const otherData = parsedSingleVCard["IM-OTHER"] || [];
                const twitter = parsedSingleVCard["IM-TWITTER"] || [];
                const xmpp = parsedSingleVCard["IM-XMPP"] || [];
                this.multipleImportIMData[index] = {xmpp:[], mastodon:[], twitter:[], others:[]};
                IMData.map(im => {
                    let parts = im.split("::");
                    if (parts[0] === "xmpp") {
                        this.multipleImportIMData[index].xmpp.push(parts[1]);
                    }
                    if (parts[0] === "mastodon") {
                        this.multipleImportIMData[index].mastodon.push(parts[1]);
                    }
                    if (parts[0] === "twitter") {
                        this.multipleImportIMData[index].twitter.push(parts[1]);
                    }
                    if (parts[0] !== "twitter" && parts[0] !== "mastodon" && parts[0] !== "xmpp") {
                        this.multipleImportIMData[index].others.push(parts[1]);
                    }
                });

                if (xmpp.length) {
                    this.multipleImportIMData[index].xmpp = xmpp;
                }
                if (mastodonData.length) {
                    this.multipleImportIMData[index].mastodon = mastodonData;
                }
                if (twitter.length) {
                    this.multipleImportIMData[index].twitter = twitter;
                }
                if (otherData.length) {
                    this.multipleImportIMData[index].others = otherData;
                }

                console.log("IMData this.multipleImportIMData", this.multipleImportIMData);
                this.multipleContactsEvents[index] = { importBirthDay: [], importAnniversary: [], importCustom: [] };
                const bdayArray = parsedSingleVCard["BDAY"] || [];
                    this.multipleContactsEvents[index].importBirthDay = bdayArray.map(birthday => {
                    return birthday.split(";")[0];
                });

                const anniversaryArray = parsedSingleVCard["ANNIVERSARY"] || [];
                 this.multipleContactsEvents[index].importAnniversary = anniversaryArray.map(anniversary => {
                    return anniversary.split(";")[0];
                });

                const customArray = parsedSingleVCard["CUSTOM"] || [];
                 this.multipleContactsEvents[index].importCustom = customArray.map(custom => {
                    return custom.split(";")[0];
                });

                this.multipleContactsNotes[index] = "";
                if (parsedSingleVCard["NOTE"]) {
                    const notesArray = parsedSingleVCard["NOTE"] || [];
                    this.multipleContactsNotes[index] = notesArray[0];
                } else if (parsedSingleVCard["NOTE;CHARSET=utf-8"]) {
                    const notesArray = parsedSingleVCard["NOTE;CHARSET=utf-8"] || [];
                    this.multipleContactsNotes[index] = notesArray[0];
                }

                this.multipleContactsUrls[index] = { home: [], work: [], other: [] };
                const URLs = parsedSingleVCard["URL"] || parsedSingleVCard["URL;home"] || [];
                this.multipleContactsUrls[index].home = URLs.map(urls => {
                    return urls.split(";")[0];
                });

                const URLWorks = parsedSingleVCard["URL;work"] || [];
                this.multipleContactsUrls[index].work = URLWorks.map(urls => {
                    return urls.split(";")[0];
                });

                const URLOthers = parsedSingleVCard["URL;others"] || [];
                this.multipleContactsUrls[index].others = URLOthers.map(urls => {
                    return urls.split(";")[0];
                });

            }
        });

    }


    resetFile(): void {
        this.files = null;
        this.changeDetectionRef.markForCheck();
    }

    importContact(): void {
        const extension = this.files[0].name.replace(/^.*\./, "").toLowerCase();
        console.log("extension",extension);
        if (extension === "csv") {
            const mappingString: string[] = [];
            if (!!this.csvCompany) {
                mappingString.push("import_settings[mapping][company]=" + this.csvCompany);
            } else {
                mappingString.push("import_settings[mapping][company]=");
            }
            if (!!this.csvEmails) {
                mappingString.push("import_settings[mapping][emails]=" + this.csvEmails);
            } else {
                mappingString.push("import_settings[mapping][emails]");
            }
            if (!!this.csvFirstName) {
                mappingString.push("import_settings[mapping][first_name]=" + this.csvFirstName);
            } else {
                mappingString.push("import_settings[mapping][first_name]=");
            }
            if (!!this.csvJobTitle) {
                mappingString.push("import_settings[mapping][job_title]=" + this.csvJobTitle);
            } else {
                mappingString.push("import_settings[mapping][job_title]=");
            }
            if (!!this.csvLastName) {
                mappingString.push("import_settings[mapping][last_name]=" + this.csvLastName);
            } else {
                mappingString.push("import_settings[mapping][last_name]=");
            }
            if (!!this.csvMiddleName) {
                mappingString.push("import_settings[mapping][middle_name]=" + this.csvMiddleName);
            } else {
                mappingString.push("import_settings[mapping][middle_name]=");
            }
            if (!!this.csvPhones) {
                mappingString.push("import_settings[mapping][phones]=" + this.csvPhones);
            } else {
                mappingString.push("import_settings[mapping][phones]=");
            }
            mappingString.push("import_settings[mapping][is_company]=");
            const allMappings: string = mappingString.join("&");
            console.log("[importContact][blob]:", this.blob);
            this.contactRepository.importCSVContactFromBlob(this.blob, allMappings, this.selectedList, this.multipleAddressList, this.multipleContactsEvents, this.multipleContactsNotes, this.multipleContactsUrls, this.customFields, this.multipleImportIMData);
        } else {
            console.log("[importContact][blob]:", this.blob);
            this.contactRepository.importContactFromBlob(this.blob, this.selectedList, this.multipleAddressList,  this.multipleContactsEvents,this.multipleContactsNotes, this.multipleContactsUrls, this.customFields, this.multipleImportIMData);
        }
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    private b64toBlob(b64Data, contentType, sliceSize?): any {
        contentType = contentType || "";
        sliceSize = sliceSize || 512;
        let byteCharacters = atob(b64Data);
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);
            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        let blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    processToImport(blob: any): void {
        this.blob = blob;
        this.changeDetectionRef.markForCheck();
    }

    resetImportData(): void {
        const dialogElement = <HTMLElement> document.querySelector(".import_contact_dialog");
        this.csvHeaders = [];
        this.changeDetectionRef.markForCheck();
        if (dialogElement !== null) {
            dialogElement.removeAttribute("style");
        }
    }
    openListSelection() {
        let dd = [];
        dd = [...this.contactLists];
        const selectedListModal = this.matDialog.open(ContactPlusListSelectionComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "import_contact_dialog",
            data : {data : dd, selected: this.selectedList}
          });

          selectedListModal.afterClosed().pipe(take(1)).subscribe(res => {
            this.selectedList = res.selectedList,
            this.changeDetectionRef.markForCheck();
        });
    }
}
