
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, NgZone, ChangeDetectorRef, ElementRef, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ContactRootState, getContactFolders } from "src/app/contacts/store";
import { Store } from "@ngrx/store";
import { takeUntil, take } from "rxjs/operators";
import { ContactFolder } from "src/app/contacts/models/create-folder.model";
import { CreateFolderComponent } from "src/app/contacts/components/create-contact-folder/create-folder.component";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import * as _ from "lodash";

@Component({
    selector: "vp-contact-add-to-list-dialog",
    templateUrl: "./add-to-list-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToListsDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    contactFolders: ContactFolder[] = [];
    searchText: string = "";
    selectedLists: ContactFolder[] = [];
    @ViewChild("searchTextInput", {static: false}) searchTextInput: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<AddToListsDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private changeDetectionRef: ChangeDetectorRef,
        private store: Store<ContactRootState>,
        private matDialog: MatDialog
    ) { }

    ngOnInit() {
        this.store.select(getContactFolders).pipe(takeUntil(this.isAlive$)).subscribe((contactFolders: ContactFolder[]) => {
            this.contactFolders = contactFolders;
            this.changeDetectionRef.markForCheck();
        });
        this.broadcaster.on<any>(BroadcastKeys.HIDE_ADD_TO_LISTS_DAILOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    addList(): void {
        this.matDialog.open(CreateFolderComponent, {
            width: "325px",
            height: "212px",
            autoFocus: true,
            panelClass: "contact_folder-create_dialog",
            disableClose: true,
            data: { targetFolder: null, isRename: false }
        });
    }

    emptySearchText(): void {
        this.searchText = "";
        this.changeDetectionRef.markForCheck();
    }

    close(): void {
        this.dialogRef.close();
    }

    addListToContact(): void {
        console.log("[selectedLists]: ", this.selectedLists);
        this.dialogRef.close({ list: this.selectedLists });
    }

    isChecked(list: ContactFolder): boolean {
        let checked: boolean = false;
        if (!!this.selectedLists && this.selectedLists.length > 0) {
            const folder = this.selectedLists.filter(f => f.id === list.id)[0];
            if (!!folder) {
                checked = true;
            }
        }
        return checked;
    }

    changeItem(event: any, list: ContactFolder): void {
        if (event.checked) {
            this.selectedLists.push(list);
        } else {
            const index = _.findIndex(this.selectedLists, { id: list.id });
            if (index !== -1) {
                this.selectedLists.splice(index, 1);
            }
        }
    }
}