
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, EventEmitter, Output, OnDestroy, OnChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AppRoutes } from "./app.routes";
import { BroadcastKeys } from "../../../common/enums/broadcast.enum";
import { getFederatedApps, RootState } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, takeUntil, take } from "rxjs/operators";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ElectronService } from "src/app/common/service/electron.service";
import { CommonUtil } from "src/app/common/utils/common.utils";
import { ConfigService } from "../../../common/providers/config.service";
import { ContactService } from "src/app/common/service/contact-service";

@Component({
  selector: "vp-app-switcher",
  templateUrl: "app-switcher.html"
})
export class AppSwitcherComponent implements OnChanges, OnDestroy {
  @Input() switchApp: boolean;
  @Input() url: string;
  @Output() closeSwitch = new EventEmitter();
  subscriber: any = null;
  routes: AppRoutes[] = [];
  private isAlive$ = new Subject<boolean>();
  constructor(private router: Router,
    private store: Store<RootState>,
    private config: ConfigService,
    private electronService: ElectronService,
    private contactService: ContactService,
    private mailBroadcaster: Broadcaster) {
    this.store.select(getFederatedApps).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(apps => {
      let routes = [];
      console.log("[AppSwitcherComponent]", apps);
      if (apps && apps.length  > 0 ) {
        apps.forEach(app => {
          routes.push({
            title: app?.options?.title,
            icon: this.config.get("publicVncDirectoryUrl") + app?.options?.icon,
            image: this.config.get("publicVncDirectoryUrl") + app?.options?.image,
            nativeLink: `${app?.name?.toLowerCase()}://main`,
            path: app?.options?.url,
            position: app?.options?.position,
            name: app?.name,
            active: true,
            enabled: true
          });
        });
      }
      this.routes = routes;
      console.log("[AppSwitcherComponent] approutes", routes);
    });
  }

  navigate(path: any) {
    this.mailBroadcaster.broadcast(BroadcastKeys.HIDE_SIDEBAR_DRAWER);
    this.router.navigate(path);
    this.closeSwitch.emit(false);
  }

  openApp(item): void {
    if (!CommonUtil.isOnMobileDevice()) {
      console.log("APPSWITCH openApp: ", item);
      if (item.title === "VNCcontacts") {
        // no need to re-open current app!
        this.closeSwitch.emit(false);
        return;
      }

      const path: string = new URL(item.path).origin;
      if (["VNCproject", "VNCdirectory"].indexOf(item.title) > -1) {item.path = `${path}/login`;}
      if (["VNCtalk", "VNCmail", "VNCcalendar", "VNCtask"].indexOf(item.title) > -1) {item.path = `${path}/api/login`;}

      if (this.electronService.isElectron) {
        try {
          let nativeLink = item.nativeLink;
          let nativeHandler = this.electronService.app.getApplicationNameForProtocol(item.nativeLink);
          if ((item.nativeLink === "vnccalendar://main") && (!nativeHandler)) {
            nativeHandler = this.electronService.app.getApplicationNameForProtocol("vncmail://");
            if (nativeHandler) {
              nativeLink = "vncmail://main/calendar";
            }
          }
          console.log("appswitcher nativeHandler: ", nativeHandler, nativeLink);
          if (nativeHandler && nativeHandler !== "") {
            this.electronService.openExternalUrl(nativeLink);
          } else {
            this.electronService.openExternalUrl(item.path);
          }
        } catch (e) {
          console.error("[AppSwitcherComponent] error: ", e);
        }
      } else {
        window.open(item.path, "_blank");
      }
    } else {
      console.log("APPSWITCH openApp: ", item);
      const target = (CommonUtil.isOnCordova()) ? "_system" : "_blank";
      if ((CommonUtil.isOnAndroid() || CommonUtil.isOnIOS()) && (["vnccontacts", "vncmail", "vnccalendar", "vnctalk", "vnctask"].indexOf(item.name) > -1)) {
        // native app exists, and we are on real mobile app
        const itemLink = (item.name === "vnccalendar") ? "vncmail://main/calendar" : item.nativeLink;
        this.contactService.checkInstalledApp(item.nativeLink, item.name).pipe(take(1)).subscribe(installed => {
          console.log("APPSWITCH checkinstalled: ", installed, item);
          if (installed) {
            window.open(item.nativeLink, target);
          } else {
            const url = CommonUtil.getAppStoreOrWeblink(item);
            window.open(url, target);
          }
        });
      } else {
        window.open(item.path, target);
      }
    }
    this.closeSwitch.emit(false);
  }

  ngOnChanges(): void {
    (this.routes || []).forEach(route => {
      if (route.path === this.url && route.enabled) {
        route.active = true;
      } else {
        route.active = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.closeSwitch.unsubscribe();
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }
}
