
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ConfigService } from "../providers/config.service";
import { Observable, Subject, throwError } from "rxjs";
import { AuthUser } from "../models";
import { map, catchError, take, tap } from "rxjs/operators";
import { AppConstants } from "../utils/app-constants";
import { ContactFolder } from "src/app/contacts/models/create-folder.model";
import { ContactTag } from "src/app/contacts/models/contact-tag.model";
import { SearchItem, SearchResponse } from "../models/search-item";
import { DatabaseService } from "./db/database.service";
import { CommonUtil } from "src/app/common/utils/common.utils";
import { Store } from "@ngrx/store";
import { ContactRootState } from "src/app/contacts/store";
import { RootState } from "src/app/reducers";
import { UpdateListContactSuccess } from "src/app/contacts/store/actions/list-contacts.action";

@Injectable()
export class ContactService {
    private headers: HttpHeaders;
    isCordovaOrElectron = environment.isCordova || environment.isElectron;
    private baseLocationUrl: string = "";
    private veiwLocationUrl: string = "";
    private viewEmbedMapLocation: string = "";
    private googleApiKey: string = "";


    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private databaseService: DatabaseService,
        private store: Store<ContactRootState | RootState>,

    ) {
        this.googleApiKey = "AIzaSyCnwNeABa952XtuLvoQe-EDesdoI3mk8Q4";
        this.baseLocationUrl = "https://maps.googleapis.com/maps/api/geocode/json?key=" + this.googleApiKey;
        this.veiwLocationUrl = "http://maps.google.com/?key=" + this.googleApiKey;
        this.viewEmbedMapLocation = "https://www.google.com/maps?key=" + this.googleApiKey;
    }

    getAuthUser(): Observable<AuthUser> {
        if (this.isCordovaOrElectron && !navigator.onLine) {
            console.log("contact-service getAuthUser, env online: ", environment.isCordova, navigator.onLine);
            return this.getStoredAuthUser();
        } else {
            return this.http.get(this.getUrl() + "/users/current.json", { headers: this.getHeaders(), withCredentials: true })
                .pipe(map((json: any) => new AuthUser(json.user)), map(res => {
                    return res;
                }), catchError(this.handleErrorObservable.bind(this)));
        }
    }

    getHeaders(): HttpHeaders {
        if (!this.headers) {
            this.headers = new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            });
            if (this.isCordovaOrElectron) {
                let token = localStorage.getItem("token");
                if (token) {
                    this.headers = this.headers.set("Authorization", localStorage.getItem("token"));
                }
            }
        } else {
            if (this.isCordovaOrElectron) {
                let token = localStorage.getItem("token");
                if (token) {
                    this.headers = this.headers.set("Authorization", localStorage.getItem("token"));
                }
            }
        }
        return this.headers;
    }

    private handleErrorObservable(error: Response | any) {
        console.log("Contact Service handleError: ", error);
        let msg;
        let json = { errors: null };
        let storedLanguage = localStorage.getItem(AppConstants.CONTACT_LANGUAGE);
        if (error.status === 403 && error.statusText === "Forbidden") {
            if (storedLanguage === "de") {
                msg = "Erlaubnis abgelehnt";
            } else {
                msg = "Permission declined";
            }
        } else if (error.status === 404 && error.statusText === "Not Found") {
            if (storedLanguage === "de") {
                msg = "Ressource nicht gefunden";
            } else {
                msg = "Resource Not Found";
            }
        } else if (error.status === 422 && error.error.message && error.error.message === "Name has already been taken") {
            if (storedLanguage === "de") {
                msg = "GroupName Already exists";
            } else {
                msg = "GroupName Already exists";
            }
        }

        try { json = error.json(); }
        catch (e) { }
        if (Array.isArray(json.errors)) {
            msg = json.errors[0];
        }

        if (!msg) {
            if (error.error) {
                try { json = JSON.parse(error.error); }
                catch (e) {
                    json = error.error;
                }
                if (Array.isArray(json.errors)) {
                    msg = json.errors[0];
                }
            }
        }

        if (!msg) {
            if (storedLanguage === "de") {
                msg = "Ein unerwarteter Fehler ist aufgetreten";
            } else {
                msg = "Unexpected error occurred";
            }
        }
        if (error.status === 403) {
            if (!this.isCordovaOrElectron) {
                console.log("[logout] error status 403");
                this.logout();
            }
        }
        return throwError(msg);
    }

    getUrl(): string {
        return this.config.API_URL + AppConstants.CONTACTS_PLUS_URL;
    }

    getAPIUrl(): string {
        return this.config.API_URL;
    }

    logout(): void {
        window.location.href = "/api/call-logout";
    }

    getAllDirectoryContact(offset: number, limit: number, query?: string, global?: boolean,sortType?,sortOrder?:string): Observable<any> {
        if (!sortType) {sortType = localStorage.getItem(AppConstants.SORT_TYPE);}
        console.log("[SortType]: ", sortType);
        if (query) {
            let url = this.getAPIUrl() + "/api/searchContacts?offset=" + offset + "&limit=" + limit + "&searchText=" + query + "&sorttype=" + sortType + "&global=0" + "&sortOrder=" + sortOrder;
            if (global) {
                url = this.getAPIUrl() + "/api/searchContacts?offset=" + offset + "&limit=" + limit + "&searchText=" + query + "&sorttype=" + sortType + "&global=1" + "&sortOrder=" + sortOrder;
            }
            return this.http.get(url, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)), tap(v => {
                if (v && v.contacts) {
                    if (!!this.config.worker) {
                        this.config.worker.postMessage({ type: "createOrUpdateContacts", id: new Date().getTime(), args: v.contacts });
                    } else {
                        this.databaseService.createOrUpdateContacts(v.contacts);
                    }
                }
            }));
        }
        if (!sortOrder) {sortOrder = "asc";}
        return this.http.get(this.getAPIUrl() + "/api/contacts?offset=" + offset + "&limit=" + limit + "&sorttype=" + sortType + ":" + sortOrder + "&global=" + (global ? "1" : "0"), { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)), tap(v => {
                if (v && v.contacts) {
                    if (!!this.config.worker) {
                        this.config.worker.postMessage({ type: "createOrUpdateContacts", id: new Date().getTime(), args: v.contacts });
                    } else {
                        this.databaseService.createOrUpdateContacts(v.contacts);
                    }
                }
        }));
    }

    createGroupContact(body: any): Observable<any> {
        return this.http.post(this.getAPIUrl() + "/api/createContactGroup", body, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    getAllDirectoryContactGroup(offset: number, limit: number, query?: string, include?:string, sort:string = "asc"): Observable<any> {
        const sortType = localStorage.getItem(AppConstants.SORT_TYPE);
        let searchUrl = this.getAPIUrl() + "/api/getContactGroup?offset=" + offset + "&limit=" + limit + "&sorttype=" + sortType + "&sort=" + sort;
        if (!!include) {
            searchUrl = searchUrl + "&include=" + include;
        }
        if (query) {
            searchUrl = searchUrl + `&searchText=${query}`;
        }
        return this.http.get(searchUrl , { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)), tap(v => {
                console.log("getContactGroup tapped: ", v);
                if (v && v.contact_groups && !!include && (include === "contacts")) {
                    this.databaseService.createOrUpdateContactGroup(v.contact_groups);
                }
            }));
    }

    createContact(body: any, createDuplicate?: boolean): Observable<any> {
        const contactBody: any = { contact: {"contact" : body } };
        if (createDuplicate) {
            contactBody.contact.create_duplicate = "1";
        }
        return this.http.post(this.getAPIUrl() + "/api/createContact", contactBody, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)), tap(v => {
                if (v && v.contact) {
                    console.log("createContact", v);
                    this.databaseService.createOrUpdateContacts([v.contact]).subscribe();
                }
        }));
    }

    deleteContact(id: string) {
        return this.http.delete(this.getAPIUrl() + "/api/deleteContact?id=" + id, { headers: this.getHeaders(), withCredentials: true })
        .pipe(catchError(this.handleErrorObservable.bind(this)), tap(v => {
            console.log("deleteContact", v);
            this.databaseService.deleteContact({id});
        }));
    }

    deleteContactGroup(id: string) {
        return this.http.delete(this.getAPIUrl() + "/api/deleteContactGroup?id=" + id, { headers: this.getHeaders(), withCredentials: true })
        .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    updateContact(body: any): Observable<any> {
        const contactBody = {
            contact: {"contact" : body },
            id: body.id
        };
        return this.http.put(this.getAPIUrl() + "/api/updateContact", contactBody, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)), tap(v => {
                if (v && v.contact) {
                    this.databaseService.createOrUpdateContacts([v.contact]);
                }
        }));
    }

    addListaToContacts(data: any, listId): Observable<any> {
        const contactBody = {
            contact: {"contact" : {
                list: data
            } },
            listId: listId
        };
        return this.http.put(this.getAPIUrl() + "/api/addListToContacts", contactBody, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)), tap(v => {
                console.log("addListaToContacts RESPONSE", v);
                if (v && v.message && v.message === "success") {
                    v.data.forEach(d => {
                        this.databaseService.createOrUpdateContacts([d.contact]);
                        this.store.dispatch(new UpdateListContactSuccess({ id: d.contact.id, changes: d.contact }));
                    });
                }
        }));
    }

    getContactGroupById(id: string): Observable<any> {
        return this.http.get(this.getAPIUrl() + "/api/getContactGroupById?id=" + id, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    updateContactGroup(body: any): Observable<any> {
        return this.http.put(this.getAPIUrl() + "/api/updateContactGroup", body, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    deleteBulkContact(ids: string[]): Observable<any> {
        return this.http.delete(this.getAPIUrl() + "/api/deleteBulkContact?ids=" + ids.toString(), { headers: this.getHeaders(), withCredentials: true })
        .pipe(catchError(this.handleErrorObservable.bind(this)), tap(v => {
            this.databaseService.deleteContacts(ids);
        }));
    }

    mergeInternalContact(ids: string[]): Observable<any> {
        const body = { "contact_ids": ids };
        return this.http.post(this.getUrl() + "/contacts/merge_selected_contacts.json", body, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    saveSettings(userSetting: any): Observable<AuthUser>{
        /* User attribute for store differnt settings */
        /* let user = {
            "notification" : authUser.notification,
            "sound" : authUser.sound,
            "language" : authUser.language,
            "mail_notification": authUser.mail_notification,
            "global_mute": authUser.global_mute
            };
            let pref = {
                "no_self_notified": ( authUser.no_self_notified === "true" ) ? "1" : "0"
            };
        */
       return this.http.post(this.getUrl() + "/my/account.json", { "user": userSetting } , { headers: this.getHeaders() })
        .pipe(map((json: any) => new AuthUser(json.user)), map(res => {
            return res;
        }), catchError(this.handleErrorObservable.bind(this)));
    }

    getAvatarURL(userId) {
        let url = this.getUrl() + "/account/get_avatar/" + userId + ".json";
        if (this.isCordovaOrElectron) {
          url = url + "?token=" + localStorage.getItem("token");
        }
        return url;
    }

    updateUserAvatar(body: any): Observable<any> {
        return this.http.post(this.getUrl() + "/my/update_avatar.json", {
          "avatar_base64": body
        }, { headers: this.getHeaders() })
        .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
      }

    cordovaLogout(): Observable<any> {
        return this.http.get(this.getAPIUrl() + "/api/cordova-logout", { headers: this.getHeaders() })
        .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
    }

    removeProfileAvatar(): Observable<any> {
        return this.http.post(this.getUrl() + "/my/update_avatar.json", {
            "delete" : "true"
        }, { headers: this.getHeaders() })
        .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
    }

    getDesktopMapUrl(address) {
        return this.veiwLocationUrl + "&q=" + address;
    }

    getlatlng(address): Observable<any> {
        return this.http.get(this.baseLocationUrl + "&address=" + address);
    }

    getAllSearchContactGroup(offset: number, limit: number, query: string): Observable<any> {
        return this.http.get(this.getAPIUrl() + "/api/searchContactsGroup?offset=" + offset + "&limit=" + limit + "&searchText=" + query , { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    createContactList(folderTitle: string, color?: string) {
        return this.http.post(this.getUrl() + "/contact_lists.json", {
            "contact_list": {
                "name": folderTitle,
                "color_hex": color
            }
        }, { headers: this.getHeaders() })
        .pipe(map((res: any) => new ContactFolder(res.contact_list), map ( contactFolder => {
            return contactFolder;
        })), catchError(this.handleErrorObservable.bind(this)));
    }

    updateContactList(folder: ContactFolder) {
        return this.http.put(this.getUrl() + "/contact_lists/" + folder.id + ".json", {
            "contact_list": {
                "name": folder.name,
                "color_hex": folder.color_hex
            }
        }, { headers: this.getHeaders() })
        .pipe(map((res: any) => new ContactFolder(res.contact_list), map ( contactFolder => {
            return contactFolder;
        })), catchError(this.handleErrorObservable.bind(this)));
    }

    deleteContactList(folder: ContactFolder) {
        return this.http.delete(this.getUrl() + "/contact_lists/" + folder.id + ".json", { headers: this.getHeaders() })
        .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
    }

    getContactList() {
        return this.http.get(this.getUrl() + "/contact_lists.json" , { headers: this.getHeaders(), withCredentials: true })
            .pipe(map((res: any) => res.contact_lists.map(json => new ContactFolder(json))), map(contactFolders => {
                console.log("serviceGetContactLists -> createOrUpdateContactFolders: ", contactFolders);
                this.databaseService.createOrUpdateContactFolders(contactFolders);
                return contactFolders;
              }), catchError(this.handleErrorObservable.bind(this)));
    }

    getContactTags() {
        return this.http.get(this.getUrl() + "/contacts/tags_with_count.json" , { headers: this.getHeaders(), withCredentials: true })
            .pipe(map((res: any) => res.tags.map(json => new ContactTag(json))), map(contactTags => {
                return contactTags;
              }), catchError(this.handleErrorObservable.bind(this)));
    }

    exportContacts(contact_filter: string, contact_type: string, export_type: string) {
        if (export_type === "vcf"){
            return this.http.get(this.getUrl() + "/contacts" + "." + export_type + contact_filter , { headers: this.getHeaders(), withCredentials: true })
            .pipe(map(res => {
                let vcard = this.convertToVcard(res);
                const blob = new Blob([vcard], {
                    type: "application/json",
                });
                return blob; }), catchError(this.handleErrorObservable.bind(this)));
        } else {
            return this.http.get(this.getUrl() + "/contacts" + "." + export_type + contact_filter , { headers: this.getHeaders(), withCredentials: true, responseType: "blob" })
            .pipe(map(res => {
                return res; }), catchError(this.handleErrorObservable.bind(this)));
        }
    }

    convertToVcard(res){
        let output = res.trim() + "\n";
        return output;
    }

    // getAllDirectoryContact(offset: number, limit: number, query?: string, global?: boolean,sortType?,sortOrder?:string): Observable<any> {
    //     if(!sortType) sortType = localStorage.getItem(AppConstants.SORT_TYPE);
    //     console.log("[SortType]: ", sortType);
    //     if (query) {
    //         let url = this.getAPIUrl() + "/api/searchContacts?offset=" + offset + "&limit=" + limit + "&searchText=" + query + "&sorttype=" + sortType + "&all=1" + "&sortOrder=" + sortOrder;

    getContactListContact(id: string, query = "",sortType?, sortOrder :string = "asc", offset: number = 0, limit:number = 25): Observable<any> {
        console.log("getContactListContact", id);
        if (!sortType) {sortType = localStorage.getItem(AppConstants.SORT_TYPE);}
        console.log("[SortType]: ", sortType);
        let url = this.getAPIUrl() + `/api/searchContactsWithListId?all=1&contact_list=` + id + "&sorttype=" + sortType + "&sortOrder=" + sortOrder;
        if (query) {
            url += `&q=${query}`;
        }
        if (limit) {
            url += `&offset=${offset}&limit=${limit}`;
        }
        return this.http.get(url, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }


    getTagContact(id: string, query = "",sortType?, sortOrder :string = "desc"): Observable<any> {
        console.log("getContactListContact", id);
        if (!sortType) {sortType = localStorage.getItem(AppConstants.SORT_TYPE);}
        console.log("[SortType]: ", sortType);
        let url = this.getAPIUrl() + `/api/searchContactsWithTagId?all=1&tags=` + id + "&sorttype=" + sortType + "&sortOrder=" + sortOrder;
        if (query) {
            url += `&q=${query}`;
        }
        return this.http.get(url, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    getContactsGroup(sortType :string = "desc"): Observable<any> {
        if (!sortType) {sortType = localStorage.getItem(AppConstants.SORT_TYPE);}
        console.log("[SortType]: ", sortType);
        let url = this.getAPIUrl() + `/api/searchContactsGlobal?all=1&first_group=` + sortType;

        return this.http.get(url, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    // getTagContact(id: string, query = ""): Observable<any> {
    //     let url = this.getUrl() + `/contacts.json?all=1&tags=` + id;
    //     if (query) {
    //         url += `&q=${query}`;
    //     }
    //     return this.http.get(url, { headers: this.getHeaders(), withCredentials: true })
    //         .pipe(catchError(this.handleErrorObservable.bind(this)));
    // }

    getMyProducts(): Observable<any> {
        return this.http.get(this.getAPIUrl() + "/api/my-products", { headers: this.getHeaders() })
        .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
    }

    syncZimbraContacts(): Observable<any> {
        return this.http.get(this.getUrl() + "/contacts/sync_zimbra_contacts.json", { headers: this.getHeaders() })
        .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
    }

    bulkUpdateListTags(body: any): Observable<any> {
        return this.http.post(this.getUrl() + "/contacts/bulk_update.json",
            body, { headers: this.getHeaders() })
          .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
    }

    getTrashContacts(offset: number, limit: number, sort: string = "desc"): Observable<any> {

        return this.http.get(this.getAPIUrl() + "/api/getTrashContacts?offset=" + offset + "&limit=" + limit + "&sort=" + sort, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)), tap(v => {
                console.log("createContactTrashed", v);
                if (v && v.contacts) {
                    this.databaseService.createOrUpdateContacts(v.contacts).subscribe();
                }
            }));
    }

    getDuplicateContacts(): Observable<any> {
        return this.http.get(this.getUrl() + "/contacts/duplicates.json", { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    mergeContact(id: string): Observable<any> {
        return this.http.post(this.getUrl() + "/contacts/" + id + "/merge_contact.json", {}, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    mergeAllContact(): Observable<any> {
        return this.http.post(this.getUrl() + "/contacts/merge_all.json", {}, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    dismissContact(id: string): Observable<any> {
        return this.http.post(this.getUrl() + "/contacts/" + id + "/dismiss_contact.json", {}, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

     mapSearchItem(doc) {
        const content = doc.content_txt ? doc.content_txt[0] : "";
        const raw = doc.raw_txt ? doc.raw_txt[0] : "";
        let parsedContent = content;
        const rawTxt = raw.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'");
        if (rawTxt !== "") {
            parsedContent = rawTxt;
        }
        const shortContent = parsedContent;
        let id = doc.id;
        let objectId = doc.id;
        if (doc.type_s === "issue") {
            id = doc.issue_id_i;
        } else if (doc.type_s === "mail") {
            id = doc.mail_id_i;
            objectId = objectId.replace(`.${doc.mail_id_i}`, "");
        } else if (doc.type_s === "talk") {
            objectId = objectId.replace(`.${doc.owner_s}`, "").replace(`talk.${doc.talk_id_s}.`, "");
        }
        return {
            id: id,
            objectId: objectId,
            talkId: doc.talk_id_s,
            owner: doc.owner_s,
            title: doc.title_s ? doc.title_s.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'") : "",
            contentTxt: content.replace(/\\n/g, "<br />"),
            parsedContent: parsedContent.replace(/\\n/g, "<br />"),
            shortContent: shortContent.replace(/\\n/g, "<br />"),
            chatType: doc.talk_type_s,
            rawTxt: raw,
            createdDt: doc.created_dt,
            modifiedDt: doc.modified_dt,
            from: doc.from_s,
            to: doc.to_ss,
            type: doc.type_s,
            version: doc._version_,
            mailFolderID: doc.mail_folder_id_i,
            unread: doc.mail_unread_b,
            flags: doc.mail_flags_s,
            issue_type: doc.issue_type ?  doc.issue_type[0] : ""
        } as SearchItem;
    }

    public searchDocs(params: any): Observable<SearchResponse> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        return this.http.get(this.config.API_URL + "/api/search", { headers: headers, params: params }).pipe(map((res: any) => {
            let docs = [];
            let numFound = 0;
            let start = 0;
            let groups = {};
            if (res.response) {
                docs = res.response.docs.map(val => {
                    return this.mapSearchItem(val);
                });
                numFound = res.response.numFound;
                start = res.response.start;
            } else if (res.grouped && res.grouped.type_s && res.grouped.type_s.groups) {
                numFound = res.grouped.type_s.matches;
                res.grouped.type_s.groups.forEach(group => {
                    if (group.doclist.start) {
                        start = group.doclist.start;
                    }
                    const _docs = group.doclist.docs.map(val => {
                        return this.mapSearchItem(val);
                    });
                    groups[group.groupValue] = _docs;
                    docs = [...docs, ..._docs];
                });
            } else if (res.grouped && res.grouped.from_s && res.grouped.from_s.groups) {
                numFound = res.grouped.from_s.matches;
                res.grouped.from_s.groups.forEach(group => {
                    if (group.doclist.start) {
                        start = group.doclist.start;
                    }
                    const _docs = group.doclist.docs.map(val => {
                        return this.mapSearchItem(val);
                    });
                    groups[group.groupValue] = _docs;
                    docs = [...docs, ..._docs];
                });
            }
            if (res.facet_counts && res.facet_counts.facet_fields && res.facet_counts.facet_fields.type_s) {
                const apps = {};
                const type = res.facet_counts.facet_fields.type_s;
                for (let i = 0; i <= type.length; i++) {
                    if (typeof type[i] === "string" && i < type.length - 1) {
                        apps[type[i]] = type[i + 1];
                    }
                }
                console.log("[searchDocs]", apps);
            } else {
            }
            return { docs: docs, numFound: numFound, start: start, groups: groups } as SearchResponse;
        }));
    }

    markAsFavorite(groupId: string, contactId: string): Observable<any> {
        const ids = {"contact_ids": [contactId]};
        return this.http.post(this.getUrl() + "/contact_groups/" + groupId + "/add_contacts.json", ids , { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    markAsNotFavorite(groupId: string, contactId: string): Observable<any> {
        return this.http.delete(this.getAPIUrl() + "/api/deleteFavoriteInContactGroup?id=" + groupId + "&ids=" + contactId, { headers: this.getHeaders(), withCredentials: true })
        .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    getFavoriteGroup(): Observable<any> {
        return this.http.get(this.getUrl() + "/contact_groups.json?favorite=1", { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    createFavoriteContctGroup(): Observable<any> {
        const body = {
            "group": {
                "contact_group" : { "name" : "favorite", "favorite" :  "1", "contact_ids": ""  }
            }
        };
        return this.http.post(this.getAPIUrl() + "/api/createContactGroup", body, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    getLoggedInUserContact(): Observable<any> {
        return this.http.get(this.getUrl() + "/contacts/me.json", { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    updateLoggedInUserContactDetail(body: any): Observable<any> {
        const contactBody = {"contact" : body } ;
        return this.http.put(this.getUrl() + "/contacts/me.json", contactBody, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    updateTagColor(tagId: string, color: string): Observable<any> {
        const tagBody = {
            "tag": {
                "configs": {
                    "color_hex": color
                }
            }
        };
        return this.http.put(this.getUrl() + "/tags/" + tagId + ".json", tagBody, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    /** Provide count for contacts, Group contact and duplicate contact */
    getStatistics(): Observable<any> {
        return this.http.get(this.getUrl() + "/contacts/statistics.json", { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    deleteContactPermanently(ids: string): Observable<any> {
        return this.http.delete(this.getAPIUrl() + "/api/deleteContactPermanently?id=" + ids, { headers: this.getHeaders(), withCredentials: true })
        .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    recoverContactFromTrash(contactIds: any): Observable<any> {
        const body = {"ids": contactIds};
        return this.http.post(this.getUrl() + "/contacts/recover.json", body , { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    advanceSearchFromList(queryItem: string): Observable<any> {
        return this.http.get(this.getUrl() + "/contacts.json?" + queryItem, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    shareVCFToMobile(vcfData: string): void {
        window.requestFileSystem(window.TEMPORARY, 5 * 1024 * 1024, function (fs) {
            let month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            let date = new Date();
            let fileName = "vcards_" + date.getFullYear() + "" + month[date.getMonth()] + "" + date.getDate() + "_" + date.getTime() + ".vcf";
            fs.root.getFile(fileName, { create: true, exclusive: false }, function (fileEntry) {
                let dataObj;
                fileEntry.createWriter(function (fileWriter) {
                    fileWriter.onwriteend = function () {
                        /* Share contact in cordova */
                        let options = {
                            message: "VNCcontacts+ share",
                            subject: "VNCcontacts+ share",
                            files: [fileEntry.nativeURL],
                            chooserTitle: "Share with" // Android only, you can override the default share sheet title
                        };
                        let onSuccess = function (result) {
                        };
                        let onError = function (msg) {
                        };
                        window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
                    };
                    fileWriter.onerror = function (e) {
                    };
                    dataObj = new Blob([vcfData], { type: "text/vcard" });
                    fileWriter.write(dataObj);
                });
            }, function (error) {
            });
        }, function (error) {
        });
    }

    saveSearchQuery(body: any): Observable<any> {
        return this.http.post(this.getUrl() + "/contact_queries.json", body , { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    getSaveSearch(): Observable<any> {
        return this.http.get(this.getUrl() + "/contact_queries.json", { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    removeAllContactPermanent(): Observable<any> {
        return this.http.delete(this.getAPIUrl() + "/api/deleteAllContactPermanently", { headers: this.getHeaders(), withCredentials: true })
        .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    recoverAllContactFromTrash(): Observable<any> {
        const body = {"ids" : "all"};
        return this.http.post(this.getUrl() + "/contacts/recover.json", body , { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    getSearchFromQuery(q: string): Observable<any> {
        return this.http.get(this.getUrl() + "/contacts.json?all=1&q=" + q, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    getContactFilterList(): Observable<any> {
        return this.http.get(this.getUrl() + "/queries/filters.json?query_class=ContactQuery", { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    getSearchFromAdvanceSearchQuery(offset: number, limit: number, q: string): Observable<any> {
        const sortType = localStorage.getItem(AppConstants.SORT_TYPE);
        return this.http.get(this.getUrl() + "/contacts.json?all=1&offset=" + offset + "&limit=" + limit + "&sort=name:asc" + "&" + q , { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    importContactFromBol(blob: any): Observable<any> {
        console.log("[blob]: ", blob);
        const apiKey = JSON.parse(localStorage.profileUser).api_key;
        let headers = new HttpHeaders({
            "Content-Type": "application/octet-stream",
            "X-Redmine-API-Key": apiKey
        });
        if (this.isCordovaOrElectron) {
            let token = localStorage.getItem("token");
            if (token) {
                headers = headers.set("Authorization", localStorage.getItem("token"));
            }
        }

        return this.http.post(this.getUrl() + "/contacts/import.json?content_type=text/x-vcard", blob , { headers: headers, withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    shareContactViaEmail(body: any): Observable<any> {
        return this.http.post(this.getUrl() + "/contacts/share_by_email.json", body, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    getFrequentlyContacts(offset: number, limit: number): Observable<any> {
        const sortType = localStorage.getItem(AppConstants.SORT_TYPE);
        return this.http.get(this.getAPIUrl() + "/api/getFrequentContacts?offset=" + offset + "&limit=" + limit , { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    importCSVContactFromBol(blob: any, mapping: string): Observable<any> {
        console.log("[importCSVContactFromBol][blob]: ", blob);
        const apiKey = JSON.parse(localStorage.profileUser).api_key;
        let headers = new HttpHeaders({
            "Content-Type": "application/octet-stream",
            "X-Redmine-API-Key": apiKey
        });
        if (this.isCordovaOrElectron) {
            let token = localStorage.getItem("token");
            if (token) {
                headers = headers.set("Authorization", localStorage.getItem("token"));
            }
        }
        const queryString = "content_type=text/csv&encoding=UTF-8&separator=,&wrapper=&" + mapping;
        return this.http.post(this.getUrl() + "/contacts/import.json?" + queryString, blob , { headers: headers, withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    undoChangesContact(body: any): Observable<any> {
        const bodyRequest = { date_time: body };
        return this.http.post(this.getUrl() + "/contacts/revert.json", bodyRequest, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    getStoredAuthUser(): Observable<AuthUser> {
        const response = new Subject<AuthUser>();
        let storedUserRaw = localStorage.getItem("profileUser");
        console.log("contact-service storedUser: ", storedUserRaw);
        try {
            const storedUser = JSON.parse(storedUserRaw);
            const user = new AuthUser(storedUser);
            console.log("contact-service returning storedUser: ", user);
            setTimeout(() => {
                response.next(user);
            }, 1);

        } catch (error) {
            response.error(error);
        }
        return response.asObservable().pipe(take(1));
    }

    pushFcmToken(token: any): Observable<any> {
        console.log("[contact.service][pushFcmToken]: ", token);
        let body = {
            "device_token": {
                "value": token,
                "os": "android",
                "application": "vnccontacts"
            }
        };
        return this.http.post(this.getAPIUrl() + "/api/storefcm", body, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)));
    }

    seachContactDuplicate(offset: number, limit: number, query?: string, global?: boolean,sortType?,sortOrder?:string): Observable<any> {
        if (!sortType) {sortType = localStorage.getItem(AppConstants.SORT_TYPE);}
        if (query) {
            let url = this.getAPIUrl() + "/api/seachContactDuplicate?offset=" + offset + "&limit=" + limit + "&searchText=" + query + "&sorttype=" + sortType + "&all=1" + "&sortOrder=" + sortOrder;
            if (global) {
                url = this.getAPIUrl() + "/api/seachContactDuplicate?offset=" + offset + "&limit=" + limit + "&searchText=" + query + "&sorttype=" + sortType + "&global=1" + "&sortOrder=" + sortOrder;
            }
            return this.http.get(url, { headers: this.getHeaders(), withCredentials: true })
            .pipe(catchError(this.handleErrorObservable.bind(this)), tap(v => {
            }));
        }
    }

    checkInstalledApp(uri: string, appName: string): Observable<boolean> {
        const subject = new Subject<boolean>;
        if (environment.isCordova) {
            if (CommonUtil.isOnAndroid()) {
                uri = CommonUtil.getPackageName(appName);
            }
            uri = uri.replace("main", "");
            console.log("[checkInstalledApp]", uri);
            appAvailability.check(
                uri, // URI Scheme
                (data) => {
                    console.log("[checkInstalledApp] installed", appName, data);
                    subject.next(true);
                },
                () => {
                    console.log("[checkInstalledApp] not installed", appName);
                    subject.next(false);
                    const dialogArgs = {
                        autoFocus: false,
                        maxWidth: "100%",
                        data: { appName: appName },
                        panelClass: "install_app_dialog",
                    };
                    // this.matDialog.open(InstallAppDialogComponent, dialogArgs);
                }
            );
        } else {
            subject.next(true);
        }

        return subject.asObservable().pipe(take(1));
    }

}
