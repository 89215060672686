
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { AppActionTypes } from "../../../actions/app";
import { ContactTag } from "../../models/contact-tag.model";


export class ContactTagActionTypes extends AppActionTypes {

  static LOAD_TAGS = "[TAGS] Load Tags";
  static LOAD_TAGS_SUCCESS = "[TAGS] Load Tags Success";
  static LOAD_TAGS_FAIL = "[TAGS] Load Tags Fail";
}

export class LoadContactTagsSuccess implements Action {
    readonly type = ContactTagActionTypes.LOAD_TAGS_SUCCESS;
    constructor(public payload: ContactTag[]) {}
}

export class LoadContactTags implements Action {
    readonly type = ContactTagActionTypes.LOAD_TAGS;
}

export class LoadContactTagsFail implements Action {
    readonly type = ContactTagActionTypes.LOAD_TAGS_FAIL;
}
