
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, NgZone, ChangeDetectorRef, ViewChild, ElementRef } from "@angular/core";
import { Subject } from "rxjs";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ContactRootState, getContactTags } from "src/app/contacts/store";
import { Store } from "@ngrx/store";
import { takeUntil, take } from "rxjs/operators";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { ContactTag } from "src/app/contacts/models/contact-tag.model";
import { Router } from "@angular/router";
import { TagContactDialogComponent } from "../tag-contact-dialog/tag-contact-dialog.component";
import { ToastService } from "src/app/common/service/tost.service";
import { ContactRepository } from "src/app/contacts/repository/contact.repository";
import { RootState } from "src/app/reducers";
import { SetRoutingType } from "src/app/actions/app";
import { AppConstants } from "src/app/common/utils/app-constants";

@Component({
    selector: "vp-contact-tags-dialog",
    templateUrl: "./contact-tags-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactTagsDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    contactTags: ContactTag[] = [];
    isSearchView: boolean = false;
    searchText: string = "";
    @ViewChild("searchTextInput", {static: false}) searchTextInput: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<ContactTagsDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private changeDetectionRef: ChangeDetectorRef,
        private store: Store<ContactRootState | RootState>,
        private router: Router,
        private matDialog: MatDialog,
        private toastService: ToastService,
        private contactRepository: ContactRepository
    ) {
        this.store.dispatch(new SetRoutingType(AppConstants.TAG));
    }

    ngOnInit() {
        this.store.select(getContactTags).pipe(takeUntil(this.isAlive$)).subscribe((contactTags: ContactTag[]) => {
            this.contactTags = contactTags;
            this.changeDetectionRef.markForCheck();
          });
        this.broadcaster.on<any>(BroadcastKeys.HIDE_CONTACT_TAGS_DAILOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    ngOnDestroy() {
        this.contactRepository.setRouteTypeBasedonURL();
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    openSearchView(value: boolean): void {
        if (value) {
            setTimeout(() => {
                this.searchTextInput.nativeElement.focus();
            }, 200);
        }
        this.isSearchView = value;
        this.changeDetectionRef.markForCheck();
    }

    emptySearchText(): void {
        this.searchText = "";
        this.changeDetectionRef.markForCheck();
    }

    close(): void {
        this.broadcaster.broadcast(BroadcastKeys.SHOW_HAMBURGER_MENU);
        this.dialogRef.close();
    }


    routeToTagFolder(tag: ContactTag): void {
        console.log("[routeToTagFolder]: ", tag);
        this.contactRepository.getContactFromTag(tag.id.toString());
        this.matDialog.open(TagContactDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "tag-contact-dialog",
            data: {listItem: tag}
        });
    }

    createTagDialog(): void {
        this.toastService.show("COMING_SOON_LBL");
    }

}