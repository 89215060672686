
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { ContactTagActionTypes } from "../actions/contact-tag.action";
import { ContactTag } from "../../models/contact-tag.model";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";


export interface ContactTagState extends EntityState<ContactTag> {
  selectedContactTag: ContactTag;
  isLoading: boolean;
  isLoaded: boolean;
}

export const contactTagAdapter: EntityAdapter<ContactTag> = createEntityAdapter<ContactTag>({
  selectId: (contactTag: ContactTag) => contactTag.id
});

export const initialState: ContactTagState = contactTagAdapter.getInitialState({
  selectedContactTag: null,
  isLoading: false,
  isLoaded: false
});

export function contactTagReducer(state = initialState, action: Action): ContactTagState {
  switch (action.type) {

    case ContactTagActionTypes.LOAD_TAGS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ContactTagActionTypes.LOAD_TAGS_SUCCESS: {
      return contactTagAdapter.addAll(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case ContactTagActionTypes.LOAD_TAGS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case ContactTagActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.ContactTagState;
      return savedState ? { ...state, ...savedState } : state;
    }

    default: {
      return state;
    }
  }
}

export const _getSelectedContactTag = (state: ContactTagState) => state.selectedContactTag;
export const _getIsLoading = (state: ContactTagState) => state.isLoading;
export const _getIsLoaded = (state: ContactTagState) => state.isLoaded;
