
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef, NgZone, ViewChild, ElementRef, Inject } from "@angular/core";
import { Subject } from "rxjs";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RootState, getSaveSearch, getQueryFilters, getSearchFilters } from "src/app/reducers";
import { ContactRootState } from "src/app/contacts/store";
import { Store } from "@ngrx/store";
import { takeUntil, take, debounceTime } from "rxjs/operators";
import * as i18nIsoCountries from "i18n-iso-countries";
import { ConfigService } from "src/app/common/providers/config.service";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { ContactService } from "src/app/common/service/contact-service";
import { Contact } from "src/app/common/models";
import { ContactRepository } from "src/app/contacts/repository/contact.repository";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { ToastService } from "src/app/common/service/tost.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { SetFilterForSearch } from "src/app/actions/app";
import { AppConstants } from "src/app/common/utils/app-constants";

@Component({
    selector: "vp-advance-search-component",
    templateUrl: "./advance-search.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvanceSearchComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    saveSearch: any[] = [];
    searchFilter: string = "";
    isShowFilter: boolean = false;
    isShowSaveSearchDialog: boolean = false;
    searchPhrase: string = "";
    selectedFilter: any[] = [];
    country: any[] = [];
    isMultipleContry: boolean = false;
    isShowDropDownCountry: boolean = false;
    searchCountry: string = "";
    isMobileScreen: boolean = false;
    showMobileSavedSearch: boolean = false;
    contacts: Contact[] = [];
    filterLists: any[] = [];
    saveSearchName: string = "";
    searchSaved: boolean = false;
    SAVE_SEARCH_TIME_OUT: any = 5000;
    subscription: any;
    searchRecentActivity: boolean = false;
    @ViewChild("searchTextInput", {static: false}) searchTextInput: ElementRef<HTMLInputElement>;

    constructor(
        public dialogRef: MatDialogRef<AdvanceSearchComponent>,
        private store: Store<ContactRootState | RootState>,
        private changeDetectorRef: ChangeDetectorRef,
        private configService: ConfigService,
        private breakpointObserver: BreakpointObserver,
        private contactService: ContactService,
        private contactRepository: ContactRepository,
        private translateService: TranslateService,
        private router: Router,
        private dateFormtter: DatePipe,
        private toastService: ToastService,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/" + this.configService.language + ".json"));
        const allContry = i18nIsoCountries.getNumericCodes();
        Object.keys(allContry).forEach(key => {
           this.country.push({code: allContry[key], name: i18nIsoCountries.getName(allContry[key], this.configService.language)});
        });
        console.log("[country]: ", this.country);
        this.breakpointObserver.observe(["(max-width: 766px)"]).pipe(takeUntil(this.isAlive$)).subscribe((state: BreakpointState) => {
            if (state.matches) {
                this.isMobileScreen = true;
            } else {
                this.isMobileScreen = false;
            }
            this.changeDetectorRef.markForCheck();
        });
    }

    ngOnInit() {
        this.store.select(getSaveSearch).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.saveSearch = res;
            console.log("[saveSearch] ", this.saveSearch);
            this.changeDetectorRef.markForCheck();
        });
        this.store.select(getQueryFilters).pipe(takeUntil(this.isAlive$)).subscribe((filter: any) => {
            this.filterLists = filter.filter(v => !!v).filter(v => v.name !== "q");
            this.changeDetectorRef.markForCheck();
            console.log("[filters]:", this.filterLists);
        });
        this.broadcaster.on<any>(BroadcastKeys.HIDE_SAVE_SEARCH_DAILOG_COMPONENT).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        setTimeout(() => {
            if (this.searchTextInput) {
              this.searchTextInput.nativeElement.focus();
            }
        }, 500);
        if (!!this.data && this.data.isRefineSearch) {
            setTimeout(() => {
                let filters: any[] = [];
                this.store.select(getSearchFilters).pipe(take(1)).subscribe(res => {
                filters = res;
                console.log("[isRefineSearch]: ", filters);
                this.selectedFilter = [];
                this.selectedFilter = filters.filter(v => !!v).filter(v => v.name !== "q");
                const isSearchText = filters.filter(v => !!v).filter(v => v.name === "q")[0];
                if (!!isSearchText) {
                    this.searchPhrase = isSearchText.valueSelectModel;
                }
                this.changeDetectorRef.markForCheck();
            });
            }, 500);
        }
        this.searchRecentActivity = false;
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    close(): void {
        this.dialogRef.close();
    }

    addFilter(filter: any): void {
        this.isShowFilter = false;
        const isFilterAvailable = this.selectedFilter.filter(sf => sf.name === filter.name)[0];
        if (!!isFilterAvailable) {
            return;
        }
        const setData = {
            name: filter.name,
            label: this.getKeyLabel(filter.name),
            operators: [],
            type: filter.type,
            values: null,
            isSelected: true,
            selectedValue: {},
            operatorModel: "",
            valueSelectModel: "",
            showMultiple: false,
            multiValueModel: []
        };
        if (filter.operator_labels) {
            const operators = filter.operator_labels;
            Object.keys(operators).forEach(key => {
                setData.operators.push({
                    label: operators[key],
                    value: key
                });
            });
            setData.operatorModel = setData.operators[0].value;
        }
        if (filter.values && filter.values !== null) {
            const values = filter.values;
            if (!!values && values.length > 0) {
                setData.values = [];
                for ( let i = 0; i < values.length ; i++) {
                    setData.values.push({
                        label: values[i][0],
                        value: values[i][1]
                    });
                }
                setData.valueSelectModel = setData.values[0].value;
            }
        }
        console.log("[addFilter][setData]", setData);
        this.selectedFilter.push(setData);
        this.changeDetectorRef.markForCheck();
        console.log("[addFilter]: ", filter);
    }

    showFilterOption(): void {
        this.isShowFilter = true;
        this.changeDetectorRef.markForCheck();
    }

    showSaveSearch(value: boolean): void {
        if (value) {
            this.saveSearchName = "";
            this.contacts = [];
        }
        this.isShowSaveSearchDialog = value;
        this.changeDetectorRef.markForCheck();
    }

    clearSearchPhrase(): void {
        this.searchPhrase = "";
        this.changeDetectorRef.markForCheck();
    }

    setCountryItem(item: any): void {
        this.changeDetectorRef.markForCheck();
    }

    showHideMultiple(filter: any): void {
        if (filter.showMultiple) {
            filter.showMultiple = false;
        } else {
            filter.showMultiple = true;
        }
        setTimeout(() => {
            if (filter.valueSelectModel !== "") {
                const select = <any> document.getElementById(filter.name);
                if (select !== null) {
                    for (let i = 0; i < select.options.length; i++) {
                        if (select.options[i].value === filter.valueSelectModel) {
                            select.options[i].selected = true;
                        }
                    }
                }
            }
        }, 10);
        this.changeDetectorRef.markForCheck();
    }

    hideFilterOption(): void {
        this.isShowFilter = false;
        this.changeDetectorRef.markForCheck();
    }

    hideShowSavedSearchMobile(value: boolean): void {
        this.showMobileSavedSearch = value;
        this.changeDetectorRef.markForCheck();
    }

    searchFromText(ev: any): void {
        if (ev.key === "Enter") {
            this.search();
        }
    }

    addToSearchField(contact: Contact): void {
        this.searchPhrase = contact.fullName;
        this.contacts = [];
        this.changeDetectorRef.markForCheck();
    }

    search(): void {
        const isBlank = this.isBlankField();
        if (isBlank) {
            return;
        }
        console.log("[selectedFilter]: ", this.selectedFilter);
        const searchType: string = "contact";
        const selectSaveSearchItem = this.getAllSelectedFilters();
        let flag = 0;
        if (!!selectSaveSearchItem && selectSaveSearchItem.length === 0) {
            if (this.isSearchPhraseBlank()) {
                this.toastService.show("SEARCH_CAN_NOT_BE_BLANK");
                flag = 1;
            }
        }
        if (flag === 1) {
            return;
        }
        let searchString: any[] = [];
        if (this.searchPhrase !== "") {
            searchString.push("q" + "=~" + this.searchPhrase);
        }
        selectSaveSearchItem.map(si => {
            if (!si.showMultiple) {
                if (si.type === "date_past") {
                    if (si.valueSelectModel !== "") {
                        searchString.push(si.name + "=" + si.operatorModel + this.dateFormtter.transform(si.valueSelectModel, "yyyy-MM-dd"));
                    }
                } else {
                    searchString.push(si.name + "=" + si.operatorModel + si.valueSelectModel);
                }
            } else {
                if (si.type === "integer") {
                    searchString.push(si.name + "=" + si.operatorModel + si.multiValueModel.join(","));
                } else {
                    searchString.push(si.name + "=" + si.operatorModel + si.multiValueModel.join("|"));
                }
            }
        });
        if (this.searchPhrase !== "") {
            const setData = {
                name: "q",
                label: this.getKeyLabel("q"),
                operators: [],
                type: "string",
                values: [this.searchPhrase],
                isSelected: true,
                selectedValue: {},
                operatorModel: "~",
                valueSelectModel: this.searchPhrase,
                showMultiple: false,
                multiValueModel: []
            };
            selectSaveSearchItem.push(setData);
        }
        if (this.searchRecentActivity) {
            searchString.push("advance=1");
        }
        this.broadcaster.broadcast("RESET_CONTACT_PLUS_LIST");
        this.store.dispatch(new SetFilterForSearch(selectSaveSearchItem));
        this.router.navigate(["/contactplus", "search"], { queryParams: { isAdvanceSearch: true, searchType: "contact", searchText: this.searchPhrase, query: searchString.join("&") } });
        this.close();
    }

    changeSelected(filter: any): void {
        const index = this.selectedFilter.indexOf(filter);
        console.log("[index]: ", index);
        if (index !== -1) {
            this.selectedFilter.splice(index, 1);
        }
    }

    getAllSelectedFilters(): any {
        const multiValueFilter = this.selectedFilter.filter(sf => sf.showMultiple);
        const singleValueFilter = this.selectedFilter.filter(sf => !sf.showMultiple);
        if (multiValueFilter.length > 0) {
            multiValueFilter.map(mf => {
                const select = <any> document.getElementById(mf.name);
                if (select !== null) {
                    mf.multiValueModel = [];
                    for (let i = 0; i < select.options.length; i++) {
                        if (select.options[i].selected) {
                            mf.multiValueModel.push(select.options[i].value);
                        }
                    }
                }
            });
        }
        return [...multiValueFilter, ...singleValueFilter];
    }

    getKeyLabel(key: string): string {
        let keyStr: string = "";
        switch (key) {
            case "first_name":
                keyStr = "FILTER_FIRST_NAME";
                break;
            case "last_name":
                keyStr = "FILTER_LAST_NAME";
                break;
            case "middle_name":
                keyStr = "FILTER_MIDDLE_NAME";
                break;
            case "updated_at":
                keyStr = "FILTER_UPDATE_DATE";
                break;
            case "deleted":
                keyStr = "FILTER_DELETED";
                break;
            case "deleted_at":
                keyStr = "FILTER_DELETE_ON";
                break;
            case "all":
                keyStr = "FILTER_ALL";
                break;
            case "q":
                keyStr = "FILTER_FULL_TEXT";
                break;
            case "dpt":
                keyStr = "FILTER_IN_MY_DEPARTMENT";
                break;
            case "contact_list":
                keyStr = "FILTER_CONTACT_LIST";
                break;
            case "contact_group":
                keyStr = "FILTER_CONTACT_GROUP";
                break;
            case "tags":
                keyStr = "FILTER_TAGS";
                break;
            case "jid":
                keyStr = "FILTER_JID";
                break;
            case "id":
                keyStr = "FILTER_ID";
                break;
            case "related_user.city":
                keyStr = "FILTER_RELATED_USER_CITY";
                break;
            case "related_user.nationality":
                keyStr = "COUNTRY";
                break;
            case "related_user.gender":
                keyStr = "FILTER_RELATED_USER_GENDER";
                break;
            case "related_user.admin":
                keyStr = "FILTER_RELATED_USER_ADMIN";
                break;
            case "related_user.login":
                keyStr = "FILTER_RELATED_USER_LOGIN";
                break;
            case "related_user.agb_accepted":
                keyStr = "FILTER_RELATED_USER_AGB_ACCEPTED";
                break;
            case "related_user.marital_status":
                keyStr = "FILTER_RELATED_USER_MARITAL_STATUS";
                break;
            case "related_user.vnc_employee":
                keyStr = "FILTER_RELATED_USER_VNC_EMPLOYEE";
                break;
            case "related_user.freelancer":
                keyStr = "FILTER_RELATED_USER_FREELANCER";
                break;
            case "related_user.start_date":
                keyStr = "FILTER_RELATED_USER_START_DATE";
                break;
            case "related_user.end_date":
                keyStr = "FILTER_RELATED_USER_END_DATE";
                break;
            case "related_user.per_week_availability":
                keyStr = "FILTER_RELATED_USER_PER_WEEK_AVAIALABILITY";
                break;
            case "related_user.hourly_rate":
                keyStr = "FILTER_RELATED_USER_HOURLY_RATE";
                break;
            case "related_user.payment_mode":
                keyStr = "FILTER_RELATED_PAYMENT_MODE";
                break;
            case "related_user.skills":
                keyStr = "FILTER_RELATED_SKILLS";
                break;
            case "related_user.interests":
                keyStr = "FILTER_RELATED_INTEREST";
                break;
            case "related_user.labels":
                keyStr = "FILTER_RELATED_LABELS";
                break;
            case "related_user.video_bridge":
                keyStr = "VIDEO_BRIDGE_LBL";
                break;
            case "related_user.omemo":
                keyStr = "OMEMO";
                break;
            case "related_user.paid_subscription":
                keyStr = "PAID_SUBSCRIPTION";
                break;
            case "related_user.status":
                keyStr = "STATUS";
                break;
            case "related_user.created_on":
                keyStr = "CREATED";
                break;
            case "related_user.last_login_on":
                keyStr = "LAST_CONNECTION";
                break;
            case "related_user.tfa_enabled":
                keyStr = "2FA_ENABLED";
                break;
            case "related_user.organization":
                keyStr = "ORGANIZATION_LBL";
                break;
        }
        let translatedString: string = "";
        this.translateService.get(keyStr).pipe(take(1)).subscribe((str: string) => {
            translatedString = str;
        });
        return translatedString;
    }

    saveAdvanceSearch(): void {
        const isBlank = this.isBlankField();
        if (isBlank) {
            return;
        }
        const f: any[] = [];
        const op: any = {};
        const v: any = {};
        const sendQuery: any = {};
        const selectSaveSearchItem = this.getAllSelectedFilters();
        console.log("[saveAdvanceSearch]: ", this.selectedFilter);
        console.log("[getAllSelectedFilters][saveAdvanceSearch]: ", selectSaveSearchItem);
        let flag = 0;
        if (!!selectSaveSearchItem && selectSaveSearchItem.length === 0) {
            if (this.isSearchPhraseBlank()) {
                this.toastService.show("SEARCH_CAN_NOT_BE_BLANK");
                flag = 1;
            }
        }
        if (flag === 1) {
            return;
        }
        if (this.searchPhrase !== "") {
            f.push("q");
            op.q = "~";
            v.q = [this.searchPhrase];
        }
        selectSaveSearchItem.map(si => {
            f.push(si.name);
            op[si.name] = si.operatorModel;
            if (!si.showMultiple) {
                if (si.type === "date_past") {
                    v[si.name] = [this.dateFormtter.transform(si.valueSelectModel, "yyyy-MM-dd")];
                } else {
                    v[si.name] = [si.valueSelectModel];
                }
            } else {
                v[si.name] = si.multiValueModel;
            }
        });
        sendQuery.f = f;
        sendQuery.op = op;
        sendQuery.v = v;
        sendQuery.query = {
            name: this.saveSearchName
        };
        this.contactRepository.saveAdvanceSearchQuery(sendQuery).subscribe(res => {
            setTimeout(() => {
                if (this.searchTextInput) {
                  this.searchTextInput.nativeElement.focus();
                }
            }, 500);
            if (res.error) {
                this.toastService.showPlainMessage(res.error[0]);
            } else {
                this.searchSaved = true;
                this.showSaveSearch(false);
                this.changeDetectorRef.markForCheck();
                setTimeout(() => {
                    this.searchSaved = false;
                    this.changeDetectorRef.markForCheck();
                }, this.SAVE_SEARCH_TIME_OUT);
            }
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }

    getSearchBlankField(): any {
        const selectSaveSearchItem = this.getAllSelectedFilters();
        const blankFields = selectSaveSearchItem.filter(sf => sf.showMultiple === false && sf.valueSelectModel === "" && sf.operatorModel !== "*");
        return blankFields;
    }

    isBlankField(): boolean {
        let _isBlank: boolean = false;
        const fields = this.getSearchBlankField();
        if (!!fields && fields.length > 0) {
            _isBlank = true;
            this.translateService.get("CAN_NOT_BE_BLANK").pipe(take(1)).subscribe((text: string) => {
                const errorMessage = this.getKeyLabel(fields[0].name) + " " + text;
                this.toastService.showPlainMessage(errorMessage);
            });
        }
        return _isBlank;
    }

    isFilterAvailable(filter: any): boolean {
        let isAvailable: boolean = false;
        const isItemAvailable = this.selectedFilter.filter( sf => sf.name === filter.name)[0];
        if (!!isItemAvailable) {
            isAvailable = true;
        }
        return isAvailable;
    }

    loadSaveSearch(search: any): void {
        console.log("[loadSaveSearch]: ", search);
        this.selectedFilter = [];
        search = search.filters;
        Object.keys(search).forEach(key => {
            if (key === "q") {
                this.searchPhrase = search[key].values[0];
            } else {
                const filter = this.filterLists.filter(fl => fl.name === key)[0];
                const setData = {
                    name: key,
                    label: this.getKeyLabel(key),
                    operators: [],
                    type: filter.type,
                    values: null,
                    isSelected: true,
                    selectedValue: {},
                    operatorModel: "",
                    valueSelectModel: "",
                    showMultiple: search[key].values.length > 1 ? true : false,
                    multiValueModel: []
                };
                if (filter.operator_labels) {
                    const operators = filter.operator_labels;
                    Object.keys(operators).forEach(key => {
                        setData.operators.push({
                            label: operators[key],
                            value: key
                        });
                    });
                    setData.operatorModel = search[key].operator;
                }
                if (filter.values && filter.values !== null) {
                    const values = filter.values;
                    if (!!values && values.length > 0) {
                        setData.values = [];
                        for ( let i = 0; i < values.length ; i++) {
                            setData.values.push({
                                label: values[i][0],
                                value: values[i][1]
                            });
                        }
                    }
                }
                setData.valueSelectModel = search[key].values[0];
                if (search[key].values.length > 1) {
                    setData.multiValueModel = search[key].values;
                }
                this.selectedFilter.push(setData);
            }
        });
        this.changeDetectorRef.markForCheck();
        this.selectedFilter.map(f => {
            setTimeout(() => {
                if (f.valueSelectModel !== "") {
                    const select = <any> document.getElementById(f.name);
                    if (select !== null) {
                        if (f.showMultiple) {
                            for (let i = 0; i < select.options.length; i++) {
                                for (let j = 0; j < f.multiValueModel.length; j++) {
                                    if (select.options[i].value === f.multiValueModel[j]) {
                                        select.options[i].selected = true;
                                    }
                                }
                            }
                        } else {
                            for (let i = 0; i < select.options.length; i++) {
                                if (select.options[i].value === f.valueSelectModel) {
                                    select.options[i].selected = true;
                                }
                            }
                        }
                    }
                }
            }, 10);
        });
    }

    resetField(): void {
        this.selectedFilter = [];
        this.searchPhrase = "";
        this.changeDetectorRef.markForCheck();
    }

    isSearchPhraseBlank(): boolean {
        let _isBlank: boolean = false;
        if (this.searchPhrase === "") {
            _isBlank = true;
        }
        return _isBlank;
    }

    searchInRecentActivity(ev: any): void {
        if (ev.checked) {
            this.searchRecentActivity = true;
        } else {
            this.searchRecentActivity = false;
        }
        this.changeDetectorRef.markForCheck();
    }

}
