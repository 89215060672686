
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, NgZone, OnDestroy } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { AboutDialogComponent } from "../about-dialog/about-dialog.component";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ConfigService } from "src/app/common/providers/config.service";
import { CommonUtil } from "src/app/common/utils/common.utils";
import { ServicedeskDialogComponent } from "../servicedesk-dialog/servicedesk-dialog.component";
import { HelpFaqDialogComponent } from "../help-faq-dialog/help-faq-dialog.component";
import { LegalNoticeDialogComponent } from "../legal-notice-dialog/legal-notice-dialog.component";
import { ContactSettingsDialogComponent } from "../contact-settings-dialog/contact-settings-dialog.component";
import { ToastService } from "src/app/common/service/tost.service";
import { UndoChangesDialogComponent } from "src/app/contacts/components/undo-changes-dialog/undo-changes-dialog.component";
import { AboutDialogMobileComponent } from "../about-dialog-mobile/about-dialog-mobile.component";

@Component({
  selector: "vp-mobile-about-dialog",
  templateUrl: "./mobile-about-dialog.component.html"
})
export class MobileAboutDialogComponent implements OnInit, OnDestroy {

  private isAlive$ = new Subject<boolean>();
  changeLog: string;

  constructor(
    public dialogRef: MatDialogRef<MobileAboutDialogComponent>,
    private matDialog: MatDialog,
    private mailBroadcaster: Broadcaster,
    private ngZone: NgZone,
    private configService: ConfigService,
    private toastService: ToastService
  ) {
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_MOBILE_ABOUT_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
  }

  ngOnInit() {
    if (CommonUtil.isOnIOS()) {
      setTimeout(() => {
        document.querySelector(".cdk-global-overlay-wrapper").removeAttribute("style");
      }, 50);
    }
    if (this.configService.URLS.changeLog) {
      this.changeLog = this.configService.URLS.changeLog;
    } else {
      this.changeLog = "https://vnclagoon.com/release-notes/";
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  close(): void {
    this.dialogRef.close();
  }

  aboutDialog(): void {
    this.matDialog.open(AboutDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact_about_dialog"
    });
  }

  serviceDeskDialog(): void {
    this.matDialog.open(ServicedeskDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact-service-desk-dialog"
    });
  }

  helpFaqDialog(): void {
    this.matDialog.open(HelpFaqDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact-help-faq-dialog"
    });
  }

  openLegaldialog(): void {
    this.matDialog.open(LegalNoticeDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact-legal-notice-dialog"
    });
  }
}
