<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="contact-dialog">
    <div class="contact__dialog-header" *ngIf="!isSearchView">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{ 'TAGS' | translate }}
        </div>
        <div class="mobile-back-button" (click)="openSearchView(true)">
            <button mat-button>
                <mat-icon class="disable-select">search</mat-icon>
            </button>
        </div>
    </div>
    <div class="contact__dialog-header search-header" *ngIf="isSearchView">
        <div class="mobile-back-button">
            <button mat-button (click)="openSearchView(false)">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            <mat-form-field floatLabel="never">
                <input matInput #searchTextInput class="form-control" [(ngModel)]="searchText" name="label"
                    placeholder="{{ 'SEARCH_TAGS' | translate }}" autocomplete="nope" />
            </mat-form-field>
        </div>
        <div class="mobile-back-button">
            <button mat-button *ngIf="searchText.length > 0" (click)="emptySearchText()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="contact__dialog-body">
        <div class="content">
            <div *ngFor="let tag of contactTags | vpListsSearch : searchText" class="folder-list-item" (click)="routeToTagFolder(tag)">
                <div class="disable-select">
                    <mat-icon [style.color]="(tag.color_hex) ? tag.color_hex : '#888888'" class="mdi-20px" fontSet="mdi" fontIcon="mdi-tag"></mat-icon>
                </div>
                <div class="name disable-select">
                    {{ tag.name }}
                </div>
                <div class="count disable-select">
                    {{ tag.contacts_count }}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="contact-add-menu-container">
    <button mat-fab (click)="createTagDialog()">
        <mat-icon class="mdi-24px" fontSet="mdi" fontIcon="mdi-tag-plus"></mat-icon>
    </button>
</div>