
<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="mobile_Sidebar_createtag_popup" class="contact_group_create_dialog">
    <div class="content">
        <div id="mobile_Sidebar_createfolder_header" class="message">
            {{ 'CREATE_GROUP' | translate }}
        </div>
        <div class="content">
            <mat-form-field>
                <input [(ngModel)]="grupTitle" #grupNameInput matInput autocomplete="off" autocorrect="off"
                    autocapitalize="off" spellcheck="false">
            </mat-form-field>
        </div>
        <div class="actions">
            <a id="mobile_Sidebar_createfolder_cancelbtn" (click)="closeDialog()">{{ 'CANCEL' | translate }}</a>
            <a id="mobile_Sidebar_createfolder_createbrn" [class.action-disabled]="grupTitle.length < 1"
                (click)="createGroup()">{{ 'CREATE' | translate }}</a>
        </div>
    </div>
</div>