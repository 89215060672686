
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { Update } from "@ngrx/entity/src/models";
import { AppActionTypes } from "../../../actions/app";
import { Contact } from "src/app/common/models";

export class TagContactsAcTypes extends AppActionTypes {
// load Contacts
static LOAD_TAG_CONTACTS = "[Contacts] Load tag Contacts";
static LOAD_TAG_CONTACTS_FAIL = "[Contacts] Load tag Contacts Fail";
static LOAD_TAG_CONTACTS_SUCCESS = "[Contacts] Load tag Contacts Success";

// load nex Contacts
static NEXT_LOAD_TAG_CONTACTS = "[Contacts] Next Load tag Contacts";
static NEXT_LOAD_TAG_CONTACTS_FAIL = "[Contacts] Next Load tag Contacts Fail";
static NEXT_LOAD_TAG_CONTACTS_SUCCESS = "[Contacts] Next Load tag Contacts Success";

// create Contact
static CREATE_TAG_CONTACT = "[Contacts] Create tag Contact";
static CREATE_TAG_CONTACT_FAIL = "[Contacts] Create tag Contact Fail";
static CREATE_TAG_CONTACT_SUCCESS = "[Contacts] Create tag Contact Success";
// update Contact
static UPDATE_TAG_CONTACT = "[Contacts] Update tag Contact";
static UPDATE_TAG_CONTACT_FAIL = "[Contacts] Update tag Contact Fail";
static UPDATE_TAG_CONTACT_SUCCESS = "[Contacts] Update tag Contact Success";
// remove Contact
static REMOVE_TAG_CONTACT = "[Contacts] Remove tag Contact";
static REMOVE_TAG_CONTACT_FAIL = "[Contacts] Remove tag Contact Fail";
static REMOVE_TAG_CONTACT_SUCCESS = "[Contacts] Remove tag Contact Success";

static SEARCH_TOGGLE = "[Contacts] Toggle search mode";
static REMOVE_MANY_TAG_CONTACT_SUCCESS = "[Contacts] Remove Many tag Contact Success";

// remove Contact
static RESTORE_TAG_CONTACT = "[Contacts] Restore tag Contact";
static RESTORE_TAG_CONTACT_FAIL = "[Contacts] Restore tag Contact Fail";
static RESTORE_TAG_CONTACT_SUCCESS = "[Contacts] Restore tag Contact Success";

static START_TAG_LOADING = "[Contacts] Start tag Loading";
static STOP_TAG_LOADING = "[Contacts] Stop tag Loading";

static STORE_TAG_SEARCHPARAMS = "[Contacts] Store tag search parameter";

static EMPTY_TAG_CONTACTS_SUCCESS = "[Contacts] Empty tag Contacts";

static UPDATE_MANY_TAG_CONTACT_SUCCESS = "[Contacts] Update Many tag Contact Success";

}

export class LoadTagContacts implements Action {
  readonly type = TagContactsAcTypes.LOAD_TAG_CONTACTS;
}

export class SetSearchMode implements Action {
  readonly type = TagContactsAcTypes.SEARCH_TOGGLE;
  constructor(public payload: boolean) { }
}

export class StoreTagSearchParams implements Action {
  readonly type = TagContactsAcTypes.STORE_TAG_SEARCHPARAMS;
  constructor(public payload: any) { }
}

export class LoadTagContactsFail implements Action {
  readonly type = TagContactsAcTypes.LOAD_TAG_CONTACTS_FAIL;
}

export class LoadTagContactsSuccess implements Action {
  readonly type = TagContactsAcTypes.LOAD_TAG_CONTACTS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreContacts: boolean, contact: Contact[] }) { }
}

export class NextLoadTagContacts implements Action {
  readonly type = TagContactsAcTypes.NEXT_LOAD_TAG_CONTACTS;
}

export class NextLoadTagContactsFail implements Action {
  readonly type = TagContactsAcTypes.NEXT_LOAD_TAG_CONTACTS_FAIL;
}

export class NextLoadTagContactsSuccess implements Action {
  readonly type = TagContactsAcTypes.NEXT_LOAD_TAG_CONTACTS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreContacts: boolean, contacts: Contact[] }) { }
}

export class CreateTagContact implements Action {
  readonly type = TagContactsAcTypes.CREATE_TAG_CONTACT;
}

export class CreateTagContactFail implements Action {
  readonly type = TagContactsAcTypes.CREATE_TAG_CONTACT_FAIL;
}

export class CreateTagContactSuccess implements Action {
  readonly type = TagContactsAcTypes.CREATE_TAG_CONTACT_SUCCESS;
  constructor(public payload: Contact) { }
}

export class UpdateTagContact implements Action {
  readonly type = TagContactsAcTypes.UPDATE_TAG_CONTACT;
}

export class UpdateTagContactFail implements Action {
  readonly type = TagContactsAcTypes.UPDATE_TAG_CONTACT_FAIL;
}

export class UpdateTagContactSuccess implements Action {
  readonly type = TagContactsAcTypes.UPDATE_TAG_CONTACT_SUCCESS;
  constructor(public payload: Update<Contact>) { }
}

export class RemoveTagContact implements Action {
  readonly type = TagContactsAcTypes.REMOVE_TAG_CONTACT;
}

export class RemoveTagContactFail implements Action {
  readonly type = TagContactsAcTypes.REMOVE_TAG_CONTACT_FAIL;
}

export class RemoveTagContactSuccess implements Action {
  readonly type = TagContactsAcTypes.REMOVE_TAG_CONTACT_SUCCESS;
  constructor(public payload: string) { }
}

export class RemoveMultipleTagContactSuccess implements Action {
  readonly type = TagContactsAcTypes.REMOVE_MANY_TAG_CONTACT_SUCCESS;
  constructor(public payload: string[]) { }
}

export class EmptyTagContactSuccess implements Action {
  readonly type = TagContactsAcTypes.EMPTY_TAG_CONTACTS_SUCCESS;
  constructor() { }
}

export class RestoreTagContact implements Action {
  readonly type = TagContactsAcTypes.RESTORE_TAG_CONTACT;
}

export class RestoreTagContactFail implements Action {
  readonly type = TagContactsAcTypes.RESTORE_TAG_CONTACT_FAIL;
}

export class RestoreTagContactSuccess implements Action {
  readonly type = TagContactsAcTypes.RESTORE_TAG_CONTACT_SUCCESS;
  constructor(public payload: Contact[]) { }
}

export class StartTagLoading implements Action {
  readonly type = TagContactsAcTypes.START_TAG_LOADING;
}

export class StopTagLoading implements Action {
  readonly type = TagContactsAcTypes.STOP_TAG_LOADING;
}

export class UpdateMultipleTagContactSuccess implements Action {
  readonly type = TagContactsAcTypes.UPDATE_MANY_TAG_CONTACT_SUCCESS;
  constructor(public payload: Update<Contact>[]) { }
}


export type ContactsAction =
  | LoadTagContacts
  | LoadTagContactsFail
  | LoadTagContactsSuccess
  | NextLoadTagContacts
  | NextLoadTagContactsFail
  | NextLoadTagContactsSuccess
  | CreateTagContact
  | CreateTagContactFail
  | CreateTagContactSuccess
  | UpdateTagContact
  | UpdateTagContactFail
  | UpdateTagContactSuccess
  | RemoveTagContact
  | RemoveTagContactFail
  | RemoveTagContactSuccess
  | RemoveMultipleTagContactSuccess
  | SetSearchMode
  | RestoreTagContact
  | RestoreTagContactFail
  | RestoreTagContactSuccess
  | StartTagLoading
  | StopTagLoading
  | StoreTagSearchParams
  | EmptyTagContactSuccess
  | UpdateMultipleTagContactSuccess;
