
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, OnDestroy, ChangeDetectionStrategy, Component, ChangeDetectorRef, ViewChild, ElementRef, Inject, NgZone } from "@angular/core";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { Subject } from "rxjs";
import { takeUntil, take } from "rxjs/operators";
import { Contact } from "src/app/common/models";
import { ContactRepository } from "src/app/contacts/repository/contact.repository";
import { Store } from "@ngrx/store";
import { ContactRootState, getContacts, getIsContactsLoading, getContactFolders, getContactTags } from "src/app/contacts/store";
import { RootState, getFederatedApps } from "src/app/reducers";
import { ContactDetailDialogComponent } from "src/app/contacts/components/contact-detail-dialog/contact-detail-dialog.component";
import { ToastService } from "src/app/common/service/tost.service";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogType } from "src/app/common/models/dialog.model";
import { AddToListsDialogComponent } from "../add-to-list-dialog/add-to-list-dialog.component";
import { ContactTag } from "src/app/contacts/models/contact-tag.model";
import { AddToTagDialogComponent } from "../add-to-tag-dialog/add-to-tag-dialog.component";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "vp-save-search-contact-dialog",
    templateUrl: "./save-search-contact-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SaveSearchContactDialogComponent implements OnDestroy, OnInit {

    private isAlive$ = new Subject<boolean>();
    isSearchView: boolean = false;
    contacts: Contact[] = [];
    isLoading: boolean = false;
    searchText: string = "";
    headerText: string = "";
    allChecked: boolean = false;
    totalSelectedContact: number = 0;
    checkedContact: Contact[] = [];
    isShowSendEmailOption: boolean = true;
    isShowTalkOptions: boolean = true;
    fedrateApps: any;
    isCordova: boolean = false;
    @ViewChild("searchTextInput", { static: false }) searchTextInput: ElementRef;

    constructor(
        private dialogRef: MatDialogRef<SaveSearchContactDialogComponent>,
        private broadcaster: Broadcaster,
        private changeDetectionRef: ChangeDetectorRef,
        private matDialog: MatDialog,
        private contactRepository: ContactRepository,
        private appStore: Store<ContactRootState | RootState>,
        private tostService: ToastService,
        private ngZone: NgZone,
        private activatedRoute: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (this.data.listItem) {
            this.headerText = this.data.listItem.name;
        }
        this.isCordova = environment.isCordova;
        this.changeDetectionRef.markForCheck();
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_CONTACT_SAVE_SEARCH_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(presence => {
            this.ngZone.run(() => {
                this.close();
            });
        });

        this.appStore.select(getContacts).pipe(takeUntil(this.isAlive$)).subscribe((contacts: Contact[]) => {
            if (!!contacts && contacts.length > 0) {
                const withoutDeleteContact = contacts.filter(c => !c.deleted_at);
                console.log("[getContacts]: ", withoutDeleteContact);
                this.contacts = withoutDeleteContact;
                this.changeDetectionRef.markForCheck();
            } else if (!!contacts && contacts.length === 0) {
                this.contacts = [];
                this.changeDetectionRef.markForCheck();
            }
        });

        this.appStore.select(getIsContactsLoading).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.isLoading = res;
            this.changeDetectionRef.markForCheck();
        });
        this.appStore.select(getContactTags).pipe(takeUntil(this.isAlive$)).subscribe((contactTag: ContactTag[]) => {
            this.headerText = this.data.listItem.name;
            this.changeDetectionRef.markForCheck();
        });
        this.appStore.select(getFederatedApps).pipe(takeUntil(this.isAlive$)).subscribe(apps => {
            console.log("[getFederatedApps]", apps);
            this.fedrateApps = apps;
            const isMailAppAvailable = this.fedrateApps.filter(fa => fa.name === "vncmail")[0];
            if (!!isMailAppAvailable) {
                this.isShowSendEmailOption = true;
            } else {
                this.isShowSendEmailOption = false;
            }
            const isTalkAppAvailable = this.fedrateApps.filter(fa => fa.name === "vnctalk")[0];
            if (!!isTalkAppAvailable) {
                this.isShowTalkOptions = true;
            } else {
                this.isShowTalkOptions = false;
            }
        });
        this.broadcaster.on<any>(BroadcastKeys.RESET_CONTACT_LIST).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.resetSelection();
        });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    openSearchView(value: boolean): void {
        if (value) {
            setTimeout(() => {
                this.searchTextInput.nativeElement.focus();
            }, 200);
        }
        this.isSearchView = value;
        this.changeDetectionRef.markForCheck();
    }

    scrollMoreContacts(event: any): void {
        if (event && event.target && !this.isLoading) {
            if (Math.ceil(event.target.scrollTop) + event.target.offsetHeight >= event.target.scrollHeight) {
                let query: string = "";
                let searchText: string = "";
                this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: any) => {
                    query = params.query;
                    searchText = params.searchText;
                });
                if (!!query) {
                    this.contactRepository.loadMoreAdvanceSearchContacts(query);
                } else {
                    this.contactRepository.loadMoreContacts(searchText);
                }
                // this.contactRepository.loadMoreContacts();
            }
        }
    }

    emptySearchText(): void {
        this.searchText = "";
        this.changeDetectionRef.markForCheck();
    }

    openContactDetail(contact: Contact): void {
        if (this.totalSelectedContact > 0) {
            this.selectContactItem(contact);
        } else {
            this.matDialog.open(ContactDetailDialogComponent, {
                maxWidth: "100%",
                autoFocus: false,
                data: { contact: contact },
                panelClass: "contact_detail_dialog"
            });
        }
    }

    selectContactItem(contact: Contact): void {
        contact.isChecked = !contact.isChecked;
        this.markMultiple(contact, contact.isChecked);
    }

    markAll(allChecked) {
        this.checkedContact = [];
        this.contacts.forEach(contact => {
            contact.isChecked = allChecked;
            if (allChecked) {
                this.checkedContact.push(contact);
            }
            this.setMarkAllStatus(allChecked);
        });
        this.totalSelectedContact = this.checkedContact.length;
        this.changeDetectionRef.markForCheck();
    }

    setMarkAllStatus(isChecked: boolean) {
        this.allChecked = isChecked;
        this.changeDetectionRef.markForCheck();
    }

    markMultiple(contact, value) {
        if (this.checkedContact && this.checkedContact.includes(contact)) {
            if (!value) {
                this.checkedContact.splice(
                    this.checkedContact.indexOf(contact),
                    1
                );
            }
        } else {
            if (value) {
                this.checkedContact.push(contact);
            }
        }
        let checkedContactsLength = this.checkedContact.length;
        if (checkedContactsLength === this.contacts.length) {
            this.setMarkAllStatus(true);
        } else {
            this.setMarkAllStatus(false);
        }
        this.totalSelectedContact = this.checkedContact.length;
        this.changeDetectionRef.markForCheck();
    }

    contextMenuOpration(event: any): void {
        console.log("context Menu operation: ", event);
    }

    resetSelection(): void {
        this.totalSelectedContact = 0;
        this.allChecked = false;
        this.markAll(false);
        this.changeDetectionRef.markForCheck();
    }

    floatingOperation(operation: string, value: any): void {
        switch (operation) {
            case "delete":
                this.deleteContact();
                break;
            case "audioCall":
                this.audioCall();
                break;
            case "videoCall":
                this.videoCall();
                break;
            case "chat":
                this.chatConveration();
                break;
            case "email":
                this.sendEmail();
                break;
            case "star":
                this.starContacts();
                break;
            case "addList":
                this.addToList();
                break;
            case "addTags":
                this.addToTags();
                break;
            case "shareContact":
                this.shareContact();
                break;
            case "createTask":
                this.createTask();
                break;
            case "exportContact":
                this.contactRepository.commingSoonToastMessage();
                break;
            case "printContact":
                this.contactRepository.commingSoonToastMessage();
                break;
        }
    }

    private deleteContact(): void {
        const globalContacts = this.checkedContact.filter(c => c.is_global);
        if (!!globalContacts && globalContacts.length === this.checkedContact.length) {
            this.tostService.show("GLOBAL_SELECTED_CONTACT_ERROR");
            return;
        }
        const dlg = this.matDialog.open(ConfirmationDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "confirm_contactplus_dialog",
            data: { dialogType: ConfirmDialogType.DELETE_CONTACT, message_title: "DELETE", message_body: "CONFIRM_DELETE_CONTACT" }
        });
        dlg.afterClosed().pipe(take(1)).subscribe(res => {
            if (!!res && res.confirmation) {
                if (res.confirmation === "yes") {

                    this.contactRepository.deleteContact(this.checkedContact);

                }
            }
        });
    }

    audioCall(): void {
        this.contactRepository.makeTalkAudioChatVideoOperation(this.checkedContact, "audio-call", "group-audio");
    }

    videoCall(): void {
        this.contactRepository.makeTalkAudioChatVideoOperation(this.checkedContact, "video-call", "group-audio");
    }

    chatConveration(): void {
        this.contactRepository.makeTalkAudioChatVideoOperation(this.checkedContact, "start-chat", "group-audio");
    }

    sendEmail(): void {
        this.contactRepository.sendEmail(this.checkedContact);
    }

    starContacts(): void {
        this.contactRepository.markContactFavorite(true, this.checkedContact);
    }

    addToList(): void {
        const dialogRef = this.matDialog.open(AddToListsDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "add_to_lists_dialog"
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(res => {
            if (!!res && res.list) {
                const list = res.list;
                this.contactRepository.bulkUpdateContactList(this.checkedContact, list);
            }
        });
    }

    addToTags(): void {
        const dialogRef = this.matDialog.open(AddToTagDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "add_to_tags_dialog"
          });
        dialogRef.afterClosed().pipe(take(1)).subscribe(res => {
            if (!!res && res.list) {
                const list = res.list;
                this.contactRepository.bulkUpdateContactTags(this.checkedContact, list);
            }
        });
    }

    shareContact(): void {
        this.contactRepository.shareContactVcard(this.checkedContact);
    }

    createTask(): void {
        this.contactRepository.createTask(this.checkedContact);
    }

}
