
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, NgZone, ChangeDetectorRef, ElementRef, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ContactRootState, getContactFolders, getContactTags } from "src/app/contacts/store";
import { Store } from "@ngrx/store";
import { takeUntil, take } from "rxjs/operators";
import { ContactFolder } from "src/app/contacts/models/create-folder.model";
import { CreateFolderComponent } from "src/app/contacts/components/create-contact-folder/create-folder.component";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import * as _ from "lodash";
import { ContactTag } from "src/app/contacts/models/contact-tag.model";
import { CreateTagComponent } from "src/app/contacts/components/create-contact-tag-dialog/create-contact-tag.component";
import { getSaveSearch } from "src/app/reducers";
import { ContactRepository } from "src/app/contacts/repository/contact.repository";
import { SaveSearchContactDialogComponent } from "../save-search-contact-dialog/save-search-contact-dialog.component";
import { Router } from "@angular/router";

@Component({
    selector: "vp-save-search-dialog",
    templateUrl: "./save-search-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveSearchDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    searchText: string = "";
    saveSearch: any[] = [];
    isSearchView: boolean = false;
    @ViewChild("searchTextInput", { static: false }) searchTextInput: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<SaveSearchDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private changeDetectionRef: ChangeDetectorRef,
        private store: Store<ContactRootState>,
        private contactRepository: ContactRepository,
        private matDialog: MatDialog,
        private router: Router
    ) { }

    ngOnInit() {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_SAVE_SEARCH_DAILOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        this.store.select(getSaveSearch).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.saveSearch = res;
            console.log("[saveSearch] ", this.saveSearch);
        });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    emptySearchText(): void {
        this.searchText = "";
        this.changeDetectionRef.markForCheck();
    }

    close(): void {
        this.router.navigate(["/contactplus"]);
        this.broadcaster.broadcast(BroadcastKeys.SHOW_HAMBURGER_MENU);
        this.dialogRef.close();
    }

    openSearchView(value: boolean): void {
        if (value) {
            setTimeout(() => {
                this.searchTextInput.nativeElement.focus();
            }, 200);
        }
        if (!value) {
            this.emptySearchText();
        }
        this.isSearchView = value;
        this.changeDetectionRef.markForCheck();
    }

    searchFromSaveSearch(search: any): void {
        console.log("[searchFromSaveSearch]", search);
        this.contactRepository.searchFromSaveSearch(search);
        this.matDialog.open(SaveSearchContactDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "list-contact-dialog",
            data: {listItem: search }
        });
    }

}
