
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import * as fromListContacts from "../actions/list-contacts.action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../../../actions";
import { Contact } from "src/app/common/models";

export interface ContactListState extends EntityState<Contact> {
  isLoaded: boolean;
  isLoading: boolean;

  isNextPageLoading: boolean;
  isNextPageLoaded: boolean;
  currentOffset: number;
  isMoreContacts: boolean;

  selectedContactsIds: number[];
  isSearchMode: boolean;
  queryParams: any;
}

export const contactListAdapter: EntityAdapter<Contact> = createEntityAdapter<Contact>({
  selectId: (contact: Contact) => contact.id
});

export function sortByFirstCharacter(contact1: Contact, contact2: Contact): number {
  return contact1.fullName.charCodeAt(0) - contact2.fullName.charCodeAt(0);
}

export const initialState: ContactListState = contactListAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  isNextPageLoading: false,
  isNextPageLoaded: false,
  currentOffset: 0,
  isMoreContacts: false,

  selectedContactsIds: [],
  sortBy: null,
  isSearchMode: false,
  queryParams : null
});

export function listContactReducer(
  state = initialState,
  action: Action
): ContactListState {
  switch (action.type) {
    case fromListContacts.ListContactsAcTypes.LOAD_LIST_CONTACTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromListContacts.ListContactsAcTypes.LOAD_LIST_CONTACTS_SUCCESS: {
      return contactListAdapter.addAll(action.payload.contact, {
        ...state,
        isLoading: false,
        isLoaded: true,
        currentOffset: action.payload.currOffset,
        isMoreContacts: action.payload.isMoreContacts
      });
    }

    case fromListContacts.ListContactsAcTypes.LOAD_LIST_CONTACTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromListContacts.ListContactsAcTypes.NEXT_LOAD_LIST_CONTACTS: {
      return {
        ...state,
        isNextPageLoading: true,
        isLoading: true
      };
    }

    case fromListContacts.ListContactsAcTypes.NEXT_LOAD_LIST_CONTACTS_SUCCESS: {
      const newState = contactListAdapter.addMany(action.payload.contacts, {
        ...state,
        isNextPageLoading: false,
        isNextPageLoaded: true,
        isLoading: false,
        isLoaded: true,
        currentOffset: action.payload.currOffset,
        isMoreContacts: action.payload.isMoreContacts
      });

      const changes = action.payload.contacts.map(contact => {
        return {id: contact.id, changes: contact };
      });
      return contactListAdapter.updateMany(changes, newState);
    }

    case fromListContacts.ListContactsAcTypes.EMPTY_LIST_CONTACTS_SUCCESS: {
      const newState = contactListAdapter.removeAll( {
        ...state,
        isLoading: false,
        isLoaded: true,
        currentOffset: 0,
        isMoreContacts: false
      });
      return newState;
    }

    case fromListContacts.ListContactsAcTypes.NEXT_LOAD_LIST_CONTACTS_FAIL: {
      return {
        ...state,
        isNextPageLoading: false,
        isNextPageLoaded: false,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromListContacts.ListContactsAcTypes.UPDATE_LIST_CONTACT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromListContacts.ListContactsAcTypes.UPDATE_LIST_CONTACT_SUCCESS: {
      return contactListAdapter.updateOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromListContacts.ListContactsAcTypes.UPDATE_LIST_CONTACT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromListContacts.ListContactsAcTypes.CREATE_LIST_CONTACT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromListContacts.ListContactsAcTypes.CREATE_LIST_CONTACT_SUCCESS: {
      return contactListAdapter.addOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromListContacts.ListContactsAcTypes.CREATE_LIST_CONTACT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromListContacts.ListContactsAcTypes.REMOVE_LIST_CONTACT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromListContacts.ListContactsAcTypes.REMOVE_LIST_CONTACT_SUCCESS: {
      return contactListAdapter.removeOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromListContacts.ListContactsAcTypes.REMOVE_MANY_LIST_CONTACT_SUCCESS: {
      return contactListAdapter.removeMany(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromListContacts.ListContactsAcTypes.REMOVE_LIST_CONTACT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromListContacts.ListContactsAcTypes.RESTORE_LIST_CONTACT: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }

    case fromListContacts.ListContactsAcTypes.RESTORE_LIST_CONTACT_SUCCESS: {
      return contactListAdapter.addMany(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromListContacts.ListContactsAcTypes.RESTORE_LIST_CONTACT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromListContacts.ListContactsAcTypes.SEARCH_TOGGLE: {
      return  {
        ...state,
        isSearchMode: action.payload,
      };
    }

    case fromListContacts.ListContactsAcTypes.START_LIST_LOADING: {
      return {
        ...state,
        isLoading: true
      };
    }

    case fromListContacts.ListContactsAcTypes.STOP_LIST_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case fromListContacts.ListContactsAcTypes.STORE_LIST_SEARCHPARAMS: {
      return {
        ...state,
        queryParams: action.payload,
      };
    }

    case fromListContacts.ListContactsAcTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.contactState;
      return savedState ? { ...state, ...savedState } : state;
    }

    case fromListContacts.ListContactsAcTypes.UPDATE_MANY_LIST_CONTACT_SUCCESS: {
      return contactListAdapter.updateMany(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

  }
  return state;
}

export const _getIsSearchMode = (state: ContactListState) => state.isSearchMode;
export const _getSelectedListContactsIds = (state: ContactListState) => state.selectedContactsIds;
export const _getIsListContactsLoading = (state: ContactListState) => state.isLoading;
export const _getIsListContactsLoaded = (state: ContactListState) => state.isLoaded;
export const _getCurrentListOffset = (state: ContactListState) => state.currentOffset;
export const _getIsListNextPageLoading = (state: ContactListState) => state.isNextPageLoading;
export const _getIsListNextPageLoaded = (state: ContactListState) => state.isNextPageLoaded;
export const _getListSearchParams = (state: ContactListState) => state.queryParams;
export const _getIsListMoreContacts = (state: ContactListState) => state.isMoreContacts;
