<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <div class="contact-dialog">
    <div class="contact__dialog-header" *ngIf="!isSearchView">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{ contact.fullName }}
        </div>
        <div class="mobile-back-button">
            <button mat-button (click)="openSearchView(true)">
                <mat-icon class="disable-select">search</mat-icon>
            </button>
        </div>
        <div class="mobile-back-button">
            <button mat-button [matMenuTriggerFor]="headerMenuOperation">
                <mat-icon class="disable-select">more_vert</mat-icon>
            </button>
        </div>
    </div>
    <div class="contact__dialog-header search-header" *ngIf="isSearchView">
        <div class="mobile-back-button">
            <button mat-button (click)="openSearchView(false);emptySearchText();">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            <mat-form-field floatLabel="never">
                <input matInput #searchTextInput class="form-control" [(ngModel)]="searchText" name="label"
                    placeholder="{{ 'SEATCH_CONTACTS' | translate }}" autocomplete="nope" />
            </mat-form-field>
        </div>
        <div class="mobile-back-button">
            <button mat-button *ngIf="searchText.length > 0" (click)="emptySearchText()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="contact__dialog-body">
        <div class="content">
            <div *ngFor="let member of memberContact | vpSearchContact : searchText" class="member-items" (click)="openContact(member)">
                <div>
                    <vp-avatar [user]="{firstLastCharacters: member.fullName.charAt(0), color: member.bgAvatarColor, avatarURL: member.avatar}"> </vp-avatar>
                </div>
                <div class="fullname">
                    {{member.fullName}}
                </div>
            </div>
        </div>
    </div>
    <mat-menu #headerMenuOperation="matMenu" [class]="'contact-plus-operation-mat-menu'">
        <button mat-menu-item (click)="floatingOperation('email','')" [disabled]="!isShowSendEmailOption">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-email"></mat-icon>
            <span class="disable-select">{{'EMAIL_LBL' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('event','')" [disabled]="!isShowCalendarOption">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-calendar-plus"></mat-icon>
            <span class="disable-select">{{'CALENDAR_EVENT' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('audioCall','')" [disabled]="!isShowTalkOptions">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-phone"></mat-icon>
            <span class="disable-select">{{'VOICE_CALL' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('videoCall','')" [disabled]="!isShowTalkOptions">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-video"></mat-icon>
            <span class="disable-select">{{'VIDIO_CALL' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('chat','')" [disabled]="!isShowTalkOptions">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-message-text"></mat-icon>
            <span class="disable-select">{{'SEND_A_MSG_LBL' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('broadcast','')" [disabled]="!isShowTalkOptions">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-bullhorn"></mat-icon>
            <span class="disable-select">{{'BROADCAST' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('shareScreen','')" [disabled]="!isShowTalkOptions" *ngIf="!isOnMobile">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-monitor-share"></mat-icon>
            <span class="disable-select">{{'SHARE_SCREEN' | translate}}</span>
        </button>
    </mat-menu>
</div>
<div class="contact-add-menu-container">
    <button mat-fab (click)="openGroupContactDialog()">
        <mat-icon class="mdi-24px" fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
    </button>
</div>