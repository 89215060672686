
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, OnDestroy, ChangeDetectionStrategy, Component, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { Subject } from "rxjs";
import { takeUntil, take } from "rxjs/operators";
import { RootState, getSearchInApps, getSearchInLists, getSearchInTags } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { ContactFolder } from "src/app/contacts/models/create-folder.model";
import { SearchInAppsDialogComponent } from "../search-in-apps-dialog/search-in-apps-dialog.component";
import { SearchInListsDialogComponent } from "../search-in-lists-dialog/search-in-lists-dialog.component";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogType } from "src/app/common/models/dialog.model";
import { SetSearchInApp, SetSearchInLists, SetSearchInTags } from "src/app/actions/app";
import { ContactTag } from "src/app/contacts/models/contact-tag.model";
import { SearchInTagsDialogComponent } from "../search-in-tags-dialog/search-in-tags-dialog.component";

@Component({
    selector: "vp-advance-search-dialog",
    templateUrl: "./advance-search-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AdvanceSearchDialogComponent implements OnDestroy, OnInit {

    private isAlive$ = new Subject<boolean>();
    searchApps: any;
    searchLists: ContactFolder[] = [];
    searchListsItem: string = "";
    searchTags: ContactTag[] = [];
    searchTagsItem: string = "";

    constructor(
        private dialogRef: MatDialogRef<AdvanceSearchDialogComponent>,
        private broadcaster: Broadcaster,
        private store: Store<RootState>,
        private matDialog: MatDialog,
        private changeDetection: ChangeDetectorRef
    ) {

    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_ADVANCE_SEARCH_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(presence => {
            this.close();
        });
        this.store.select(getSearchInApps).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.searchApps = res;
        });
        this.store.select(getSearchInLists).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.searchLists = res;
            if (!!this.searchLists && this.searchLists.length > 0) {
                this.searchListsItem = this.searchLists.map(f => f.name).toString();
            } else {
                this.searchListsItem = "";
            }
            this.changeDetection.markForCheck();
        });
        this.store.select(getSearchInTags).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.searchTags = res;
            if (!!this.searchTags && this.searchTags.length > 0) {
                this.searchTagsItem = this.searchTags.map(f => f.name).toString();
            } else {
                this.searchTagsItem = "";
            }
            this.changeDetection.markForCheck();
        });

        this.broadcaster.on<any>("SEARCH_LIST_ITEMS").pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (!!res && res.data) {
                this.searchLists = res.data;
                if (!!this.searchLists && this.searchLists.length > 0) {
                    this.searchListsItem = this.searchLists.map(f => f.name).toString();
                } else {
                    this.searchListsItem = "";
                }
                this.changeDetection.markForCheck();
            }
        });

        this.broadcaster.on<any>("SEARCH_TAG_ITEMS").pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (!!res && res.data) {
                this.searchTags = res.data;
                if (!!this.searchTags && this.searchTags.length > 0) {
                    this.searchTagsItem = this.searchTags.map(f => f.name).toString();
                } else {
                    this.searchTagsItem = "";
                }
                this.changeDetection.markForCheck();
            }
        });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    openSearchInApps(): void {
        this.matDialog.open(SearchInAppsDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "search_in_apps_dialog"
        });
    }

    searchInLists(): void {
        this.matDialog.open(SearchInListsDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "search_in_lists_dialog"
        });
    }

    removeAdvanceSearchConfig(): void {
        let folders: ContactFolder[] = [];
        const dlg = this.matDialog.open(ConfirmationDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "confirm_contactplus_dialog",
            data: { dialogType: ConfirmDialogType.ADVANCE_SEARCH_REMOVE, message_title: "CONFIRM", message_body: "ADVANCE_SEARCH_REMOVE_MESSAGE" }
        });
        dlg.afterClosed().pipe(take(1)).subscribe(res => {
            if (!!res && res.confirmation) {
                if (res.confirmation === "yes") {
                    this.store.dispatch(new SetSearchInLists([]));
                    this.store.dispatch(new SetSearchInTags([]));
                }
            }
        });
    }

    searchInTags(): void {
        this.matDialog.open(SearchInTagsDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "search_in_tags_dialog"
        });
    }

}