
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { ContactFolderActionTypes } from "../actions/contact-folder.action";
import { ContactFolder } from "../../models/create-folder.model";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";


export interface ContactFolderState extends EntityState<ContactFolder> {
  selectedContactFolder: ContactFolder;
  isLoading: boolean;
  isLoaded: boolean;
}

export const contactFolderAdapter: EntityAdapter<ContactFolder> = createEntityAdapter<ContactFolder>({
  selectId: (contactFolder: ContactFolder) => contactFolder.id
});

export const initialState: ContactFolderState = contactFolderAdapter.getInitialState({
  selectedContactFolder: null,
  isLoading: false,
  isLoaded: false
});

export function contactFolderReducer(state = initialState, action: Action): ContactFolderState {
  switch (action.type) {
    case ContactFolderActionTypes.SELECT_CONTACT_FOLDER: {
      return {
        ...state,
       selectedContactFolder: action.payload
      };
    }

    case ContactFolderActionTypes.LOAD_FOLDERS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ContactFolderActionTypes.LOAD_FOLDERS_SUCCESS: {
      return contactFolderAdapter.addAll(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case ContactFolderActionTypes.LOAD_FOLDERS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case ContactFolderActionTypes.CREATE_CONTACT_FOLDER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ContactFolderActionTypes.CREATE_CONTACT_FOLDER_SUCCESS: {
      return contactFolderAdapter.addOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case ContactFolderActionTypes.CREATE_CONTACT_FOLDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case ContactFolderActionTypes.DELETE_CONTACT_FOLDER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ContactFolderActionTypes.DELETE_CONTACT_FOLDER_SUCCESS: {
      return contactFolderAdapter.removeOne(action.payload.id, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case ContactFolderActionTypes.DELETE_CONTACT_FOLDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case ContactFolderActionTypes.UPDATE_CONTACT_FOLDER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ContactFolderActionTypes.UPDATE_CONTACT_FOLDER_SUCCESS: {
      return contactFolderAdapter.updateOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case ContactFolderActionTypes.UPDATE_CONTACT_FOLDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case ContactFolderActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.ContactFolderState;
      return savedState ? { ...state, ...savedState } : state;
    }

    case ContactFolderActionTypes.RESET_CONTACT_FOLDER_STATE: {
      return contactFolderAdapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const _getSelectedContactFolder = (state: ContactFolderState) => state.selectedContactFolder;
export const _getIsLoading = (state: ContactFolderState) => state.isLoading;
export const _getIsLoaded = (state: ContactFolderState) => state.isLoaded;
