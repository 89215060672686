
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import * as fromContacts from "../actions/contacts.action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../../../actions";
import { Contact } from "src/app/common/models";

export interface ContactState extends EntityState<Contact> {
  isLoaded: boolean;
  isLoading: boolean;

  isNextPageLoading: boolean;
  isNextPageLoaded: boolean;
  currentOffset: number;
  isMoreContacts: boolean;

  selectedContactsIds: number[];
  isSearchMode: boolean;
  queryParams: any;
}

export const contactAdapter: EntityAdapter<Contact> = createEntityAdapter<Contact>({
  selectId: (contact: Contact) => contact.id
});

export function sortByFirstCharacter(contact1: Contact, contact2: Contact): number {
  return contact1.fullName.charCodeAt(0) - contact2.fullName.charCodeAt(0);
}

export const initialState: ContactState = contactAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  isNextPageLoading: false,
  isNextPageLoaded: false,
  currentOffset: 0,
  isMoreContacts: false,

  selectedContactsIds: [],
  sortBy: null,
  isSearchMode: false,
  queryParams : null
});

export function contactReducer(
  state = initialState,
  action: Action
): ContactState {
  switch (action.type) {
    case fromContacts.ContactsAcTypes.LOAD_CONTACTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromContacts.ContactsAcTypes.LOAD_CONTACTS_SUCCESS: {
      return contactAdapter.addAll(action.payload.contact, {
        ...state,
        isLoading: false,
        isLoaded: true,
        currentOffset: action.payload.currOffset,
        isMoreContacts: action.payload.isMoreContacts
      });
    }

    case fromContacts.ContactsAcTypes.LOAD_CONTACTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromContacts.ContactsAcTypes.NEXT_LOAD_CONTACTS: {
      return {
        ...state,
        isNextPageLoading: true,
        isLoading: true
      };
    }

    case fromContacts.ContactsAcTypes.NEXT_LOAD_CONTACTS_SUCCESS: {
      const newState = contactAdapter.addMany(action.payload.contacts, {
        ...state,
        isNextPageLoading: false,
        isNextPageLoaded: true,
        isLoading: false,
        isLoaded: true,
        currentOffset: action.payload.currOffset,
        isMoreContacts: action.payload.isMoreContacts
      });

      const changes = action.payload.contacts.map(contact => {
        return {id: contact.id, changes: contact };
      });
      return contactAdapter.updateMany(changes, newState);
    }

    case fromContacts.ContactsAcTypes.EMPTY_CONTACTS_SUCCESS: {
      const newState = contactAdapter.removeAll( {
        ...state,
        isLoading: false,
        isLoaded: true,
        currentOffset: 0,
        isMoreContacts: false
      });
      return newState;
    }

    case fromContacts.ContactsAcTypes.NEXT_LOAD_CONTACTS_FAIL: {
      return {
        ...state,
        isNextPageLoading: false,
        isNextPageLoaded: false,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromContacts.ContactsAcTypes.UPDATE_CONTACT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromContacts.ContactsAcTypes.UPDATE_CONTACT_SUCCESS: {
      return contactAdapter.updateOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromContacts.ContactsAcTypes.UPDATE_CONTACT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromContacts.ContactsAcTypes.CREATE_CONTACT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromContacts.ContactsAcTypes.CREATE_CONTACT_SUCCESS: {
      return contactAdapter.addOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromContacts.ContactsAcTypes.CREATE_CONTACT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromContacts.ContactsAcTypes.REMOVE_CONTACT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromContacts.ContactsAcTypes.REMOVE_CONTACT_SUCCESS: {
      return contactAdapter.removeOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromContacts.ContactsAcTypes.REMOVE_MANY_CONTACT_SUCCESS: {
      return contactAdapter.removeMany(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromContacts.ContactsAcTypes.REMOVE_CONTACT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromContacts.ContactsAcTypes.RESTORE_CONTACT: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }

    case fromContacts.ContactsAcTypes.RESTORE_CONTACT_SUCCESS: {
      return contactAdapter.addMany(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case fromContacts.ContactsAcTypes.RESTORE_CONTACT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromContacts.ContactsAcTypes.SEARCH_TOGGLE: {
      return  {
        ...state,
        isSearchMode: action.payload,
      };
    }

    case fromContacts.ContactsAcTypes.START_LOADING: {
      return {
        ...state,
        isLoading: true
      };
    }

    case fromContacts.ContactsAcTypes.STOP_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case fromContacts.ContactsAcTypes.STORE_SEARCHPARAMS: {
      return {
        ...state,
        queryParams: action.payload,
      };
    }

    case fromContacts.ContactsAcTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.contactState;
      return savedState ? { ...state, ...savedState } : state;
    }

    case fromContacts.ContactsAcTypes.UPDATE_MANY_CONTACT_SUCCESS: {
      return contactAdapter.updateMany(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

  }
  return state;
}

export const _getIsSearchMode = (state: ContactState) => state.isSearchMode;
export const _getSelectedContactsIds = (state: ContactState) => state.selectedContactsIds;
export const _getIsContactsLoading = (state: ContactState) => state.isLoading;
export const _getIsContactsLoaded = (state: ContactState) => state.isLoaded;
export const _getCurrentOffset = (state: ContactState) => state.currentOffset;
export const _getIsNextPageLoading = (state: ContactState) => state.isNextPageLoading;
export const _getIsNextPageLoaded = (state: ContactState) => state.isNextPageLoaded;
export const _getSearchParams = (state: ContactState) => state.queryParams;
export const _getIsMoreContacts = (state: ContactState) => state.isMoreContacts;
