
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  NgZone,
  OnInit
} from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { UserProfile } from "../../models";
import { Store } from "@ngrx/store";
import { takeUntil, take } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { BroadcastKeys } from "../../../common/enums/broadcast.enum";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ToastService } from "src/app/common/service/tost.service";
import { ConfigService } from "src/app/common/providers/config.service";
import { ContactImageCropperDialogComponent } from "../contact-image-cropper-dialog/contact-image-cropper-dialog.component";
import { RootState, getUserProfile, getLoggedInUserContact } from "src/app/reducers";
import { ContactRepository } from "src/app/contacts/repository/contact.repository";
import { LocalStorageService } from "src/app/common/providers/storage.service";
import { AppConstants } from "src/app/common/utils/app-constants";
import { SetUserProfile } from "src/app/actions/app";
import { ContactService } from "src/app/common/service/contact-service";
import { Contact } from "src/app/common/models";
import { CreateContactDialogComponent } from "src/app/contacts/components/create-contact-dialog/create-contact-dialog.component";
import { CommonUtil } from "src/app/common/utils/common.utils";

@Component({
  selector: "vp-avatar-dialog",
  templateUrl: "./avatar-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarDialogComponent implements OnInit, OnDestroy {

  currentUser: UserProfile;
  private isAlive$ = new Subject<boolean>();
  contact: Contact;
  backgroundImageStyle: any = {};
  selectedTab: any = 0;
  swipeCoord: any;
  swipeTime: number = 0;

  constructor(
    public dialogRef: MatDialogRef<AvatarDialogComponent>,
    private store: Store<RootState>,
    private matDialog: MatDialog,
    private broadcaster: Broadcaster,
    private tostService: ToastService,
    private ngZone: NgZone,
    private changeDetectionRef: ChangeDetectorRef,
    private configService: ConfigService,
    private contactRepository: ContactRepository,
    private localStorage: LocalStorageService,
    private contactService: ContactService
  ) {
    this.broadcaster.on<any>(BroadcastKeys.HIDE_PROFILE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });

    this.broadcaster.on<any>("UPDATE_USER_AVATAR").pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.contactRepository.poppulateAuthUserAvatar(this.currentUser, false).subscribe(success => {
        if (success) {
          this.localStorage.setItem(AppConstants.PROFILE_USER, JSON.stringify(this.currentUser));
          this.store.dispatch(new SetUserProfile(this.currentUser));
          this.broadcaster.broadcast(BroadcastKeys.RELOAD_USER_PROFILE);
          this.changeDetectionRef.markForCheck();
        }
      });
    });
  }

  ngOnInit(): void {
    this.store.select(getUserProfile).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (!!res && res !== null) {
        this.currentUser = res;
      }
      this.changeDetectionRef.markForCheck();
    });
    this.store.select(getLoggedInUserContact).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (!!res && res !== null) {
        this.contact = res;
        console.log("[AvatarDialogComponent] [getLoggedInUserContact] ", this.contact);
        if (this.contact.avatar) {
          this.backgroundImageStyle = {
            "background": "url(" + this.contact.avatar + ") center center / cover no-repeat",
            "filter": "blur(5px)",
            "-webkit-filter": "blur(5px)"
          };
          this.changeDetectionRef.markForCheck();
        }
      }
      this.changeDetectionRef.markForCheck();
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  openCropperDialog() {
    const dialogArgs = {
      autoFocus: false,
      maxWidth: "100%",
      data: { profileUserDetail: this.currentUser, contactUserAvtar: this.currentUser.userAvatar },
      panelClass: "contact-image-cropper-dialog"
    };
    this.matDialog.open(ContactImageCropperDialogComponent, dialogArgs);
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  editLoggedInUserDetail(): void {
    if (!!this.contact && this.contact !== null) {
      this.matDialog.open(CreateContactDialogComponent, {
        maxWidth: "100%",
        autoFocus: false,
        panelClass: "create_contactplus_dialog",
        disableClose: true,
        data: {isEdit: true, contact: this.contact, isLoggedInUser: true }
    });
    }
  }

  shareContact(): void {
    this.contactRepository.shareContactVcard([this.contact]);
  }

  swipe (e: TouchEvent, when: string): void {
    const coord: [number, number] = [
        e.changedTouches[0].clientX,
        e.changedTouches[0].clientY
    ];
    const time = new Date().getTime();
    if (when === "start") {
        this.swipeCoord = coord;
        this.swipeTime = time;
    } else if (when === "end") {
        const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
        const duration = time - this.swipeTime;
        if (duration < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
            const swipe = direction[0] < 0 ? "next" : "previous";
            if (swipe === "next") {
                const isFirst = this.selectedTab === 0;
                if (this.selectedTab <= 1) {
                    this.selectedTab = isFirst ? 1 : this.selectedTab + 1;
                }
            } else if (swipe === "previous") {
                const isLast = this.selectedTab === 2;
                if (this.selectedTab >= 1) {
                    this.selectedTab = this.selectedTab - 1;
                }
            }
        }
    }
    this.changeDetectionRef.markForCheck();
  }

  scrollingDialog(ev: any): void {
    if (CommonUtil.isOnMobileDevice()) {
      const mobileDetail = <HTMLElement> document.querySelector(".detail-contact");
        if (mobileDetail !== null) {
            if ((ev.target.scrollTop + 60) > mobileDetail.offsetTop) {
                const dialotBody = <HTMLElement> document.querySelector(".contact__dialog-body");
                if (dialotBody !== null && !mobileDetail.classList.contains("sticky-header")) {
                  setTimeout(() => {
                    dialotBody.scrollTop = 1;
                  }, 100);
                }
                mobileDetail.classList.add("sticky-header");
            }
        }
        if (ev.target.scrollTop === 0) {
          if (mobileDetail !== null) {
            mobileDetail.classList.remove("sticky-header");
          }
        }
    }
  }

}
