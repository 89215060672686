
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnChanges, OnInit } from "@angular/core";
import { ElectronService } from "src/app/common/service/electron.service";
import { ConfigService } from "src/app/common/providers/config.service";
import { environment } from "src/environments/environment";
import { CommonUtil } from "src/app/common/utils/common.utils";
import { DatabaseService } from "src/app/common/service/db/database.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { takeUntil, take } from "rxjs/operators";
import { Subject } from "rxjs";
import { ContactRepository } from "src/app/contacts/repository/contact.repository";
import * as CryptoJS from "crypto-js";

@Component({
  selector: "vp-avatar",
  templateUrl: "./user-avatar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent implements OnChanges, OnDestroy, OnInit {
  @Input() type;
  @Input() user;
  @Input() isGroup: boolean = false;
  @Input() isHeaderAvatar: boolean = false;
  avatarURL: string;
  photoLastUpdate: any;
  photoLastUpdateSubscription$: any;
  email: string;
  hideImage: boolean = true;
  loadedFromURLorDB: boolean = false;
  isCordovaApp: boolean;
  isCordovaOrElectron: boolean;
  configAvatarURL: string;
  configDomain: string;
  private isAlive$ = new Subject<boolean>();
  constructor(
    private electronService: ElectronService,
    private configService: ConfigService,
    private databaseService: DatabaseService,
    private contactRepo: ContactRepository,
    private broadcaster: Broadcaster,
    private changeDetectionRef: ChangeDetectorRef
  ) {
    this.isCordovaApp = environment.isCordova;
    this.isCordovaOrElectron = environment.isCordova || environment.isElectron;
    this.configAvatarURL = this.configService.get("avatarServiceUrl");
    this.configDomain = this.configService.get("domain");
    if (!this.configAvatarURL) {
      this.configAvatarURL = "https://avatar.vnc.biz";
    }
  }

  ngOnInit(): void {
    const mail = !!this.user.jid ? this.user.jid : (!!this.user.emails ? this.user.emails[0]?.email : "");
    const email = !!this.user.jid ? this.user.jid : mail;
    let avatarB64url;
    if (!!email) {
      avatarB64url = this.contactRepo.getCachedAvatar(email);
      // console.log("avataronInit: ", this.user, avatarB64url);
    }
    if (this.isHeaderAvatar) {
      this.buildAvatarURL();
      this.changeDetectionRef.markForCheck();
    } else {
      if (!!avatarB64url) {
        this.avatarURL = avatarB64url;
        this.loadedFromURLorDB = true;
      }
    }

    this.changeDetectionRef.markForCheck();

    this.broadcaster.on<any>("DBREADY").pipe(takeUntil(this.isAlive$)).subscribe(() => {
      if (!this.loadedFromURLorDB) {
        // console.log("userAvatar onDBready ", this.email);
        this.buildAvatarURL();
      }
    });
    this.broadcaster.on<any>("CONTACTAVATARUPDATED").pipe(takeUntil(this.isAlive$)).subscribe((res) => {
      // console.log("userAvatar CONTACTAVATARUPDATED ", this.email);
      if (res === this.email) {
        this.buildAvatarURL();
      }
    });
    this.broadcaster.on<any>("CACHED_AVATARS_UPDATED").pipe(takeUntil(this.isAlive$)).subscribe((res) => {
      // console.log("userAvatar CONTACTAVATARUPDATED ", email, res);
      if (!!res && (res.length > 0) && !!email && (res.indexOf(email) > -1)) {
        avatarB64url = this.contactRepo.getCachedAvatar(email);
        if (!!avatarB64url) {
          this.avatarURL = avatarB64url;
          this.loadedFromURLorDB = true;
          this.changeDetectionRef.markForCheck();
        }
      }
    });
  }

  onLoad() {
    this.hideImage = false;
    this.changeDetectionRef.markForCheck();
  }

  getColor() {
    let color = "#888888";
    if (this.user) {
      if (!this.avatarURL && this.user.color) {
        color = this.user.color;
      }
    }
    return color;
  }

  ngOnChanges(data) {

    if (this.isHeaderAvatar) {
      if (data.user.currentValue.avatarURL && data.user.currentValue.avatarURL !== "") {
        this.avatarURL = data.user.currentValue.avatarURL;
        this.changeDetectionRef.markForCheck();
      } else {
        this.imgLoadOnError("");
      }
    } else {
      this.user = data.user.currentValue;
      const mail = !!this.user.jid ? this.user.jid : (!!this.user.emails ? this.user.emails[0]?.email : "");
      const email = !!this.user.jid ? this.user.jid : mail;
      let avatarB64url;
      if (!!email) {
        avatarB64url = this.contactRepo.getCachedAvatar(email);
        // console.log("avataronInit: ", this.user, avatarB64url);
        if (!!avatarB64url) {
          this.avatarURL = avatarB64url;
          this.loadedFromURLorDB = true;
          this.changeDetectionRef.markForCheck();
        } else {
          this.avatarURL = "";
          this.imgLoadOnError("");
        }
      }

    }

  }

  ngOnDestroy() {
    if (this.photoLastUpdateSubscription$) {
      this.photoLastUpdateSubscription$.unsubscribe();
    }
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  imgLoadOnError(event) {
    this.avatarURL = null;
    this.changeDetectionRef.markForCheck();
  }

  private async buildAvatarURL() {
    const mail = !!this.user.jid ? this.user.jid : (!!this.user.emails ? this.user.emails[0]?.email : "");
    const email = !!this.user.jid ? this.user.jid : mail;
    // console.trace("userAvatar buildAvatarURL: ", email);
    this.email = email;
    let avatarId = "";
    if (environment.isElectron) {
      avatarId = this.electronService.md5(email);
    } else {
      avatarId = await CryptoJS.MD5(email);
    }
    this.databaseService.getAvatarByEmail(email).pipe(take(1)).subscribe(avt => {
      // console.log("buildAvatarURL0 for avt: ", avt);
      if (!!avt && !!avt.data) {
        this.avatarURL = avt.data;
        this.changeDetectionRef.markForCheck();
      } else {
        if (navigator.onLine) {
          if (this.user.avatarURL && this.user.avatarURL !== "") {
            // console.log("buildAvatarURL1 for user: ", this.user);
            CommonUtil.getBase64ImageFromUrl(this.user.avatarURL).subscribe((url: string) => {
              this.avatarURL = url;
              this.loadedFromURLorDB = true;
              this.databaseService.storeAvatar(url, this.user.avatarURL, email);
              this.changeDetectionRef.markForCheck();
            }, () => {
              if (this.configAvatarURL) {
                const avatarURL = `${this.configAvatarURL}/${avatarId}.jpg`;
                // console.log("buildAvatarURL2 for user: ", this.user);
                this.changeDetectionRef.markForCheck();
                CommonUtil.getBase64ImageFromUrl(avatarURL).subscribe((url: string) => {
                  this.avatarURL = url;
                  this.loadedFromURLorDB = true;
                  this.databaseService.storeAvatar(url, avatarURL, email);
                  this.changeDetectionRef.markForCheck();
                }, () => {

                  if (navigator.onLine) {
                    const gravatarURL = `https://www.gravatar.com/avatar/${avatarId}.jpg?d=404`;
                    CommonUtil.getBase64ImageFromUrl(gravatarURL).subscribe((url: string) => {
                      this.avatarURL = url;
                      this.loadedFromURLorDB = true;
                      this.databaseService.storeAvatar(url, gravatarURL, email);
                      this.changeDetectionRef.markForCheck();
                    }, () => {
                      if (this.user.avatarURL && this.user.avatarURL !== "") {
                        this.avatarURL = this.user.avatarURL;
                      } else {
                        this.avatarURL = null;
                      }
                      this.changeDetectionRef.markForCheck();
                    });
                  }

                });
              }
            });
          } else {

            if (this.configAvatarURL) {
              const avatarURL = `${this.configAvatarURL}/${avatarId}.jpg`;
              // console.log("buildAvatarURL for user: ", this.user);
              this.changeDetectionRef.markForCheck();
              // this.loadedFromURL = false;
              CommonUtil.getBase64ImageFromUrl(avatarURL).subscribe((url: string) => {
                this.avatarURL = url;
                this.loadedFromURLorDB = true;
                this.databaseService.storeAvatar(url, avatarURL, email);
                this.changeDetectionRef.markForCheck();
              }, () => {

                  if (navigator.onLine) {
                    const gravatarURL = `https://www.gravatar.com/avatar/${avatarId}.jpg?d=404`;
                    CommonUtil.getBase64ImageFromUrl(gravatarURL).subscribe((url: string) => {
                      this.avatarURL = url;
                      this.loadedFromURLorDB = true;
                      this.databaseService.storeAvatar(url, gravatarURL, email);
                      this.changeDetectionRef.markForCheck();
                    }, () => {
                      if (this.user.avatarURL && this.user.avatarURL !== "") {
                        this.avatarURL = this.user.avatarURL;
                      } else {
                        this.avatarURL = null;
                      }
                      this.changeDetectionRef.markForCheck();
                    });
                  }

              });
            }

          }

        }
      }
    });
  }


}
