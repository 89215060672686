
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { Update } from "@ngrx/entity/src/models";
import { AppActionTypes } from "../../../actions/app";
import { Contact } from "src/app/common/models";

export class FrequntlyContactsAcTypes extends AppActionTypes {
// load Contacts
static LOAD_FREQUENTLY_CONTACTS = "[Contacts] Load frequntly Contacts";
static LOAD_FREQUENTLY_CONTACTS_FAIL = "[Contacts] Load frequntly Contacts Fail";
static LOAD_FREQUENTLY_CONTACTS_SUCCESS = "[Contacts] Load frequntly Contacts Success";

// load nex Contacts
static NEXT_LOAD_FREQUENTLY_CONTACTS = "[Contacts] Next Load frequntly Contacts";
static NEXT_LOAD_FREQUENTLY_CONTACTS_FAIL = "[Contacts] Next Load frequntly Contacts Fail";
static NEXT_LOAD_FREQUENTLY_CONTACTS_SUCCESS = "[Contacts] Next Load frequntly Contacts Success";

// create Contact
static CREATE_FREQUENTLY_CONTACT = "[Contacts] Create frequntly Contact";
static CREATE_FREQUENTLY_CONTACT_FAIL = "[Contacts] Create frequntly Contact Fail";
static CREATE_FREQUENTLY_CONTACT_SUCCESS = "[Contacts] Create frequntly Contact Success";
// update Contact
static UPDATE_FREQUENTLY_CONTACT = "[Contacts] Update frequntly Contact";
static UPDATE_FREQUENTLY_CONTACT_FAIL = "[Contacts] Update frequntly Contact Fail";
static UPDATE_FREQUENTLY_CONTACT_SUCCESS = "[Contacts] Update frequntly Contact Success";
// remove Contact
static REMOVE_FREQUENTLY_CONTACT = "[Contacts] Remove frequntly Contact";
static REMOVE_FREQUENTLY_CONTACT_FAIL = "[Contacts] Remove frequntly Contact Fail";
static REMOVE_FREQUENTLY_CONTACT_SUCCESS = "[Contacts] Remove frequntly Contact Success";

static SEARCH_TOGGLE = "[Contacts] Toggle search mode";
static REMOVE_MANY_FREQUENTLY_CONTACT_SUCCESS = "[Contacts] Remove Many frequntly Contact Success";

// remove Contact
static RESTORE_FREQUENTLY_CONTACT = "[Contacts] Restore frequntly Contact";
static RESTORE_FREQUENTLY_CONTACT_FAIL = "[Contacts] Restore frequntly Contact Fail";
static RESTORE_FREQUENTLY_CONTACT_SUCCESS = "[Contacts] Restore frequntly Contact Success";

static START_FREQUENTLY_LOADING = "[Contacts] Start frequntly Loading";
static STOP_FREQUENTLY_LOADING = "[Contacts] Stop frequntly Loading";

static STORE_FREQUENTLY_SEARCHPARAMS = "[Contacts] Store frequntly search parameter";

static EMPTY_FREQUENTLY_CONTACTS_SUCCESS = "[Contacts] Empty frequntly Contacts";

static UPDATE_MANY_FREQUENTLY_CONTACT_SUCCESS = "[Contacts] Update Many frequntly Contact Success";

}

export class LoadFrequntlyContacts implements Action {
  readonly type = FrequntlyContactsAcTypes.LOAD_FREQUENTLY_CONTACTS;
}

export class SetSearchMode implements Action {
  readonly type = FrequntlyContactsAcTypes.SEARCH_TOGGLE;
  constructor(public payload: boolean) { }
}

export class StoreFrequntlySearchParams implements Action {
  readonly type = FrequntlyContactsAcTypes.STORE_FREQUENTLY_SEARCHPARAMS;
  constructor(public payload: any) { }
}

export class LoadFrequntlyContactsFail implements Action {
  readonly type = FrequntlyContactsAcTypes.LOAD_FREQUENTLY_CONTACTS_FAIL;
}

export class LoadFrequntlyContactsSuccess implements Action {
  readonly type = FrequntlyContactsAcTypes.LOAD_FREQUENTLY_CONTACTS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreContacts: boolean, contact: Contact[] }) { }
}

export class NextLoadFrequntlyContacts implements Action {
  readonly type = FrequntlyContactsAcTypes.NEXT_LOAD_FREQUENTLY_CONTACTS;
}

export class NextLoadFrequntlyContactsFail implements Action {
  readonly type = FrequntlyContactsAcTypes.NEXT_LOAD_FREQUENTLY_CONTACTS_FAIL;
}

export class NextLoadFrequntlyContactsSuccess implements Action {
  readonly type = FrequntlyContactsAcTypes.NEXT_LOAD_FREQUENTLY_CONTACTS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreContacts: boolean, contacts: Contact[] }) { }
}

export class CreateFrequntlyContact implements Action {
  readonly type = FrequntlyContactsAcTypes.CREATE_FREQUENTLY_CONTACT;
}

export class CreateFrequntlyContactFail implements Action {
  readonly type = FrequntlyContactsAcTypes.CREATE_FREQUENTLY_CONTACT_FAIL;
}

export class CreateFrequntlyContactSuccess implements Action {
  readonly type = FrequntlyContactsAcTypes.CREATE_FREQUENTLY_CONTACT_SUCCESS;
  constructor(public payload: Contact) { }
}

export class UpdateFrequntlyContact implements Action {
  readonly type = FrequntlyContactsAcTypes.UPDATE_FREQUENTLY_CONTACT;
}

export class UpdateFrequntlyContactFail implements Action {
  readonly type = FrequntlyContactsAcTypes.UPDATE_FREQUENTLY_CONTACT_FAIL;
}

export class UpdateFrequntlyContactSuccess implements Action {
  readonly type = FrequntlyContactsAcTypes.UPDATE_FREQUENTLY_CONTACT_SUCCESS;
  constructor(public payload: Update<Contact>) { }
}

export class RemoveFrequntlyContact implements Action {
  readonly type = FrequntlyContactsAcTypes.REMOVE_FREQUENTLY_CONTACT;
}

export class RemoveFrequntlyContactFail implements Action {
  readonly type = FrequntlyContactsAcTypes.REMOVE_FREQUENTLY_CONTACT_FAIL;
}

export class RemoveFrequntlyContactSuccess implements Action {
  readonly type = FrequntlyContactsAcTypes.REMOVE_FREQUENTLY_CONTACT_SUCCESS;
  constructor(public payload: string) { }
}

export class RemoveMultipleFrequntlyContactSuccess implements Action {
  readonly type = FrequntlyContactsAcTypes.REMOVE_MANY_FREQUENTLY_CONTACT_SUCCESS;
  constructor(public payload: string[]) { }
}

export class EmptyFrequntlyContactSuccess implements Action {
  readonly type = FrequntlyContactsAcTypes.EMPTY_FREQUENTLY_CONTACTS_SUCCESS;
  constructor() { }
}

export class RestoreFrequntlyContact implements Action {
  readonly type = FrequntlyContactsAcTypes.RESTORE_FREQUENTLY_CONTACT;
}

export class RestoreFrequntlyContactFail implements Action {
  readonly type = FrequntlyContactsAcTypes.RESTORE_FREQUENTLY_CONTACT_FAIL;
}

export class RestoreFrequntlyContactSuccess implements Action {
  readonly type = FrequntlyContactsAcTypes.RESTORE_FREQUENTLY_CONTACT_SUCCESS;
  constructor(public payload: Contact[]) { }
}

export class StartFrequntlyLoading implements Action {
  readonly type = FrequntlyContactsAcTypes.START_FREQUENTLY_LOADING;
}

export class StopFrequntlyLoading implements Action {
  readonly type = FrequntlyContactsAcTypes.STOP_FREQUENTLY_LOADING;
}

export class UpdateMultipleFrequntlyContactSuccess implements Action {
  readonly type = FrequntlyContactsAcTypes.UPDATE_MANY_FREQUENTLY_CONTACT_SUCCESS;
  constructor(public payload: Update<Contact>[]) { }
}


export type ContactsAction =
  | LoadFrequntlyContacts
  | LoadFrequntlyContactsFail
  | LoadFrequntlyContactsSuccess
  | NextLoadFrequntlyContacts
  | NextLoadFrequntlyContactsFail
  | NextLoadFrequntlyContactsSuccess
  | CreateFrequntlyContact
  | CreateFrequntlyContactFail
  | CreateFrequntlyContactSuccess
  | UpdateFrequntlyContact
  | UpdateFrequntlyContactFail
  | UpdateFrequntlyContactSuccess
  | RemoveFrequntlyContact
  | RemoveFrequntlyContactFail
  | RemoveFrequntlyContactSuccess
  | RemoveMultipleFrequntlyContactSuccess
  | SetSearchMode
  | RestoreFrequntlyContact
  | RestoreFrequntlyContactFail
  | RestoreFrequntlyContactSuccess
  | StartFrequntlyLoading
  | StopFrequntlyLoading
  | StoreFrequntlySearchParams
  | EmptyFrequntlyContactSuccess
  | UpdateMultipleFrequntlyContactSuccess;
