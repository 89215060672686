<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="contact-dialog">
    <div class="contact__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{ 'SEARCH_IN_LISTS' | translate }}
        </div>
        <div class="desktop-close-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="contact__dialog-body contact__dialog-body--pad">
        <div class="content">
            <mat-radio-group [(ngModel)]="selectType" (change)="radioButtonChange($event)">
                <mat-radio-button value="all-list">{{ 'ALL_LIST' | translate }}</mat-radio-button>
                <mat-radio-button value="lists">{{ 'LISTS_LBL' | translate }}</mat-radio-button>
            </mat-radio-group>
            <div class="all-list-item" *ngIf="selectType === 'lists'">
                <div *ngFor="let list of allLists">
                    <div class="list-item-group">
                        <div class="name">
                            {{list.name}}
                        </div>
                        <div class="check-mark-item">
                            <mat-checkbox [checked]="isSelected(list)" (change)="itemSelectionToggle(list, $event)"></mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="contact__dialog-footer" *ngIf="isChangeValue">
        <div class="desktop-footer">
            <button mat-button (click)="close()">
              {{ 'CANCEL' | translate }}
            </button>
            <button mat-button (click)="submit()">
              {{ 'SAVE' | translate }}
            </button>
        </div>
        <div class="mobile-footer">
            <div class="title">
                {{ 'SAVE' | translate }}
            </div>
            <div class="save_footer_btn">
                <button mat-button (click)="submit()">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>