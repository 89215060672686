
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { environment } from "../../../environments/environment";
import Autolinker from "autolinker";
import linkifyHtml from "linkify-html";
import { take } from "rxjs/operators";
import { Subject } from "rxjs";
import { colorConstants } from "./color-constants";
const EMAIL_REGEXP =
/^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
export class CommonUtil {
  // PRASHANT_COMMENT common helper methods here
  static imagesExtensions = ["jpeg", "jpg", "gif", "png", "bmp", "svg"];
  static audioExtensions = ["mp3", "wav"];

  static randomId(length: number = 7, containNumbers: boolean = true) {
    let randomId = "";
    let dictionary = "abcdefghijklmnopqrstuvwxyz";

    if (containNumbers) {
      dictionary += "0123456789";
    }

    const dictionaryLength = dictionary.length;

    for (let i = 0; i < length; i++) {
      randomId += dictionary.charAt(Math.floor(Math.random() * dictionaryLength));
    }

    return randomId;
  }

  static getRandomNumbers() {
    let array = new Uint32Array(10);
    window.crypto.getRandomValues(array);
    return array.join("");
  }

  static escapeHTMLString(unescapedString: string): string {
    return unescapedString
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  static escapeCarriagesAndNewline(unescapedString: string): string {
    return unescapedString.replace(/\r?\n/g, " ");
  }

  static linkify(inputText: string): string {
    let replacedText, replacePattern1, replacePattern2, replacePattern3;

    // URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, "<a href=\"$1\" target=\"_blank\" class=\"open-new-window\">$1</a>");

    // URLs starting with "www." (without // before it, or it"d re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, "$1<a href=\"http://$2\" target=\"_blank\" class=\"open-new-window\">$2</a>");

    // Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, "<a href=\"mailto:$1\">$1</a>");

    return replacedText;
  }

  static validateEmail(email) {
    // RFC822 version
    let sQtext = "[^\\x0d\\x22\\x5c\\x80-\\xff]";
    let sDtext = "[^\\x0d\\x5b-\\x5d\\x80-\\xff]";
    let sAtom = "[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+";
    let sQuotedPair = "\\x5c[\\x00-\\x7f]";
    let sDomainLiteral = "\\x5b(" + sDtext + "|" + sQuotedPair + ")*\\x5d";
    let sQuotedString = "\\x22(" + sQtext + "|" + sQuotedPair + ")*\\x22";
    let sDomain_ref = sAtom;
    let sSubDomain = "(" + sDomain_ref + "|" + sDomainLiteral + ")";
    let sWord = "(" + sAtom + "|" + sQuotedString + ")";
    let sDomain = sSubDomain + "(\\x2e" + sSubDomain + ")*";
    let sLocalPart = sWord + "(\\x2e" + sWord + ")*";
    let sAddrSpec = sLocalPart + "\\x40" + sDomain; // complete RFC822 email address spec
    let sValidEmail = "^" + sAddrSpec + "$"; // as whole string

    let reValidEmail = new RegExp(sValidEmail);

    return reValidEmail.test(email);
  }

  static logger(data: any) {
    console.log("Prashant Logger", data);
  }

  static isOnMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|PlayBook/i
      .test(navigator.userAgent);
  }

  static isOnIOS() {
    return typeof device !== "undefined" && device.platform.toUpperCase() === "IOS";
  }

  static isOnAndroid() {
    return typeof device !== "undefined" && device.platform.toUpperCase() === "ANDROID";
  }

  static isInsideIFrame() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static getBaseUrl() {
    const baseUrl = window.location.href;
    if (environment.isCordova) {
      return CommonUtil.isOnAndroid() ? "" : window.location.href.split("/contactplus")[0].replace(/index.html/gi, "");
    } else if (environment.isElectron) {
      return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/contactplus")[0];
    } else {
      return "";
    }
  }

  static getFullUrl(url: string) {
    return CommonUtil.getBaseUrl() + url;
  }

  static trimString(subject: string, length: number, suffix: string = "...") {
    if (subject.length <= length) {
      return subject;
    }

    const suffixLength = suffix.length;
    const trimLength = length - suffixLength;

    return subject.slice(0, trimLength - 1) + suffix;
  }

  static isOnCordova() {
    return typeof cordova !== "undefined";
  }

  static isValidAvtarSize(file) {
    if (file.size < 2000000) {
      return true;
    } else {
      return false;
    }
  }

  static isvalidCsvByExtension(file) {
    if (file.name.match(/.(csv|vcf)$/i)) {
      return true;
    }
    return false;
  }

  static isvalidFileByExtension(file): boolean {
    if (file.name.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
      return true;
    }
    return false;
  }

  static validateMail(mail): boolean {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }

  static isMobileView(): boolean {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
      return true;
    }
    return false;
  }

  static translateAppURLtoDeeplinkURL(url: string): string {
    let origURL = new URL(url);
    let deepLinkUrl = origURL.protocol + "//" + origURL.hostname + "/api/deeplink" + origURL.pathname + origURL.search;
    return deepLinkUrl;
  }

  static getShortNameForMobile(str: string, size: number): string {
    let shortName: string = "";
    if (this.isMobileView) {
      shortName = str.substr(0, size) + (str.length > 10 ? "...." : "");
    } else {
      shortName = str;
    }
    return shortName;
  }

  static getIcon(appName: string): string {
    let icon = "";
    let name = appName.replace("vnc", "VNC");
    switch (appName.toLowerCase()) {
      case "vncmail": icon = CommonUtil.getFullUrl("/assets/images/VNCmail.svg"); break;
      case "vnctask": icon = CommonUtil.getFullUrl("/assets/images/VNCtask.svg"); break;
      case "vncmcb": icon = CommonUtil.getFullUrl("/assets/images/VNCmcb.svg"); break;
      case "vnccontacts": icon = CommonUtil.getFullUrl("/assets/images/VNCcontacts.svg"); break;
      case "vnctalk": icon = CommonUtil.getFullUrl("/assets/images/VNCtalk.svg"); break;
      case "vnccalendar": icon = CommonUtil.getFullUrl("/assets/images/VNCcalendar.svg"); break;
      case "vncproject": icon = CommonUtil.getFullUrl("/assets/images/VNCproject.svg"); break;
      default: icon = CommonUtil.getFullUrl("/assets/images/" + name + ".svg"); break;
    }
    return icon;
  }

  static getAppStoreOrWeblink(appItem: any) {
    let appLink = appItem.path;
    if (CommonUtil.isOnAndroid()) {
      switch (appItem.name.toLowerCase()) {
        case "vncmail": appLink = "https://play.google.com/store/apps/details?id=biz.vnc.vncmail"; break;
        case "vnccalendar": appLink = "https://play.google.com/store/apps/details?id=biz.vnc.vncmail"; break;
        case "vnccontacts": appLink = "https://play.google.com/store/apps/details?id=biz.vnc.contactsplus"; break;
        case "vnctalk": appLink = "https://play.google.com/store/apps/details?id=biz.vnc.vnctalk"; break;
        case "vnctask": appLink = "https://play.google.com/store/apps/details?id=biz.vnc.vnctask"; break;
        default: appLink = appItem.path;
      }
    } else if (CommonUtil.isOnIOS()) {
      switch (appItem.name.toLowerCase()) {
        case "vncmail": appLink = "https://apps.apple.com/us/app/vncmail-email-communication/id1448862526"; break;
        case "vnccalendar": appLink = "https://apps.apple.com/us/app/vncmail-email-communication/id1448862526"; break;
        case "vnccontacts": appLink = "https://apps.apple.com/us/app/vnccontacts/id1536718018"; break;
        case "vnctalk": appLink = "https://apps.apple.com/us/app/vnctalk/id1400937435"; break;
        case "vnctask": appLink = "https://apps.apple.com/us/app/vnctask/id1423089930"; break;
        default: appLink = appItem.path;
      }
    }
    return appLink;
  }

  static getPackageName(appName: string): string {
    const packageName = appName === "vnccalendar" ? "biz.vnc.vncmail" : `biz.vnc.${appName.toLowerCase()}`;
    console.log("getPackageName", appName, packageName);
    return packageName;
  }

  static getAppUrl(appName: string): string {
    const availableiOSApps = {
      "vncmail": "",
      "vnctask": "itms-apps://itunes.apple.com/app/id1423089930",
      "vnccontacts": ""
    };
    const availableAndroidApps = {
      "vncmail": "",
      "vnctask": "market://details?id=biz.vnc.vnctask",
      "vnccontacts": ""
    };
    if (!CommonUtil.isOnAndroid()) {
      return availableiOSApps[appName.toLowerCase()];
    }
    return availableAndroidApps[appName.toLowerCase()];
  }

  static isOnNativeMobileDevice(){
    return CommonUtil.isOnIOS() || CommonUtil.isOnAndroid();
  }

  static requestPermissions(): void {
    if (!cordova.plugins.permissions) {
      return;
    }
    const permissions = cordova.plugins.permissions;
    const checkVideoPermissionCallback = function (status) {
      if (!status.hasPermission) {
        const errorCallback = function () {
          console.log("Camera permission is not turned on");
        };
        permissions.requestPermission(
          permissions.CAMERA,
          function (s) {
            if (!s.hasPermission) {
              errorCallback();
            }
          },
          errorCallback);
      }
    };
    const checkAudioPermissionCallback = function (status) {
      if (!status.hasPermission) {
        const errorCallback = function () {
          console.log("Audio permission is not turned on");
        };
        permissions.requestPermission(
          permissions.RECORD_AUDIO,
          function (s) {
            if (!s.hasPermission) {
              errorCallback();
            }
          },
          errorCallback);
      }
    };
    const checkWriteExternalStoragePermissionCallback = function (status) {
      if (!status.hasPermission) {
        const errorCallback = function () {
          console.log("Write external storage permission is not turned on");
        };
        permissions.requestPermission(
          permissions.WRITE_EXTERNAL_STORAGE,
          function (s) {
            if (!s.hasPermission) {
              errorCallback();
            }
          },
          errorCallback);
      }
    };
    permissions.checkPermission(permissions.CAMERA, checkVideoPermissionCallback, null);
    permissions.checkPermission(permissions.RECORD_AUDIO, checkAudioPermissionCallback, null);
    permissions.checkPermission(permissions.WRITE_EXTERNAL_STORAGE, checkWriteExternalStoragePermissionCallback, null);
  }

  static isOnIpad() {
    return /iPad/i
      .test(navigator.userAgent);
  }

  static getAvatarbgColors() {
    const values = colorConstants.map((item) => Object.values(item)[0]);
    return values;
  }

  static getRandomAvatarColor() {
    const colors = this.getAvatarbgColors();
    const random = Math.floor(Math.random() * colors.length);
    return colors[random];
  }

  static mapAppointmentFromMsg(appointmentResponse: any) {
    const appointMent: any = {};
    appointMent.id = appointmentResponse.id;
    appointMent.d = appointmentResponse.d;
    appointMent.f = appointmentResponse.f;
    appointMent.l = appointmentResponse.l;
    appointMent.md = appointmentResponse.md;
    appointMent.ms = appointmentResponse.ms;
    appointMent.rev = appointmentResponse.rev;
    appointMent.t = appointmentResponse.t;
    appointMent.s = appointmentResponse.s;
    appointMent.tn = appointmentResponse.tn;
    if (appointmentResponse.mp) {
      appointMent.mp = appointmentResponse.mp;
    }
    if (appointmentResponse.inv && appointmentResponse.inv[0].comp && Array.isArray(appointmentResponse.inv[0].comp)) {
      const component = appointmentResponse.inv[0].comp[0];
      appointMent.alarmData = component.alarm;
      appointMent.apptId = component.apptId;
      if (component.at) {
        appointMent.at = component.at;
      }
      appointMent.calItemId = component.calItemId;
      appointMent.ciFolder = component.ciFolder;
      appointMent.class = component.class;
      appointMent.compNum = component.compNum;
      appointMent.desc = "";
      appointMent.descHTML = "";
      if (component.desc) {
        appointMent.desc = component.desc[0]._content;
      }
      if (component.descHtml) {
        appointMent.descHTML = component.descHtml[0]._content;
      }
      if (component.draft) {
        appointMent.draft = component.draft;
      }
      appointMent.fb = component.fb;
      appointMent.fba = component.fba;
      appointMent.name = component.name;
      if (component.or) {
        appointMent.or = component.or;
      }
      if (component.s) {
        appointMent.startDateData = component.s;
      }
      if (component.e) {
        appointMent.endDateData = component.e;
      }
      appointMent.seq = component.seq;
      appointMent.status = component.status;
      appointMent.transp = component.transp;
      appointMent.uid = component.uid;
      appointMent.x_uid = component.x_uid;
      appointMent.url = component.url;
      appointMent.isOrg = component.isOrg;
      appointMent.allDay = false;
      if (component.allDay) {
        appointMent.allDay = component.allDay;
      }
      appointMent.loc = "";
      if (component.loc) {
        appointMent.loc = component.loc;
      }
      if (component.recur) {
        appointMent.recur = component.recur;
      }
      if (component.neverSent) {
        appointMent.neverSent = component.neverSent;
      }
    }
    if (appointmentResponse.inv && appointmentResponse.inv[0].replies) {
      const reply = appointmentResponse.inv[0].replies[0];
      appointMent.reply = reply.reply;
    }
    return appointMent;
  }

  static parseRedmineMentions(prefix: string, text: string): string[] {
    if (!text) {
      return [];
    }
    const mentionsRegex = new RegExp(prefix + "#([a-zA-Z0-9\_\.\-]+@[a-zA-Z0-9\_\.\-]+)", "gim");
    // LoggerService.info("[parseMentions]", mentionsRegex, text);
    let matches = text.match(mentionsRegex);
    if (matches && matches.length) {
        return matches.filter(v => CommonUtil.isValidEmail(v));
    } else {
        return [];
    }
  }

  static isValidEmail(email: string): boolean {
    return EMAIL_REGEXP.test(email);
  }
  static processHTMLBody(htmlBody) {
    if (!htmlBody) {
      return "";
    }
    return  htmlBody.replace("<body xmlns=\"http://www.w3.org/1999/xhtml\">", "")
    .replace("</body>", "")
    .replace(";lt;/body&amp;gt;", "")
    .replace(/&amp;nbsp;/ig, " ")
    .replace(/&lt;/ig, "<")
    .replace(/&gt;/ig, ">")
    .replace(/&quot;/ig, "\"")
    .replace(/&amp;lt;/ig, "&lt;")
    .replace(/&amp;gt;/ig, "&gt;")
    .replace(/&amp;amp;quote;/g, "&quote;")
    .replace(/&amp;amp;trade;/g, "&trade;")
    .replace(/&amp;amp;copy;/g, "&copy;")
    .replace(/&amp;amp;/ig, "&amp;");
  }
  static linkifyMe(inputText: string): string {
    if (!inputText) {
      return "";
    }
    const autolinker = new Autolinker({
      urls: {
        schemeMatches: true,
        tldMatches: true
      },
      email: false,
      phone: false,
      mention: false,
      hashtag: false,

      stripPrefix: false,
      stripTrailingSlash: false,
      newWindow: true,

      truncate: {
        length: 0,
        location: "end"
      },

      className: "open-new-window"
    });

    return autolinker.link(inputText);
  }

  static linkifyNext(inputText: string): string {
    if (!inputText) {
      return "";
    }

    let result = this.linkifyText2Html(inputText).replace(/<a href/ig, "<a class=\"open-new-window\" href");
    return result;
  }

  static linkifyHTML(inputText: string): string {
    if (!inputText) {
        return "";
    }
    return linkifyHtml(inputText, {
        defaultProtocol: "http",
        className: "open-new-window",
        target: {
            url: "_blank",
            email: null
        },
        formatHref: (href, type) => {
            if (type !== "email") {
                return href;
            }
            return "javascript:void(0)";
        },
        tagName: (href, type) => {
            if (type !== "email") {
                return "a";
            }
            return "span";
        }
    });
  }
  static CONFERENCE_LINK_PART = "/vncmeet/join/";
  static CONFERENCE_LINK_CLICK_EVENT = "vncmeet-join-link-click";
  static linkifyText2Html(inputText: string) {
    // LoggerService.info("[CommonUtil][linkifyText2Html]", inputText);
    if (!inputText) {
      return "";
    }
    const isConfJoinLink = this.isOnIOS() && inputText.includes(this.CONFERENCE_LINK_PART);
    // const isConfJoinLink = inputText.includes(ConstantsUtil.CONFERENCE_LINK_PART);
    let link = linkifyHtml(inputText, {
      defaultProtocol: "http",
      className: "open-new-window",
      target: {
        url: isConfJoinLink ? "_self" : "_blank",
        email: null
      },
      attributes: (href, type) => {
        if (type === "url") {
          const attrs = {rel: "noopener"};
          if (isConfJoinLink) {
            // https://stackoverflow.com/a/20548330/574475
            attrs["onclick"] = `const evv = new CustomEvent('${this.CONFERENCE_LINK_CLICK_EVENT}', { 'detail': '${href}' }); document.dispatchEvent(evv);`;
          }
          return attrs;
        }
        return {};
      },
      formatHref: (href, type) => {
        if (type !== "email" && !isConfJoinLink) {
          return href;
        }
        return "javascript:void(0)";
      },
      tagName: (href, type) => {
        if (type !== "email") {
          return "a";
        }
        return "span";
      }
    });

    // LoggerService.info("[linkify]", inputText, link)
    if (link.indexOf(`<span href="javascript:void(0)" class="open-new-window">`) !== -1) {
      link = link.replace(new RegExp(`<span href="javascript:void\\(0\\)" class="open-new-window">`, "igm"), "")
      .replace("</span>", "");
    }
    return link;
  }

  static processEmoji(messageBody) {
    if (!messageBody) {
      return "";
    }
    // if (wdtEmojiBundle && wdtEmojiBundle.emoji) {
    //   wdtEmojiBundle.emoji.replace_mode = "unified";
    //   wdtEmojiBundle.emoji.allow_native = true;
    // }
    return messageBody;
    // return wdtEmojiBundle.render(messageBody.replace(/<br \/>/g, " ____<br />____ ")).replace(new RegExp(" ____<br />____ ", "g"), "<br />");
  }

  static generateCachedBodyRedmine(messageBody, highlight?: string, skipEmoji?: boolean) {
    if (!messageBody) {
      // console.warn("[CommonUtil][generateCachedBody] empty message body, ignore1");
      return "";
    }
    const jwt = !!localStorage.getItem("jwtTemp") ? localStorage.getItem("jwtTemp") : "";
    messageBody = messageBody.replace(/\?jwt=REDMINE_JWT/g, "?jwt=" + jwt);
    messageBody = CommonUtil.processHTMLBody(messageBody);
    messageBody = CommonUtil.linkifyMe(messageBody).replace(/\r?\n/g, "\\n").replace(/\\n/g, " <br />");
    if (highlight) {
      messageBody = CommonUtil.highlightSearch(messageBody, highlight);
    }
    if (!skipEmoji) {
      messageBody = CommonUtil.processEmoji(messageBody.replace(/<\/p>/ig, " </p>").replace(/<p>:/ig, "<p> :"));
    }
    return messageBody.replace(/<a href=/g, "<a target=\"_blank\" class=\"open-new-window\" href=")
    .replace(/<a class="([a-z\s0-9]*)"\shref=/g, "<a target=\"_blank\" class=\"open-new-window\" href=");
  }

  static generateCachedBody(messageBody, highlight?: string, skipEmoji?: boolean) {
    if (!messageBody) {
      // console.warn("[CommonUtil][generateCachedBody] empty message body, ignore1");
      return "";
    }
    if (messageBody.indexOf("meta_task_mention") !== -1 || messageBody.indexOf("ticket_mention") !== -1 || messageBody.indexOf("?jwt=REDMINE_JWT") !== -1) {
      return CommonUtil.generateCachedBodyRedmine(messageBody, highlight);
    }

    // const t0 = performance.now();

    messageBody = CommonUtil.processHTMLBody(messageBody);
    messageBody = CommonUtil.linkifyNext(messageBody).replace(/\r?\n/g, "\\n").replace(/\\n/g, " <br />").replace(/user active/g, "open-new-window");
    if (highlight) {
      messageBody = CommonUtil.highlightSearch(messageBody, highlight);
    }
    if (!skipEmoji) {
      messageBody = CommonUtil.processEmoji(messageBody.replace(/<\/p>/ig, " </p>").replace(/<p>:/ig, "<p> :"));
    }

    // const t1 = performance.now();

    // save cached content
    return messageBody;
  }
  static decimalNcr = {
    ä: "&#228;" ,
    ö: "&#246;" ,
    ü: "&#252;" ,
    Ä: "&#196;" ,
    Ö: "&#214;" ,
    Ü: "&#220;" ,
    ß: "&#223;" ,
    ẞ: "&#7838;"
  };

  static convertToNcr(searchKeyword) {
      if (!searchKeyword) {
        return "";
      }
      let ncrKeyword = "";
      let singleChar;
      for (let i = 0; i < searchKeyword.length; i++) {
        singleChar = searchKeyword.charAt(i);
        if (this.decimalNcr[singleChar]) {
          ncrKeyword += this.decimalNcr[singleChar];
        }
        else {
          ncrKeyword += singleChar;
        }
      }
      return ncrKeyword;
    }

    static toUnicode(searchKeyword) {
      if (!searchKeyword) {
        return "";
      }
      let unicodeString = "";
      for (let i = 0; i < searchKeyword.length; i++) {
        let theUnicode = searchKeyword.charCodeAt(i).toString(16).toUpperCase();
        while (theUnicode.length < 4) {
          theUnicode = "0" + theUnicode;
        }
        theUnicode = "\\u" + theUnicode;
        unicodeString += theUnicode;
      }
      return unicodeString;
    }

  static highlightSearch(text , keyword) {
    if (!text) {
      return "";
    }
    if (!keyword) {
      return text;
    }
    let newText = text;
    try {
      text = text.replace(/&#34;/g , "\"");
      if (keyword.includes("ä" || "ö" || "ü" || "ß" || "Ä" || "Ö" || "Ü" || "ẞ")) {
        keyword = this.convertToNcr(keyword);
      }
      newText = text;
      const query = new RegExp(this.toUnicode(keyword) , "gim");
      const doc = document.createElement("div");
      doc.innerHTML = newText;
      for (let dom of Array.from(doc.childNodes)) {
        if (dom.nodeValue) {
            dom.nodeValue = dom.nodeValue.replace(/(<span>|<\/span>)/igm , "").replace(query , "START_HIGHLIGHT$&END_HIGHLIGHT");
        } else if (dom.textContent) {
          dom.textContent = dom.textContent.replace(/(<span>|<\/span>)/igm , "").replace(query , "START_HIGHLIGHT$&END_HIGHLIGHT");
        }
      }
      // LoggerService.info("[highlightSearch]", doc.childNodes);
      newText = doc.innerHTML.replace(/START_HIGHLIGHT/g, "<span class=\"highlight\">").replace(/END_HIGHLIGHT/g, "</span>");
    } catch (error) {
      console.log("[highlightSearch] error", error);
    }
    return newText;
  }

  static parseMentions(text: string): string[] {
    if (!text) {
      return [];
    }
    const mentionsRegex = new RegExp("@([a-zA-Z0-9\_\.\-]+@[a-zA-Z0-9\_\.\-]+)", "gim");
    let matches = text.match(mentionsRegex);
    if (matches && matches.length) {
       let matchess = matches.map((match) => {
            return match.slice(1);
        });
        return CommonUtil.uniq(matches.filter(v => CommonUtil.isValidEmail(v)));
    } else {
        return [];
    }
  }

  static uniq(array: any[]) {
    return Array.from(new Set(array));
  }
  static getBase64ImageFromUrl(url) {
    const subject = new Subject();
    const image: HTMLImageElement = new Image();
    image.src = url;
    image.crossOrigin = "Anonymous";
    image.onload = () => {
      const canvas: HTMLCanvasElement = document.createElement("canvas");
      const context: CanvasRenderingContext2D = canvas.getContext("2d");
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
      const b64url = canvas.toDataURL();
      subject.next(b64url);
    };
    image.onerror = (error) => {
      subject.error(error);
    };
    return subject.asObservable().pipe(take(1));
  }

}
