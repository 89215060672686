
<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-legal-notice-dialog contact-dialog">
  <div class="contact__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="close()">
        <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{ 'LEGAL_NOTICE' | translate }}
    </div>
  </div>
  <!-- <div class="contact__dialog-header" layout="row center-center">
        <div>
            <button id="mobile_sidebar_about_legal_backbtn" (click)="close()" class="brand-color mat-button show-in-mobile">
                <mat-icon class="disable-select">chevron_left</mat-icon>
            </button>
            <button class="brand-color mat-button hide-in-mobile">
              <svg viewBox="0 0 24 24" style="height :24px;width:24px">
                <path fill="#ffffff" d="M12,3C10.73,3 9.6,3.8 9.18,5H3V7H4.95L2,14C1.53,16 3,17 5.5,17C8,17 9.56,16 9,14L6.05,7H9.17C9.5,7.85 10.15,8.5 11,8.83V20H2V22H22V20H13V8.82C13.85,8.5 14.5,7.85 14.82,7H17.95L15,14C14.53,16 16,17 18.5,17C21,17 22.56,16 22,14L19.05,7H21V5H14.83C14.4,3.8 13.27,3 12,3M12,5A1,1 0 0,1 13,6A1,1 0 0,1 12,7A1,1 0 0,1 11,6A1,1 0 0,1 12,5M5.5,10.25L7,14H4L5.5,10.25M18.5,10.25L20,14H17L18.5,10.25Z" />
              </svg>
          </button>
        </div>
        <div id="mobile_sidebar_about_legal_header" class="header-title ">
            <span class="disable-select">{{ 'LEGAL_NOTICE' | translate }}</span>
        </div>
        <div class= "desktop-close hide-in-mobile">
          <button class="brand-color mat-button" (click) = "close()">
              <mat-icon class="disable-select">close</mat-icon>
          </button>
      </div>
    </div> -->
  <div class="contact__dialog-body">
    <mat-list>
      <mat-list-item id="mobile_sidebar_about_legal_terms">
        <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-clipboard-text"></mat-icon>
        <a target="_blank" class="open-new-window disable-select non-decoration"
          href="{{termsOfUse}}">{{ 'TERMS_OF_USE' | translate }}</a>
      </mat-list-item>
      <mat-list-item id="mobile_sidebar_about_legal_data_privacy">
        <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-lock"></mat-icon>
        <a target="_blank" class="open-new-window disable-select non-decoration" href="{{dataPrivacy}}">
          {{ 'DATA_PRIVACY' | translate }}
        </a>
      </mat-list-item>
    </mat-list>
  </div>
</div>