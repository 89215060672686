<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="contact-dialog">
    <div class="contact__dialog-header" #addContactHeader>
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{ ((!isEdit)? 'CREATE_NEW_CONTACT' : 'EDIT_CONTACT') | translate }}
        </div>
        <span></span>
        <div class="desktop-close-button">
            <button mat-button (click)="close()" matTooltip="{{ 'CANCEL' | translate }}">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
        <div class="mobile-back-button">
            <button mat-button class="mobile-submit-button" (click)="submit()" [disabled]="isSubmit">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-check"></mat-icon>
            </button>
        </div>
    </div>
    <div class="modal-body-contact">
        <div class="form-section">
            <form id="contactForm" #contactForm="ngForm">
                <div class="contact__dialog-body contact__dialog-body--pad vnc-scrollbar-verical-show-on-hover"
                    [class.is-show-submit]="isFormFieldsValueChange" [style.max-height.px]="getHeight()">
                    <div class="avatar-section">
                        <div class="avatar-block">
                            <!-- <div class="contact-profile-avatar desktop-hide" *ngIf="isMobileView">
                                <div class="user-avatar">
                                    <vp-avatar
                                        [user]="{firstLastCharacters: !!contactDetails.first_name && contactDetails.first_name !== '' ? contactDetails.first_name.charAt(0) : '', color: contactDetails.bgAvatarColor ? contactDetails.bgAvatarColor : '#dddddd', avatarURL: avatarBase64}">
                                    </vp-avatar>
                                </div>
                                <div class="avatar-upload">
                                    <button id="profile-photo" *ngIf="isOnline" class="brand-color mat-button"
                                        (click)="openCropperDialog()">
                                        <mat-icon class="disable-select">photo_camera</mat-icon>
                                    </button>
                                </div>
                            </div> -->
                            <!-- mobile-hide -->
                            <div class="user-avatar" (click)="openCropperDialog()">
                                <vp-avatar
                                    [user]="{firstLastCharacters: '', color: '#ededed', avatarURL: avatarBase64}">
                                </vp-avatar>
                                <div class="edit" matTooltip="{{ 'ADD_PHOTO' | translate }}">
                                    <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
                                </div>
                                <div class="favourite"
                                    (click)="$event.stopPropagation();contactDetails.favorite = !contactDetails.favorite" matTooltip="{{ 'FAVORITE' | translate }}">
                                    <mat-icon class="mdi-18px favorite-mark" fontSet="mdi" fontIcon="mdi-star"
                                        *ngIf="contactDetails.favorite"></mat-icon>
                                    <ng-container [ngTemplateOutlet]="emptyStarIcon"
                                        *ngIf="!contactDetails.favorite"></ng-container>
                                </div>
                            </div>
                            <!-- <div class="field_icon desktop-hide">
                                <mat-icon class="material-icons">person</mat-icon>
                            </div> -->
                        </div>
                        <div class="contact-info-block">
                            <!-- <vnc-input [menuWidth]="240" [showClear]="true" [form]="formControl" type="search"
                                placeholder="{{ 'Search...' }}"></vnc-input> -->
                            <h2 class="name">
                                {{contactDetails.first_name?contactDetails.first_name:''}}
                                {{contactDetails.middle_name}} {{contactDetails.last_name}}
                            </h2>
                            <div class="contact-info">
                                {{contactDetails.company}} <div class="dot"
                                    *ngIf="contactDetails.company && contactDetails.job_title"></div>
                                {{contactDetails.job_title}}
                            </div>
                        </div>

                    </div>
                    <div class="content">

                        <div class="form-group company-field">
                            <div class="fields">
                                <mat-checkbox [(ngModel)]="isCompanyContact" name="isCompany" [disabled]="isEdit">
                                    {{ 'COMPANY_CONTACT' | translate }}</mat-checkbox>
                            </div>
                        </div>
                        <div class="separator-div desktop-hide"></div>
                        <div class="contcat-dual-fields form-group">

                            <div class="fields">
                                <div class="field_item mobile-width">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'FIRST_NAME' | translate}} *</mat-label>
                                        <input matInput class="form-control"
                                            placeholder="{{'FIRST_NAME' | translate}} *"
                                            [(ngModel)]="contactDetails.first_name" #firstName="ngModel"
                                            name="firstName" autocomplete="nope"
                                            [disabled]="isGlobalContact && !isAdmin" />
                                    </mat-form-field>
                                </div>
                                <div class="field_item">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'LAST_NAME' | translate}}</mat-label>
                                        <input matInput class="form-control" [(ngModel)]="contactDetails.last_name"
                                            name="lastName" placeholder="{{'LAST_NAME' | translate}}" #lastnameFiled
                                            autocomplete="nope" [disabled]="isGlobalContact && !isAdmin" />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <!-- <div class="field_icon">
                                <mat-icon class="material-icons middle-name-icon">person</mat-icon>
                            </div> -->
                            <div class="fields">
                                <div class="field_item middlename">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'MIDDLE_NAME' | translate}}</mat-label>
                                        <input matInput class="form-control" placeholder="{{'MIDDLE_NAME' | translate}}"
                                            [(ngModel)]="contactDetails.middle_name" name="middleName"
                                            autocomplete="nope" [disabled]="isGlobalContact && !isAdmin" />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="separator-div desktop-hide"></div>
                        <div class="form-group">
                            <div class="fields">
                                <div class="field_item">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'JOB_TITLE' | translate}}</mat-label>
                                        <input matInput class="form-control" placeholder="{{'JOB_TITLE' | translate}}"
                                            [(ngModel)]="contactDetails.job_title" name="jobTitle" autocomplete="nope"
                                            [disabled]="isGlobalContact && !isAdmin" />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="contcat-dual-fields form-group">
                            <div class="fields">
                                <div class="field_item">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'COMPANY' | translate}}</mat-label>
                                        <input matInput class="form-control" placeholder="{{'COMPANY' | translate}}"
                                            [(ngModel)]="contactDetails.company" name="company" autocomplete="nope"
                                            [disabled]="isGlobalContact && !isAdmin" />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="separator-div desktop-hide"></div>
                        <div class="form-group  row-flex"
                            *ngFor="let item of contactDetails.emails_attributes; let i= index;last as last">
                            <div class="form-group contact-mobile-fields">
                                <!-- <div class="field_icon">
                                    <mat-icon *ngIf="i===0">mail</mat-icon>
                                </div> -->
                                <div class="fields">
                                    <div class="field_item" id="email{{i}}">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{'EMAIL' | translate}}</mat-label>
                                            <input matInput class="" [(ngModel)]="item.email" #email="ngModel"
                                                name="email{{i}}" placeholder="{{'EMAIL' | translate}}"
                                                id="emailId{{i}}"
                                                pattern="^[!#$%&'*+\-/=?^_`{|}~.0-9A-Za-z]+(\.[!#$%&'*+\-/=?^_`{|}~.0-9A-Za-z]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$"
                                                autocomplete="nope" [disabled]="isGlobalContact && !isAdmin" (input)="onEmailChange($event,i)"/>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="right-space-div add-remove-button">
                                    <button mat-button (click)="removeEmail(i)"
                                    [disabled]="isGlobalContact && !isAdmin" matTooltip="{{ 'DELETE' | translate }}">
                                    <ng-container [ngTemplateOutlet]="deleteIcon"></ng-container>
                                </button>

                                </div>
                            </div>
                            <p class="duplicate-warning" *ngIf="duplicateEmails[i]">{{ 'CONTACT_ALREADY_EXISTS' | translate}}</p>
                            <button mat-button (click)="addEmail(i)" [disabled]="isGlobalContact && !isAdmin"
                                *ngIf="last" matTooltip="{{ 'ADD' | translate }}">
                                <mat-icon>add_circle_outline</mat-icon>
                                Add
                            </button>
                        </div>
                        <div class="separator-div desktop-hide"></div>
                        <div class="form-group  row-flex"
                            *ngFor="let phone of contactDetails.phones_attributes; let i= index;last as last">
                            <div class="form-group contcat-dual-fields contact-mobile-fields">
                                <div class="fields">
                                    <div class="field_item phone phone-flag">
                                        <mat-form-field appearance="outline" class="phone-number-form-field">
                                            <mat-label>{{'PHONE' | translate}}</mat-label>
                                            <input matInput class="form-control" [(ngModel)]="phone.phone"
                                                name="phone{{i}}" placeholder="{{'PHONE' | translate}}"
                                                autocomplete="nope" [disabled]="isGlobalContact && !isAdmin" (input)="onContactNumberChange($event,i)"/>
                                        </mat-form-field>
                                    </div>
                                    <div class="field_item phone small_field">
                                        <mat-form-field appearance="outline" class="mobile-width-40">
                                            <mat-label>{{'TYPE' | translate}}</mat-label>
                                            <mat-select class="margin-right-5" [(ngModel)]="phone.phone_type"
                                                name="phoneType{{i}}" [disabled]="isGlobalContact && !isAdmin">
                                                <mat-option value="home">{{ 'PHONE_TYPE.HOME' | translate
                                                    }}</mat-option>
                                                <mat-option value="work">{{ 'PHONE_TYPE.WORK' | translate
                                                    }}</mat-option>
                                                <mat-option value="mobile">{{ 'PHONE_TYPE.MOBILE' | translate
                                                    }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="right-space-div add-remove-button">
                                    <button mat-button (click)="removePhone(i)"
                                        [disabled]="isGlobalContact && !isAdmin" matTooltip="{{ 'DELETE' | translate }}">
                                        <ng-container [ngTemplateOutlet]="deleteIcon"></ng-container>
                                    </button>
                                </div>
                            </div>
                            <p class="duplicate-warning" *ngIf="duplicatePhone[i]">{{ 'CONTACT_ALREADY_EXISTS' | translate}}</p>
                            <button mat-button (click)="addPhone(i)" [disabled]="isGlobalContact && !isAdmin"
                                *ngIf="last" matTooltip="{{ 'ADD' | translate }}">
                                <mat-icon>add_circle_outline</mat-icon>
                                Add
                            </button>
                        </div>
                        <div class="separator-div desktop-hide"></div>
                        <div>
                            <div class="form-group row-flex"
                                *ngFor="let im of contactDetails.im_accounts_attributes; let i= index;last as last">
                                <div class="form-group contcat-dual-fields contact-mobile-fields">
                                    <div class="fields">
                                        <div class="field_item">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{'IM' | translate}}</mat-label>
                                                <input matInput class="form-control" [(ngModel)]="im.im_id"
                                                    name="im{{i}}" placeholder="{{'IM' | translate}}"
                                                    autocomplete="nope" [disabled]="isGlobalContact && !isAdmin" />
                                            </mat-form-field>
                                        </div>
                                        <div class="field_item small_field">
                                            <mat-form-field appearance="outline" class="mobile-width-40">
                                                <mat-label>{{'TYPE' | translate}}</mat-label>
                                                <mat-select class="margin-right-5" [(ngModel)]="im.im_type"
                                                    name="imType{{i}}" [disabled]="isGlobalContact && !isAdmin">
                                                    <mat-option value="XMPP">{{ 'IM_TYPE.XMPP' | translate
                                                        }}</mat-option>
                                                    <mat-option value="mastodon">{{ 'IM_TYPE.MASTODON' | translate
                                                        }}</mat-option>
                                                    <mat-option value="twitter">{{ 'IM_TYPE.TWITTER' | translate }}</mat-option>
                                                    <mat-option value="Other">{{ 'IM_TYPE.OTHER' | translate
                                                        }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="right-space-div add-remove-button">
                                        <button mat-button (click)="removeIm(i)"
                                            [disabled]="isGlobalContact && !isAdmin" matTooltip="{{ 'DELETE' | translate }}">
                                            <ng-container [ngTemplateOutlet]="deleteIcon"></ng-container>
                                        </button>
                                    </div>
                                </div>
                                <button mat-button (click)="addIm(i)" [disabled]="isGlobalContact && !isAdmin"
                                    *ngIf="last" matTooltip="{{ 'ADD' | translate }}">
                                    <mat-icon>add_circle_outline</mat-icon>
                                    Add
                                </button>
                            </div>
                            <div class="separator-div desktop-hide"></div>
                            <div class="form-group-address"
                                *ngFor="let address of contactDetails.addresses_attributes; let i= index;last as last">
                                <div class="form-group">
                                    <div class="fields">
                                        <div class="field_item">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{'STREET_AND_NUMBER' | translate}}</mat-label>
                                                <input matInput class="form-control" [(ngModel)]="address.street1"
                                                    name="addressstrt{{address.street1}}{{i}}"
                                                    placeholder="{{'STREET_AND_NUMBER' | translate}}"
                                                    autocomplete="nope" [disabled]="isGlobalContact && !isAdmin" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="contcat-dual-fields form-group">
                                    <!-- <div class="field_icon">
                                        <mat-icon></mat-icon>
                                    </div> -->
                                    <div class="fields">
                                        <div class="field_item contact_city">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{'ZIP_LBL' | translate}}</mat-label>
                                                <input matInput class="form-control" [(ngModel)]="address.postcode"
                                                    name="addresspostal{{address.postcode}}{{i}}"
                                                    placeholder="{{'ZIP_LBL'|translate}}" autocomplete="nope"
                                                    [disabled]="isGlobalContact && !isAdmin" />
                                            </mat-form-field>

                                        </div>
                                        <div class="field_item">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{'CITY' | translate}}</mat-label>
                                                <input matInput class="form-control" [(ngModel)]="address.city"
                                                    name="addresscity{{address.city}}{{i}}"
                                                    placeholder="{{'CITY' | translate}}" autocomplete="nope"
                                                    [disabled]="isGlobalContact && !isAdmin" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="fields">
                                        <div class="field_item state_field">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{'STATE' | translate}}</mat-label>
                                                <input matInput class="form-control" [(ngModel)]="address.state"
                                                    name="addressstate{{address.state}}{{i}}"
                                                    placeholder="{{'STATE' | translate}}" autocomplete="nope"
                                                    [disabled]="isGlobalContact && !isAdmin" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="contcat-dual-fields form-group">
                                    <!-- <div class="field_icon">
                                        <mat-icon></mat-icon>
                                    </div> -->
                                    <div class="fields">
                                        <div class="field_item country-code" [matMenuTriggerFor]="contactCountryMenu"
                                            [matMenuTriggerData]="{countryData: {type: 'address', index: i }}">
                                            <!-- <mat-form-field appearance="outline">
                                                    <input matInput class="form-control" [(ngModel)]="address.country_code"
                                                        name="addresscountry{{address.country_code}}{{i}}"
                                                        placeholder="{{'COUNTRY_CODE' | translate}}" autocomplete="nope" [disabled]="isGlobalContact && !isAdmin" />
                                                </mat-form-field> -->
                                            <div class="flag-address-item-div flag-form-field">
                                                <span
                                                    class="country-item-name">{{getCountryNameByCode(address.country_code) ? getCountryNameByCode(address.country_code) : 'COUNTRY' | translate}}</span>
                                                <span class="icon">
                                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="field_item small_field address-type">
                                            <mat-form-field appearance="outline">
                                                <mat-select #addressSelect class="full-width"
                                                    [(ngModel)]="address.address_type" name="addressType{{i}}"
                                                    [disabled]="isGlobalContact && !isAdmin">
                                                    <mat-option value="home"> {{ 'ADDRESS_TYPE.HOME' | translate
                                                        }}</mat-option>
                                                    <mat-option value="work"> {{ 'ADDRESS_TYPE.WORK' | translate
                                                        }}</mat-option>
                                                    <mat-option value="other"> {{ 'ADDRESS_TYPE.OTHER' | translate
                                                        }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="right-space-div add-remove-button">
                                        <button mat-button (click)="removeAddress(i)"
                                            [disabled]="isGlobalContact && !isAdmin" matTooltip="{{ 'DELETE' | translate }}">
                                            <ng-container [ngTemplateOutlet]="deleteIcon"></ng-container>
                                        </button>
                                        <!-- <button mat-button (click)="addAddress(i)" [disabled]="isGlobalContact && !isAdmin">
                                            <mat-icon>add_circle_outline</mat-icon>
                                        </button> -->
                                    </div>
                                </div>
                                <button mat-button (click)="addAddress(i)" [disabled]="isGlobalContact && !isAdmin"
                                    *ngIf="last" matTooltip="{{ 'ADD' | translate }}">
                                    <mat-icon>add_circle_outline</mat-icon>
                                    Add
                                </button>
                            </div>
                            <div class="separator-div desktop-hide"></div>
                            <div class="form-group row-flex"
                                *ngFor="let url of contactDetails.urls_attributes; let i= index; last as last">
                                <div class="form-group contcat-dual-fields contact-mobile-fields">
                                    <div class="fields">
                                        <div class="field_item">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{'URL' | translate}}</mat-label>
                                                <input matInput class="form-control" [(ngModel)]="url.url"
                                                    name="url{{i}}" placeholder="{{'URL' | translate}}"
                                                    autocomplete="nope" [disabled]="isGlobalContact && !isAdmin" />
                                            </mat-form-field>
                                        </div>
                                        <div class="field_item small_field">
                                            <mat-form-field appearance="outline" class="mobile-width-40">
                                                <mat-label>{{'TYPE' | translate}}</mat-label>
                                                <mat-select class="margin-right-5" [(ngModel)]="url.url_type"
                                                    name="urlType{{i}}" [disabled]="isGlobalContact && !isAdmin">
                                                    <mat-option value="home">{{ 'URL_TYPE.HOME' | translate
                                                        }}</mat-option>
                                                    <mat-option value="work">{{ 'URL_TYPE.WORK' | translate
                                                        }}</mat-option>
                                                    <mat-option value="other">{{ 'URL_TYPE.OTHER' | translate
                                                        }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="right-space-div add-remove-button">
                                        <button mat-button (click)="removeUrl(i)"
                                            [disabled]="isGlobalContact && !isAdmin" matTooltip="{{ 'DELETE' | translate }}">
                                            <ng-container [ngTemplateOutlet]="deleteIcon"></ng-container>
                                        </button>
                                        <!-- <button mat-button (click)="addUrl(i)" [disabled]="isGlobalContact && !isAdmin">
                                            <mat-icon>add_circle_outline</mat-icon>
                                        </button> -->
                                    </div>
                                </div>
                                <button mat-button (click)="addUrl(i)" [disabled]="isGlobalContact && !isAdmin"
                                    *ngIf="last" matTooltip="{{ 'ADD' | translate }}">
                                    <mat-icon>add_circle_outline</mat-icon>
                                    Add
                                </button>
                            </div>
                            <div class="separator-div desktop-hide"></div>
                            <div class="form-group row-flex"
                                *ngFor="let event of contactDetails.events_attributes; let i= index; last as last">
                                <div class="form-group contcat-dual-fields contact-mobile-fields">
                                    <div class="fields">
                                        <div class="field_item">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{'TYPE' | translate}}</mat-label>
                                                <mat-select class="margin-right-5" [(ngModel)]="event.event_type"
                                                    name="eventType{{i}}" [disabled]="isGlobalContact && !isAdmin">
                                                    <mat-option value="birthday">{{ 'CONTACT_EVENT_TYPE.BIRTHDAY' |
                                                        translate
                                                        }}</mat-option>
                                                    <mat-option value="anniversary">{{ 'CONTACT_EVENT_TYPE.ANNIVERSARY'
                                                        | translate
                                                        }}</mat-option>
                                                    <mat-option value="custom">{{ 'CONTACT_EVENT_TYPE.CUSTOM' |
                                                        translate
                                                        }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="field_item small_field">
                                            <mat-form-field appearance="outline" class="mobile-width-90">
                                                <mat-label>{{'DATE' | translate }}</mat-label>
                                                <input matInput [matDatepicker]="picker" [(ngModel)]="event.event_date"
                                                    name="birthday" [disabled]="isGlobalContact && !isAdmin">
                                                <mat-datepicker-toggle matSuffix [for]="picker"
                                                    [disabled]="isGlobalContact && !isAdmin"></mat-datepicker-toggle>
                                                <mat-datepicker #picker disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="right-space-div add-remove-button">
                                        <button mat-button (click)="removeEvent(i)"
                                            [disabled]="isGlobalContact && !isAdmin">
                                            <ng-container [ngTemplateOutlet]="deleteIcon" matTooltip="{{ 'DELETE' | translate }}"></ng-container>
                                        </button>
                                        <!-- <button mat-button (click)="addEvent(i)" [disabled]="isGlobalContact && !isAdmin">
                                            <mat-icon>add_circle_outline</mat-icon>
                                        </button> -->
                                    </div>
                                </div>
                                <button mat-button (click)="addEvent(i)" [disabled]="isGlobalContact && !isAdmin"
                                    *ngIf="last" matTooltip="{{ 'ADD' | translate }}">
                                    <mat-icon>add_circle_outline</mat-icon>
                                    Add
                                </button>
                            </div>
                            <div class="separator-div desktop-hide"></div>
                            <div class="form-group tags-container" [matMenuTriggerFor]="contactListMenu" >
                                <mat-label *ngIf="selectedLists.length === 0">{{ 'LISTS_LBL' | translate }}</mat-label>
                                <div class="fields chip-list-field">
                                    <div class="field_item contact-chips-list">
                                        <mat-chip-list #contactChipList>
                                            <mat-chip *ngFor="let list of selectedLists" [selectable]="true"
                                                [removable]="true" (removed)="remove(list)"
                                                [ngStyle]="{'background': list.color_hex !== null ? list.color_hex : '#eaeef2'}"
                                                title="{{ list.name }}">
                                                <span class="name">{{list.name}}</span>
                                                <mat-icon matChipRemove *ngIf="true">close</mat-icon>
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
                                </div>
                            </div>
                            <div class="separator-div desktop-hide"></div>
                            <!-- <div class="form-group margin-10">
                                <div class="fields">
                                    <div class="field_item list-field-item" [matMenuTriggerFor]="contactTagMenu">
                                        <mat-label>{{ 'TAGS' | translate }}</mat-label>
                                        <div class="list-item">
                                            <mat-icon>keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div> -->


                            <div class="form-group tags-container"  [matMenuTriggerFor]="contactTagMenu">
                                <mat-label *ngIf="selectedTags.length === 0">{{ 'TAGS' | translate }}</mat-label>
                                <div class="fields chip-list-field">
                                    <div class="field_item contact-chips-list">
                                        <mat-chip-list #contactChipTags>
                                            <mat-chip *ngFor="let tag of selectedTags" [selectable]="true" class="mat-chip-data"
                                                [removable]="true" (removed)="removeTag(tag)"
                                                [ngStyle]="{'background': tag.color_hex !== null ? tag.color_hex : '#eaeef2'}"
                                                title="{{ tag.name }}">
                                                <span class="name">{{tag.name}}</span>
                                                <mat-icon matChipRemove *ngIf="true">close</mat-icon>
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
                                </div>
                                <div class="right-space-div"></div>
                            </div>
                            <div class="separator-div desktop-hide"></div>
                            <div class="form-group">
                                <div class="fields">
                                    <div class="field_item">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{'NOTES' | translate }}</mat-label>
                                            <textarea matInput class="" [(ngModel)]="contactDetails.notes"
                                                #note="ngModel" name="notes" placeholder="{{'NOTES' | translate}}"
                                                matTextareaAutosize matAutosizeMinRows="3" rows="4" autocomplete="nope"
                                                [disabled]="isGlobalContact && !isAdmin"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="separator-div desktop-hide"></div>
                            <div class="form-group"
                                *ngFor="let custom of contactDetails.custom_fields_attributes; let i= index;">
                                <div class="form-group contact-mobile-fields">
                                    <div class="fields">
                                        <div class="field_item">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{custom.field_name}}</mat-label>
                                                <input matInput class="form-control" [(ngModel)]="custom.field_value"
                                                    name="{{ custom.field_name }}" placeholder="{{custom.field_name}}"
                                                    autocomplete="nope" [disabled]="isGlobalContact && !isAdmin" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="right-space-div add-remove-button">
                                        <button mat-button (click)="removeCustomField(i)"
                                            [disabled]="isGlobalContact && !isAdmin" matTooltip="{{ 'DELETE' | translate }}">
                                            <ng-container [ngTemplateOutlet]="deleteIcon"></ng-container>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="separator-div desktop-hide"></div>

                            <div class="form-group" *ngIf="!isShowMoreField">
                                <div class="fields">
                                    <div class="field_item">
                                        <mat-form-field appearance="outline" floatLabel="never">
                                            <input matInput class="form-control" [(ngModel)]="label" name="label"
                                                placeholder="{{ 'LABEL' | translate }}" autocomplete="nope"
                                                [disabled]="isGlobalContact && !isAdmin" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="right-space-div add-remove-button">
                                    <button mat-button (click)="hideCustomField()"
                                        [disabled]="isGlobalContact && !isAdmin" matTooltip="{{ 'DELETE' | translate }}">
                                        <ng-container [ngTemplateOutlet]="deleteIcon" ></ng-container>
                                    </button>
                                    <button mat-button (click)="addCustomField(label)"
                                        [disabled]="isGlobalContact && !isAdmin">
                                        <mat-icon class="check">check_circle_outline</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="more-fields">
                                <button mat-button (click)="showMoreFields ? showMoreFields = false : showMoreFields = true;">
                                    {{ ((!showMoreFields)? 'ADVANCED_MODE' : 'BASIC_MODE') | translate }}
                                </button>
                            </div> -->
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="contact__dialog-footer footer-section" #addContactFooter>
        <!-- <div class="more-fields">
            <button mat-button (click)="showMoreFields ? showMoreFields = false : showMoreFields = true;">
              {{ ((!showMoreFields)? 'ADVANCED_MODE' : 'BASIC_MODE') | translate }}
            </button>
        </div> -->
        <div class="form-group padding-top-bottom-10 add-more-field" *ngIf="isShowMoreField">
            <div class="field_icon margin-0">
                <mat-icon class="brand">add_circle_outline</mat-icon>
            </div>
            <div class="fields" (click)="showCustomField()" matTooltip="{{ 'ADD_MORE_FIELDS' | translate }}">
                <div class="field_item add_more">
                    {{ 'ADD_MORE_FIELDS' | translate }}
                </div>
            </div>
        </div>
        <div class="desktop-footer">
            <button mat-button (click)="close()">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button class="primary-button" (click)="submit()" [disabled]="isSubmit">
                {{ ((!isEdit) ? 'CREATE' : 'SAVE') | translate }}
            </button>
        </div>
    </div>
</div>

<mat-menu #contactListMenu="matMenu" [class]="'contact-lists-mat-menu'">
    <div mat-menu-item class="search-item">
        <mat-form-field >
            <input matInput type="text" [(ngModel)]="searchListText" (click)="$event.stopPropagation();"
                placeholder="{{ 'SEARCH_DOT' | translate }}">
            <button mat-button matSuffix mat-icon-button aria-label="Clear"
                (click)="$event.stopPropagation();searchFromList()">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div class="list-scrollbar-item">
        <div mat-menu-item class="contact-list-item" *ngFor="let list of contactLists"
            (click)="$event.stopPropagation();addToSelectList(list)">
            <mat-icon class="mdi-18px" [ngStyle]="{'color': list.color_hex !== null ? list.color_hex : '#888888' }"
                fontSet="mdi" fontIcon="mdi-account-box-multiple"></mat-icon>
            <span title="{{ list.name }}">{{list.name}}</span>
            <mat-icon class="check-item" *ngIf="isSelectedList(list)">check</mat-icon>
        </div>
    </div>
    <button mat-menu-item class="add-list" (click)="$event.stopPropagation();addList()">
        <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
        <span> {{ 'NEW_LIST' | translate }}</span>
    </button>
</mat-menu>

<!-- Tags contact -->

<mat-menu #contactTagMenu="matMenu" [class]="'contact-tags-mat-menu'">
    <div mat-menu-item class="search-item">
        <mat-form-field>
            <input matInput type="text" [(ngModel)]="searchTagText" (click)="$event.stopPropagation();"
                placeholder="{{ 'SEARCH_DOT' | translate }}">
            <button mat-button matSuffix mat-icon-button aria-label="Clear"
                (click)="$event.stopPropagation();searchFromTags()">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div class="list-scrollbar-item">
        <div mat-menu-item class="contact-list-item" *ngFor="let tag of contactTags"
            (click)="$event.stopPropagation();addToSelectTag(tag)">
            <mat-icon class="mdi-18px" [ngStyle]="{'color': tag.color_hex ? tag.color_hex : '#888888' }" fontSet="mdi"
                fontIcon="mdi-tag"></mat-icon>
            <span title="{{ tag.name }}">{{tag.name}}</span>
            <mat-icon class="check-item" *ngIf="isSelectedTag(tag)">check</mat-icon>
        </div>
    </div>
    <button mat-menu-item class="add-list" (click)="$event.stopPropagation();addTag()">
        <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
        <span> {{ 'NEW_TAG' | translate }}</span>
    </button>
</mat-menu>

<!-- Country contact -->
<mat-menu #contactCountryMenu="matMenu" [class]="'contact-country-mat-menu'"
    yPosition="{{isOnMobile ? 'above' : 'below'}}">
    <div mat-menu-item class="search-item">
        <mat-form-field class="country-search" floatLabel="never">
            <input type="text" matInput [(ngModel)]="searchCountry" autocomplete="nope"
                (click)="$event.stopPropagation();">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <ng-template matMenuContent let-countryData="countryData">
        <div class="list-scrollbar-item">
            <div mat-menu-item class="contact-list-item"
                *ngFor="let country of countryCodes | vpListsSearch : searchCountry;"
                (click)="countryItemSelect(countryData, country.code)">
                <span class="drop-down-flag" [innerHTML]="country.flag"></span>
                <span class="country-name-lbl">{{country.name}}</span>
                <span class="country-dial-code">{{country.dial_code}}</span>
            </div>
        </div>
    </ng-template>
</mat-menu>

<ng-template #deleteIcon>
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" class="ng-star-inserted">
        <title>Icon/product/delete-new</title>
        <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill-rule="evenodd">
            <path fill="#8B96A0"
                d="M18,7 L6,7 L6,18.2857143 C6,19.7746499 7.19131256,21 8.66666667,21 L15.3333333,21 C16.8086874,21 18,19.7746499 18,18.2857143 L18,7 Z M8,9 L16,9 L16,18.2857143 C16,18.6824929 15.6913126,19 15.3333333,19 L8.66666667,19 L8.57834889,18.9935231 C8.25938319,18.9465317 8,18.649428 8,18.2857143 L8,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z"
                id="Combined-Shape"></path>
        </g>
    </svg>
</ng-template>

<ng-template #emptyStarIcon>
    <svg width="18px" height="18px" viewBox="0 0 24 24" fill="#8b96a0" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" class="ng-star-inserted">
        <title>Icon/product/favorites-new</title>
        <g id="Icon/product/favorites-new" stroke="none" stroke-width="1" fill-rule="evenodd">
            <g id="Icon-24px" transform="translate(1.505671, 1.540769)">
                <path
                    d="M6.9983286,6.14323104 L1.40953093,6.61799276 L1.26460357,6.63518784 C-0.0552054556,6.83794505 -0.44917403,8.20493004 0.598745785,9.11274336 L4.8433286,12.789231 L3.56851214,18.2545538 L3.54091129,18.3929441 C3.32282074,19.7132073 4.50160138,20.5142266 5.69036612,19.7967359 L10.4943286,16.897231 L15.2982911,19.7967359 L15.421404,19.8657141 C16.6098776,20.4807549 17.7355842,19.6067107 17.4201451,18.2545538 L16.1443286,12.789231 L20.3899114,9.11274336 L20.4968956,9.01361095 C21.4441276,8.07347077 20.9616162,6.73528328 19.5791263,6.61799276 L13.9893286,6.14323104 L11.805765,0.99087528 C11.2452509,-0.33161867 9.74228122,-0.328964184 9.18289224,0.99087528 L6.9983286,6.14323104 Z M8.36972948,8.03467405 L10.4943286,3.02023104 L12.6189277,8.03467405 L18.0483286,8.49523104 L13.9233514,12.0686154 L15.1623286,17.378231 L10.4943286,14.5612051 L5.8253286,17.378231 L7.06530584,12.0686154 L2.9393286,8.49523104 L8.36972948,8.03467405 Z"
                    id="Shape"></path>
            </g>
        </g>
    </svg>
</ng-template>