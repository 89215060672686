<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="contact-dialog import-csv-vcf-contact-dialog">
    <div class="contact__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{ 'CONTACT_IMPORT_DIALOG_TITLE' | translate }}
            <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-help-circle-outline"></mat-icon>
        </div>
        <div class="desktop-close-button">
        </div>
    </div>
    <div class="contact__dialog-body">
        <div class="content">
            <input style="display: none;" type="file" #attachmentImport
                        (change)="uploadImport(attachmentImport.files, $event)" (click)="resetImportData();attachmentImport.value = null" />
            <span class="desktop-view">
                <div class="content-div description">
                    {{ 'ALLOW_IMPORT_FILE_DESCRIPTION' | translate }}
                </div>
                <div class="content-div">
                    <span (click)="attachmentImport.click();resetFile()"
                        class="lable-select">{{ 'SELECT_FILE' | translate }}</span>
                    <span class="no-file-chosen" *ngIf="files !== null"
                        title="{{ attachmentImport.files[0].name }}">{{ attachmentImport.files[0].name.length > 20 ? attachmentImport.files[0].name.substr(0,12)+'...' + attachmentImport.files[0].name.substr(-5) : attachmentImport.files[0].name}}</span>
                    <span class="no-file-chosen" *ngIf="files === null"
                        title="{{ 'NO_FILE_CHOOSEN' | translate }}">{{ 'NO_FILE_CHOOSEN' | translate }}</span>
                </div>
                <div class="select-list-block">
                    <button class="select-list" (click)="openListSelection()">{{'SELECT_THE_LIST_LBL' | translate}}</button>
                    <span class="selected-list-count" *ngIf="selectedList.length">{{selectedList.length}} {{'SELECTED' | translate}}.</span>
                </div>
            </span>
            <span class="mobile-view">
                <div class="content-div description-mobile">
                    {{ 'SELECT_CSV_VCF_FILE_MSG' | translate }}
                </div>
                <div class="content-div import-contact-msg" (click)="attachmentImport.click();resetFile()">
                   <span>{{ 'IMPORT_CONTACT' | translate }}</span>
                </div>
            </span>
            <div *ngIf="!!csvHeaders && csvHeaders.length > 0" class="csv-header-items">
                <mat-form-field>
                    <mat-label>{{ 'FIRST_NAME' | translate }}</mat-label>
                    <mat-select [(ngModel)]="csvFirstName">
                        <mat-option *ngFor="let header of csvHeaders;let i= index;" value="{{i}}">{{header}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'LAST_NAME' | translate }}</mat-label>
                    <mat-select [(ngModel)]="csvLastName">
                        <mat-option *ngFor="let header of csvHeaders;let i= index;" value="{{i}}">{{header}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'MIDDLE_NAME' | translate }}</mat-label>
                    <mat-select [(ngModel)]="csvMiddleName">
                        <mat-option *ngFor="let header of csvHeaders;let i= index;" value="{{i}}">{{header}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'COMPANY' | translate }}</mat-label>
                    <mat-select [(ngModel)]="csvCompany">
                        <mat-option *ngFor="let header of csvHeaders;let i= index;" value="{{i}}">{{header}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'JOB_TITLE' | translate }}</mat-label>
                    <mat-select [(ngModel)]="csvJobTitle">
                        <mat-option *ngFor="let header of csvHeaders;let i= index;" value="{{i}}">{{header}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'PHONE' | translate }}</mat-label>
                    <mat-select [(ngModel)]="csvPhones">
                        <mat-option *ngFor="let header of csvHeaders;let i= index;" value="{{i}}">{{header}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'EMAIL' | translate }}</mat-label>
                    <mat-select [(ngModel)]="csvEmails">
                        <mat-option *ngFor="let header of csvHeaders;let i= index;" value="{{i}}">{{header}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="contact__dialog-footer">
        <div class="desktop-footer">
            <button mat-button (click)="close()">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button [disabled]="files === null" (click)="importContact()">
                {{ 'IMPORT_LABEL' | translate }}
            </button>
        </div>
        <div class="mobile-footer" *ngIf="files !== null">
            <div class="title">
                {{ 'IMPORT_LABEL' | translate }}
            </div>
            <div class="save_footer_btn">
                <button mat-button (click)="importContact()">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>