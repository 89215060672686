import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, EMPTY, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { RootState } from "src/app/reducers";
import { DatabaseInterface } from "./database.interface";
import { DumbDBService } from "./dumpDB.service";
import { IndexedDBService } from "./indexedDB.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";

@Injectable()
export class DatabaseService implements DatabaseInterface {
    isIndexedDBSupported: boolean = false;
    dbServiceImpl: DatabaseInterface;
    avatar = {};
    dbReady = new BehaviorSubject<boolean>(false);
    constructor(private store: Store<RootState>, private broadcaster: Broadcaster) {
        console.log("[DatabaseService][constructor]");

        const idb: any = require("idb-keyval/dist/idb-keyval-cjs-compat.min.js");
        const testStore = new idb.Store("contacts-database", "keyval");
        idb.set("testIdb", "test", testStore).then(() => {
            this.isIndexedDBSupported = true;
            localStorage.setItem("idbsupport", "yes");
            console.log("[DatabaseService][constructor1] using indexedDB");
            this.dbServiceImpl = new IndexedDBService();
            this.initDatabase();
            this.dbServiceImpl.getContactCountInDatabase().pipe(take(1)).subscribe(res => {
               console.log("[DatabaseService][constructor] getContactCountInDatabase: ", res);
               this.setDatabaseReady();
            });

        }).catch((error) => {
            console.log("[DatabaseService][constructor] error attempting to store: ", error);
            console.log("[DatabaseService][constructor] using dumbDBService");
            this.dbServiceImpl = new DumbDBService();
            localStorage.setItem("idbsupport", "no");
            this.dbServiceImpl.getContactCountInDatabase().pipe(take(1)).subscribe(res => {
                console.log("[DatabaseService][constructor] getContactCountInDatabase: ", res);
                this.setDatabaseReady();
            });

        });
    }

    deleteContacts(ids: any[]): Observable<any> {
        return this.dbServiceImpl.deleteContacts(ids);
    }

    deleteContact(contact: any): Observable<any> {
        return this.dbServiceImpl.deleteContact(contact);
    }

    getContactId(id: string): Observable<any> {
        return this.dbServiceImpl.getContactId(id);
    }

    createOrUpdateContacts(contacts: any[]): Observable<any> {
        return this.dbServiceImpl.createOrUpdateContacts(contacts);
    }

    fetchContacts(global?: boolean, deleted?: boolean): Observable<any> {
        return this.dbServiceImpl.fetchContacts(global, deleted);
    }
    fetchAllContacts(): Observable<any> {
        return this.dbServiceImpl.fetchAllContacts();
    }

    getContactCountInDatabase(): Observable<any> {
        return this.dbServiceImpl.getContactCountInDatabase();
    }

    storeAvatar(avatarB64Url: string, avatarUrl: string, email: string): Observable<any> {
        return this.dbReady.value ? this.dbServiceImpl.storeAvatar(avatarB64Url, avatarUrl, email) : EMPTY;
    }

    deleteAvatar(email: string): Observable<any> {
        return this.dbServiceImpl.deleteAvatar(email);
    }

    getAvatarByEmail(email: string): Observable<any> {
        return this.dbReady.value ? this.dbServiceImpl.getAvatarByEmail(email) : EMPTY;
    }

    fetchAllAvatarFromDatabase(): Observable<any[]> {
        return this.dbServiceImpl.fetchAllAvatarFromDatabase();
    }

    createOrUpdateContactFolders(folders: any[]): Observable<any> {
        return this.dbServiceImpl.createOrUpdateContactFolders(folders);
    }

    getContactLists(): Observable<any[]> {
        return this.dbServiceImpl.getContactLists();
    }

    getContactListById(id): Observable<any[]> {
        return this.dbServiceImpl.getContactListById(id);
    }

    getContactsByList(id): Observable<any[]> {
        return this.dbServiceImpl.getContactsByList(id);
    }

    createOrUpdateContactGroup(groups: any[]): Observable<any> {
        return this.dbServiceImpl.createOrUpdateContactGroup(groups);
    }

    getAllContactGroup(): Observable<any[]> {
        return this.dbServiceImpl.getAllContactGroup();
    }

    getContactGroup(id: string): Observable<any> {
        return this.dbServiceImpl.getContactGroup(id);
    }

    getAllContactIds(): Observable<any[]> {
        return this.dbServiceImpl.getAllContactIds();
    }

    addPendingOp(op: any, type: string): Observable<any> {
        return this.dbServiceImpl.addPendingOp(op, type);
    }

    addPendingOps(ops: any[], type: string): Observable<any> {
        return this.dbServiceImpl.addPendingOps(ops, type);
    }

    getAllPendingOperations(): Observable<any[]> {
        return this.dbServiceImpl.getAllPendingOperations();
    }

    getPendingOpsByContact(id): Observable<any[]> {
        return this.dbServiceImpl.getPendingOpsByContact(id);
    }

    deletePendingOpById(id): Observable<any> {
        return this.dbServiceImpl.deletePendingOpById(id);
    }

    initDatabase() {
        this.dbServiceImpl.initDatabase();
    }

    setDatabaseReady() {
        console.log("[DatabaseService] set DatabaseReady");
        this.dbReady.next(true);
        this.broadcaster.broadcast("DBREADY");
    }
}