<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<!-- Desktop view -->
<div class="desktop" *ngIf="!isMobileScreen">
    <div class="header-part">
        <div></div>
        <div class="text">{{ 'ADVANCE_SEARCH' | translate }}</div>
        <div class="close-icon" (click)="close()">
            <mat-icon class="mdi-30px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
        </div>
    </div>
    <div class="content">
        <div class="search-field">
            <mat-form-field [floatLabel]="'never'">
                <mat-icon matPrefix class="mdi-30px" fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
                <input type="text" #searchTextInput matInput placeholder="{{'HEADER_SEARCH_PLACEHOLDER' | translate}}" [(ngModel)]="searchPhrase" (keydown)="searchFromText($event)">
                <span matSuffix class="saved-search-lbl" *ngIf="searchSaved">{{ 'SEARCH_SAVED' | translate }}</span>
                <mat-icon matSuffix class="mdi-24px close-icon-item" fontSet="mdi" fontIcon="mdi-close" (click)="clearSearchPhrase()"></mat-icon>
                <mat-icon matSuffix class="mdi-30px save-icon-item" fontSet="mdi" fontIcon="mdi-content-save" (click)="showSaveSearch(true)">
                </mat-icon>
                <!-- <mat-autocomplete #auto="matAutocomplete" class="contact-auto-complete">
                    <mat-option *ngFor="let contact of contacts" (click)="addToSearchField(contact)">
                        <vp-avatar [user]="{firstLastCharacters: contact.fullName.charAt(0), color: 'rgb(3, 169, 244)', avatarURL: null}"> </vp-avatar>
                        {{contact.fullName}}
                    </mat-option>
                </mat-autocomplete> -->
            </mat-form-field>
            <div class="save-search-dialog" *ngIf="isShowSaveSearchDialog">
                <input type="text" matInput [(ngModel)]="saveSearchName" autocomplete="nope">
                <button mat-button (click)="showSaveSearch(false)">{{ 'CANCEL' | translate }}</button>
                <button mat-button (click)="saveAdvanceSearch()" [disabled]="saveSearchName === ''">{{ 'SAVE' | translate }}</button>
            </div>
        </div>
        <div class="advance-search-recent-activity-option">
            <mat-checkbox [(ngModel)]="searchRecentActivity" (change)="searchInRecentActivity($event)">{{ 'SEARCH_THROUGH_RECENT_ACTIVITY' | translate }}</mat-checkbox>
        </div>
        <div class="search-operation">
            <div class="filter-item-option" *ngIf="isShowFilter">
                <div class="back-drop" (click)="hideFilterOption()"></div>
                <div class="desktop-filter">
                    <div class="search-word">
                        <input type="text" matInput [(ngModel)]="searchFilter" autocomplete="nope">
                        <mat-icon matSuffix class="mdi-14px" fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
                    </div>
                    <div class="render-filters">
                        <div *ngFor="let filter of filterLists | vpListsSearch : searchFilter" class="filters-name" (click)="addFilter(filter)" [class.is-selected-item]="isFilterAvailable(filter)">
                            <span *ngIf="filter.name === 'first_name'">{{ 'FILTER_FIRST_NAME' | translate }}</span>
                            <span *ngIf="filter.name === 'last_name'">{{ 'FILTER_LAST_NAME' | translate }}</span>
                            <span *ngIf="filter.name === 'middle_name'">{{ 'FILTER_MIDDLE_NAME' | translate }}</span>
                            <span *ngIf="filter.name === 'updated_at'">{{ 'FILTER_UPDATE_DATE' | translate }}</span>
                            <span *ngIf="filter.name === 'deleted'">{{ 'FILTER_DELETED' | translate }}</span>
                            <span *ngIf="filter.name === 'deleted_at'">{{ 'FILTER_DELETE_ON' | translate }}</span>
                            <span *ngIf="filter.name === 'all'">{{ 'FILTER_ALL' | translate }}</span>
                            <span *ngIf="filter.name === 'q'">{{ 'FILTER_FULL_TEXT' | translate }}</span>
                            <span *ngIf="filter.name === 'dpt'">{{ 'FILTER_IN_MY_DEPARTMENT' | translate }}</span>
                            <span *ngIf="filter.name === 'contact_list'">{{ 'FILTER_CONTACT_LIST' | translate }}</span>
                            <span *ngIf="filter.name === 'contact_group'">{{ 'FILTER_CONTACT_GROUP' | translate }}</span>
                            <span *ngIf="filter.name === 'tags'">{{ 'FILTER_TAGS' | translate }}</span>
                            <span *ngIf="filter.name === 'id'">{{ 'FILTER_ID' | translate }}</span>
                            <span *ngIf="filter.name === 'jid'">{{ 'FILTER_JID' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.city'">{{ 'FILTER_RELATED_USER_CITY' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.nationality'">{{ 'COUNTRY' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.gender'">{{ 'FILTER_RELATED_USER_GENDER' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.admin'">{{ 'FILTER_RELATED_USER_ADMIN' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.login'">{{ 'FILTER_RELATED_USER_LOGIN' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.agb_accepted'">{{ 'FILTER_RELATED_USER_AGB_ACCEPTED' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.marital_status'">{{ 'FILTER_RELATED_USER_MARITAL_STATUS' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.vnc_employee'">{{ 'FILTER_RELATED_USER_VNC_EMPLOYEE' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.freelancer'">{{ 'FILTER_RELATED_USER_FREELANCER' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.start_date'">{{ 'FILTER_RELATED_USER_START_DATE' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.end_date'">{{ 'FILTER_RELATED_USER_END_DATE' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.per_week_availability'">{{ 'FILTER_RELATED_USER_PER_WEEK_AVAIALABILITY' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.hourly_rate'">{{ 'FILTER_RELATED_USER_HOURLY_RATE' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.payment_mode'">{{ 'FILTER_RELATED_PAYMENT_MODE' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.skills'">{{ 'FILTER_RELATED_SKILLS' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.interests'">{{ 'FILTER_RELATED_INTEREST' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.labels'">{{ 'FILTER_RELATED_LABELS' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.video_bridge'">{{ 'VIDEO_BRIDGE_LBL' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.omemo'">{{ 'OMEMO' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.paid_subscription'">{{ 'PAID_SUBSCRIPTION' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.created_on'">{{ 'CREATED' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.last_login_on'">{{ 'LAST_CONNECTION' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.tfa_enabled'">{{ '2FA_ENABLED' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.organization'">{{ 'ORGANIZATION_LBL' | translate }}</span>
                            <span *ngIf="filter.name === 'related_user.status'">{{ 'STATUS' | translate }}</span>
                        </div>
                    </div>
            </div>
            </div>
            <div class="filter-add-button">
                <div class="add-filter-button">
                    <div>
                        <button mat-button (click)="showFilterOption()">{{ 'ADD_FILTER' | translate }}</button>
                    </div>
                    
                </div>
 
                <div class="reset-save-search">
                    <div class="reset-field" (click)="resetField()">
                        {{ 'RESET_FILEDS' | translate }}
                    </div>
                    <div class="save-search" [matMenuTriggerFor]="saveSearchMenu">
                        {{ 'SAVED_SEARCHES' | translate }}
                        <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-menu-right"></mat-icon>
                    </div>
                </div>
            </div>
            <div class="fill-items">
                <div *ngFor="let selectFilter of selectedFilter" class="all-filter">
                    <div class="filter-checkbox">
                        <mat-checkbox [(ngModel)]="selectFilter.isSelected" (change)="changeSelected(selectFilter)">
                            {{ selectFilter.label}}
                        </mat-checkbox>
                    </div>
                    <div class="filter-operator">
                        <mat-select [(ngModel)]="selectFilter.operatorModel">
                            <mat-option *ngFor="let oprator of selectFilter.operators" value="{{oprator.value}}">{{oprator.label}}</mat-option>
                        </mat-select>
                    </div>
                    <div class="filter-values">
                        <span *ngIf="selectFilter.type === 'string' && selectFilter.operatorModel !== '*'">
                            <input type="text" autocomplete="nope" matInput [(ngModel)]="selectFilter.valueSelectModel">
                        </span>
                        <span *ngIf="selectFilter.type === 'integer' && selectFilter.operatorModel !== '*'">
                            <input type="text" autocomplete="nope" matInput [(ngModel)]="selectFilter.valueSelectModel">
                        </span>
                        <span *ngIf="selectFilter.type === 'date_past' && selectFilter.operatorModel !== '*'">
                            <mat-form-field class="date-class">
                                <input matInput [matDatepicker]="picker" [(ngModel)]="selectFilter.valueSelectModel" disabled>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker disabled="false"></mat-datepicker>
                              </mat-form-field>
                        </span>
                        <span *ngIf="selectFilter.type === 'list' && selectFilter.operatorModel !== '*'" class="list-item">
                            <span *ngIf="!selectFilter.showMultiple">
                                <mat-select [(ngModel)]="selectFilter.valueSelectModel">
                                    <mat-option *ngFor="let value of selectFilter.values" value="{{value.value}}">{{value.label}}</mat-option>
                                </mat-select>
                            </span>
                            <span *ngIf="selectFilter.showMultiple">
                                <select multiple class="multi-select" id="{{selectFilter.name}}">
                                    <option *ngFor="let value of selectFilter.values" value="{{value.value}}">
                                        {{value.label}}
                                    </option>
                                  </select>
                            </span>
                            <mat-icon class="mdi-18px font-unfold-icon" *ngIf="!selectFilter.showMultiple" fontSet="mdi" fontIcon="mdi-unfold-more-horizontal" (click)="showHideMultiple(selectFilter)"></mat-icon>
                            <mat-icon class="mdi-18px font-unfold-icon" *ngIf="selectFilter.showMultiple" fontSet="mdi" fontIcon="mdi-unfold-less-horizontal" (click)="showHideMultiple(selectFilter)"></mat-icon>
                        </span>
                        <span *ngIf="selectFilter.type === 'list_optional' && selectFilter.operatorModel !== '*'" class="list-item">
                            <span *ngIf="!selectFilter.showMultiple">
                                <mat-select [(ngModel)]="selectFilter.valueSelectModel">
                                    <mat-option *ngFor="let value of selectFilter.values" value="{{value.value}}">{{value.label}}</mat-option>
                                </mat-select>
                            </span>
                            <span *ngIf="selectFilter.showMultiple">
                                <select multiple class="multi-select" id="{{selectFilter.name}}">
                                    <option class="multi-select-item" *ngFor="let value of selectFilter.values" value="{{value.value}}">
                                        {{value.label}}
                                    </option>
                                  </select>
                            </span>
                            <mat-icon class="mdi-18px font-unfold-icon" *ngIf="!selectFilter.showMultiple" fontSet="mdi" fontIcon="mdi-unfold-more-horizontal" (click)="showHideMultiple(selectFilter)"></mat-icon>
                            <mat-icon class="mdi-18px font-unfold-icon" *ngIf="selectFilter.showMultiple" fontSet="mdi" fontIcon="mdi-unfold-less-horizontal" (click)="showHideMultiple(selectFilter)"></mat-icon>
                        </span>
                        <span *ngIf="selectFilter.type === 'float' && selectFilter.operatorModel !== '*'">
                            <input type="text" autocomplete="nope" matInput [(ngModel)]="selectFilter.valueSelectModel">
                        </span>
                    </div>
                </div>
            </div>
            <div class="fixed-content">
                {{ 'CONDITIONAL_EXAMPLE' | translate }}:
                <ul>
                    <li>
                        <div innerHTML="{{ 'LINE_1' | translate }}"></div>
                    </li>
                    <li>
                        <div innerHTML="{{ 'LINE_3' | translate }}"></div>
                    </li>
                    <li>
                        <div innerHTML="{{ 'LINE_3' | translate }}"></div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="search-cancel">
            <button mat-button (click)="close()" class="cancel">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button class="search" (click)="search()">
                {{ 'HEADER_SEARCH_PLACEHOLDER' | translate }}
            </button>
        </div>
    </div>
</div>

<!-- Mobile view -->
<div class="mobile" *ngIf="isMobileScreen">
    <div class="header">
        <div class="text">{{ 'ADVANCE_SEARCH' | translate }}</div>
    </div>
    <div class="search-item">
        <input autocomplete="nope" type="text" matInput placeholder="{{'HEADER_SEARCH_PLACEHOLDER' | translate}}" [(ngModel)]="searchPhrase">
        <span matSuffix class="saved-search-lbl" *ngIf="searchSaved">{{ 'SEARCH_SAVED' | translate }}</span>
        <mat-icon matSuffix class="mdi-18px close-icon-item" fontSet="mdi" fontIcon="mdi-close" (click)="clearSearchPhrase()"></mat-icon>
        <mat-icon matSuffix class="mdi-18px save-icon-item" fontSet="mdi" fontIcon="mdi-content-save" (click)="showSaveSearch(true)"></mat-icon>
    </div>
    <div class="advance-search-recent-activity-option">
        <mat-checkbox [(ngModel)]="searchRecentActivity" (change)="searchInRecentActivity($event)">{{ 'SEARCH_THROUGH_RECENT_ACTIVITY' | translate }}</mat-checkbox>
    </div>
    <div class="mobile-reset-save-search">
        <div class="reset-field" (click)="resetField()">
            {{ 'RESET_FILEDS' | translate }}
        </div>
        <div class="save-search" (click)="hideShowSavedSearchMobile(true)">
            {{ 'SAVED_SEARCHES' | translate }}
        </div>
    </div>
    <!-- Add filter item -->
    <div class="add-filter-item">

        <!-- Show saved searchs -->
        <div class="mobile-show-save-search" *ngIf="showMobileSavedSearch">
            <div class="back-drop" (click)="hideShowSavedSearchMobile(false)"></div>
            <div class="searches">
                <div class="searches-options" *ngFor="let search of saveSearch" (click)="loadSaveSearch(search);hideShowSavedSearchMobile(false)">
                    {{ search.name }}
                </div>
            </div>
        </div>
        <!-- show save search dialog-->
        <div class="mobile-show-save-search-dialog" *ngIf="isShowSaveSearchDialog">
            <div class="back-drop" (click)="showSaveSearch(false)"></div>
            <div class="save-searches">
                <div class="header">
                    {{ 'SAVE_SEARCH_NAME' | translate }}
                </div>
                <div class="text-field">
                    <mat-form-field>
                        <input type="text" [(ngModel)]="saveSearchName" matInput placeholder="{{ 'SAVED_SEARCH_NAME' | translate }}">
                    </mat-form-field>
                </div>
                <div class="btn-footer">
                    <button mat-button (click)="showSaveSearch(false)">{{ 'CANCEL' | translate }}</button>
                    <button mat-button (click)="saveAdvanceSearch()">{{ 'SAVE' | translate }}</button>
                </div>
            </div>
        </div>
        <div *ngFor="let selectFilter of selectedFilter" class="all-filter">
            <div class="checkbox-button">
                <mat-checkbox [(ngModel)]="selectFilter.isSelected" (change)="changeSelected(selectFilter)">
                    {{ selectFilter.label}}
                </mat-checkbox>
                <mat-select [(ngModel)]="selectFilter.operatorModel">
                    <mat-option *ngFor="let oprator of selectFilter.operators" value="{{oprator.value}}">{{oprator.label}}</mat-option>
                </mat-select>
            </div>
            <div class="input-item">
                <span *ngIf="selectFilter.type === 'string'">
                    <input type="text" autocomplete="nope" matInput [(ngModel)]="selectFilter.valueSelectModel">
                </span>
                <span *ngIf="selectFilter.type === 'integer'">
                    <input type="text" autocomplete="nope" matInput [(ngModel)]="selectFilter.valueSelectModel">
                </span>
                <span *ngIf="selectFilter.type === 'date_past'">
                    <mat-form-field class="date-class">
                        <input matInput [matDatepicker]="picker" [(ngModel)]="selectFilter.valueSelectModel" disabled>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                      </mat-form-field>
                </span>
                <span *ngIf="selectFilter.type === 'list'" class="list-item">
                    <span *ngIf="!selectFilter.showMultiple" class="single-item">
                        <mat-select [(ngModel)]="selectFilter.valueSelectModel">
                            <mat-option *ngFor="let value of selectFilter.values" value="{{value.value}}">{{value.label}}</mat-option>
                        </mat-select>
                    </span>
                    <span *ngIf="selectFilter.showMultiple" class="select-span">
                        <select multiple class="multi-select" id="{{selectFilter.name}}">
                            <option *ngFor="let value of selectFilter.values" value="{{value.value}}">
                                {{value.label}}
                            </option>
                          </select>
                    </span>
                    <mat-icon class="mdi-18px fold-more" *ngIf="!selectFilter.showMultiple" fontSet="mdi" fontIcon="mdi-unfold-more-horizontal" (click)="showHideMultiple(selectFilter)"></mat-icon>
                    <mat-icon class="mdi-18px fold-more" *ngIf="selectFilter.showMultiple" fontSet="mdi" fontIcon="mdi-unfold-less-horizontal" (click)="showHideMultiple(selectFilter)"></mat-icon>
                </span>
                <span *ngIf="selectFilter.type === 'list_optional'" class="list-item">
                    <span *ngIf="!selectFilter.showMultiple" class="single-item">
                        <mat-select [(ngModel)]="selectFilter.valueSelectModel">
                            <mat-option *ngFor="let value of selectFilter.values" value="{{value.value}}">{{value.label}}</mat-option>
                        </mat-select>
                    </span>
                    <span *ngIf="selectFilter.showMultiple" class="select-span">
                        <select multiple class="multi-select" id="{{selectFilter.name}}">
                            <option *ngFor="let value of selectFilter.values" value="{{value.value}}">
                                {{value.label}}
                            </option>
                          </select>
                    </span>
                    <mat-icon class="mdi-18px fold-more" *ngIf="!selectFilter.showMultiple" fontSet="mdi" fontIcon="mdi-unfold-more-horizontal" (click)="showHideMultiple(selectFilter)"></mat-icon>
                    <mat-icon class="mdi-18px fold-more" *ngIf="selectFilter.showMultiple" fontSet="mdi" fontIcon="mdi-unfold-less-horizontal" (click)="showHideMultiple(selectFilter)"></mat-icon>
                </span>
                <span *ngIf="selectFilter.type === 'float'">
                    <input type="text" autocomplete="nope" matInput [(ngModel)]="selectFilter.valueSelectModel">
                </span>
            </div>
            <mat-divider></mat-divider>
        </div>

    </div>

    <div class="mobile-show-filter" *ngIf="isShowFilter">
        <div class="back-drop" (click)="hideFilterOption()"></div>
        <div class="filters">
            <div class="filter-options" *ngFor="let filter of filterLists" (click)="addFilter(filter)" [class.is-selected-item]="isFilterAvailable(filter)">
                <span *ngIf="filter.name === 'first_name'">{{ 'FILTER_FIRST_NAME' | translate }}</span>
                <span *ngIf="filter.name === 'last_name'">{{ 'FILTER_LAST_NAME' | translate }}</span>
                <span *ngIf="filter.name === 'middle_name'">{{ 'FILTER_MIDDLE_NAME' | translate }}</span>
                <span *ngIf="filter.name === 'updated_at'">{{ 'FILTER_UPDATE_DATE' | translate }}</span>
                <span *ngIf="filter.name === 'deleted'">{{ 'FILTER_DELETED' | translate }}</span>
                <span *ngIf="filter.name === 'deleted_at'">{{ 'FILTER_DELETE_ON' | translate }}</span>
                <span *ngIf="filter.name === 'all'">{{ 'FILTER_ALL' | translate }}</span>
                <span *ngIf="filter.name === 'q'">{{ 'FILTER_FULL_TEXT' | translate }}</span>
                <span *ngIf="filter.name === 'dpt'">{{ 'FILTER_IN_MY_DEPARTMENT' | translate }}</span>
                <span *ngIf="filter.name === 'contact_list'">{{ 'FILTER_CONTACT_LIST' | translate }}</span>
                <span *ngIf="filter.name === 'contact_group'">{{ 'FILTER_CONTACT_GROUP' | translate }}</span>
                <span *ngIf="filter.name === 'tags'">{{ 'FILTER_TAGS' | translate }}</span>
                <span *ngIf="filter.name === 'id'">{{ 'FILTER_ID' | translate }}</span>
                <span *ngIf="filter.name === 'jid'">{{ 'FILTER_JID' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.city'">{{ 'FILTER_RELATED_USER_CITY' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.nationality'">{{ 'COUNTRY' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.gender'">{{ 'FILTER_RELATED_USER_GENDER' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.admin'">{{ 'FILTER_RELATED_USER_ADMIN' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.login'">{{ 'FILTER_RELATED_USER_LOGIN' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.agb_accepted'">{{ 'FILTER_RELATED_USER_AGB_ACCEPTED' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.marital_status'">{{ 'FILTER_RELATED_USER_MARITAL_STATUS' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.vnc_employee'">{{ 'FILTER_RELATED_USER_VNC_EMPLOYEE' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.freelancer'">{{ 'FILTER_RELATED_USER_FREELANCER' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.start_date'">{{ 'FILTER_RELATED_USER_START_DATE' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.end_date'">{{ 'FILTER_RELATED_USER_END_DATE' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.per_week_availability'">{{ 'FILTER_RELATED_USER_PER_WEEK_AVAIALABILITY' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.hourly_rate'">{{ 'FILTER_RELATED_USER_HOURLY_RATE' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.payment_mode'">{{ 'FILTER_RELATED_PAYMENT_MODE' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.skills'">{{ 'FILTER_RELATED_SKILLS' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.interests'">{{ 'FILTER_RELATED_INTEREST' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.labels'">{{ 'FILTER_RELATED_LABELS' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.video_bridge'">{{ 'VIDEO_BRIDGE_LBL' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.omemo'">{{ 'OMEMO' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.paid_subscription'">{{ 'PAID_SUBSCRIPTION' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.created_on'">{{ 'CREATED' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.last_login_on'">{{ 'LAST_CONNECTION' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.tfa_enabled'">{{ '2FA_ENABLED' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.organization'">{{ 'ORGANIZATION_LBL' | translate }}</span>
                <span *ngIf="filter.name === 'related_user.status'">{{ 'STATUS' | translate }}</span>
            </div>
        </div>
    </div>
    <div class="footer">
        <button mat-button class="add-filter" (click)="showFilterOption()">
            {{ 'ADD_FILTER' | translate }}
        </button>
        <button mat-button (click)="close()" class="cancel">
            {{ 'CANCEL' | translate }}
        </button>
        <button mat-button class="search" (click)="search()">
            {{ 'HEADER_SEARCH_PLACEHOLDER' | translate }}
        </button>
    </div>
</div>

<mat-menu #saveSearchMenu="matMenu" [class]="'contact-plus-advance-search-mat-menu'" [xPosition]="'after'">
    <div mat-menu-item *ngIf="!!saveSearch && saveSearch.length === 0">
        <div class="item">
            <div>
                {{ 'NOT_SAVE_SEARCH_YET' | translate }}
            </div>
            <div>
                <mat-icon class="mdi-12px" fontSet="mdi" fontIcon="mdi-informationx"></mat-icon>
            </div>
        </div>
    </div>
    <div mat-menu-item *ngFor="let search of saveSearch">
        <div class="item" (click)="loadSaveSearch(search)">
            <div>
                {{ search.name }}
            </div>
            <div>
                <mat-icon class="mdi-12px" fontSet="mdi" fontIcon="mdi-information"></mat-icon>
            </div>
        </div>
    </div>
</mat-menu>
    