<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
<div class="contact-dialog">
    <div class="contact__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{ 'RECENT_LBL' | translate }}
        </div>

        <div class="desktop-close-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="contact__dialog-body contact__dialog-body--pad">
        <div class="content">
            <div class="recent-activity" (touchstart)="$event.stopPropagation();recentActivityswipe($event, 'start')" (touchend)="$event.stopPropagation();recentActivityswipe($event, 'end')">
                <mat-tab-group [(selectedIndex)]="recentActivitySelectedTab" (selectedTabChange)="onSelectedTabChangeActivity($event)">
                    <mat-tab label="{{ 'ALL' | translate }}">
                        <div class="recent-contact-tab">
                            <div class="recent-items" *ngIf="!!searchResponse && searchResponse.docs.length > 0">
                                <div class="recent-item" *ngFor="let item of searchResponse.docs" (click)="openRecentActivity(item)">
                                    <div class="recen-item-inner" *ngIf="item.type === 'mail' || item.type === 'issue' || (item.type === 'talk' && !!item.chatType && ( item.chatType === 'chat' || item.chatType === 'call'))">
                                        <div class="left">
                                            <div class="avatar-item">
                                                <vp-avatar
                                                    [user]="{firstLastCharacters: item.from.charAt(0), color: getBgAvatarColor(item.from), avatarURL: getAvatarUrl(item.from)}">
                                                </vp-avatar>
                                            </div>
                                        </div>
                                        <div class="right">
                                            <div class="title">
                                                <span class="title-content" *ngIf="item.type === 'mail' || item.type === 'issue'">{{ item.title}}</span>
                                                <span class="title-content" *ngIf="item.type === 'talk' && !!item.chatType && ( item.chatType === 'chat' || item.chatType === 'call')">{{ item.contentTxt}}</span>
                                                <span class="item-type"> •
                                                    <span *ngIf="item.type === 'mail'">
                                                        <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-email"></mat-icon>
                                                        {{ 'EMAIL_LBL' | translate }}
                                                    </span>
                                                    <span *ngIf="item.type === 'talk' && !!item.chatType && item.chatType === 'chat'">
                                                        <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-message"></mat-icon>
                                                        {{'CHAT_LEBALE' | translate }}
                                                    </span>
                                                    <span *ngIf="item.type === 'talk' && !!item.chatType && item.chatType === 'call'">
                                                        <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-video"></mat-icon>
                                                        {{'CALL_LBL' | translate }}
                                                    </span>
                                                    <span *ngIf="item.type === 'issue' && !!item.issue_type && item.issue_type === 'task'">
                                                        <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-clipboard"></mat-icon>
                                                        {{'TASK' | translate }}
                                                    </span>
                                                    <span *ngIf="item.type === 'issue' && !!item.issue_type && item.issue_type === 'ticket'">
                                                        <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-ticket"></mat-icon>
                                                        {{'TICKET' | translate }}
                                                    </span>
                                                    <span *ngIf="item.type === 'issue' && !!item.issue_type && item.issue_type === 'incident'">
                                                        <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-clipboard-alert"></mat-icon>
                                                        {{'INCIDENT' | translate }}
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="time">
                                                 {{ item.createdDt | date: "mediumDate"}} <span class="time-hours"> • {{ item.createdDt | date: "hh:mm a"}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <mat-divider *ngIf="item.type === 'mail' || item.type === 'issue' || (item.type === 'talk' && !!item.chatType && ( item.chatType === 'chat' || item.chatType === 'call'))"></mat-divider>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{ 'CHATS' | translate }}">
                        <div class="recent-contact-tab">
                            <div class="recent-items" *ngIf="!!searchResponse && searchResponse.docs.length > 0">
                                <div class="recent-item" *ngFor="let item of searchResponse.docs" (click)="openRecentActivity(item)">
                                    <span *ngIf="item.type === 'talk' && !!item.chatType && item.chatType === 'chat'">
                                        <div class="recen-item-inner">
                                            <div class="left">
                                                <div class="avatar-item">
                                                    <vp-avatar
                                                        [user]="{firstLastCharacters: item.from.charAt(0), color: getBgAvatarColor(item.from), avatarURL: getAvatarUrl(item.from)}">
                                                    </vp-avatar>
                                                </div>
                                            </div>
                                            <div class="right">
                                                <div class="title">
                                                    <span class="title-content">{{ item.contentTxt}}</span>
                                                    <span class="item-type"> •
                                                        <span *ngIf="item.type === 'talk' && !!item.chatType && item.chatType === 'chat'">
                                                            <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-message"></mat-icon>
                                                            {{'CHAT_LEBALE' | translate }}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="time">
                                                    {{ item.createdDt | date: "mediumDate"}} <span class="time-hours"> • {{ item.createdDt | date: "hh:mm a"}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{ 'CALLS' | translate }}">
                        <div class="recent-contact-tab">
                            <div class="recent-items" *ngIf="!!searchResponse && searchResponse.docs.length > 0">
                                <div class="recent-item" *ngFor="let item of searchResponse.docs" (click)="openRecentActivity(item)">
                                    <span *ngIf="item.type === 'talk' && !!item.chatType && item.chatType === 'call'">
                                        <div class="recen-item-inner">
                                            <div class="left">
                                                <div class="avatar-item">
                                                    <vp-avatar
                                                        [user]="{firstLastCharacters: item.from.charAt(0), color: getBgAvatarColor(item.from), avatarURL: getAvatarUrl(item.from)}">
                                                    </vp-avatar>
                                                </div>
                                            </div>
                                            <div class="right">
                                                <div class="title">
                                                    <span class="title-content">{{ item.contentTxt}}</span>
                                                    <span class="item-type"> •
                                                        <span *ngIf="item.type === 'talk' && !!item.chatType && item.chatType === 'call'">
                                                            <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-video"></mat-icon>
                                                            {{'CALL_LBL' | translate }}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="time">
                                                    {{ item.createdDt | date: "mediumDate"}} <span class="time-hours"> • {{ item.createdDt | date: "hh:mm a"}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{ 'EMAILS' | translate }}">
                        <div class="recent-contact-tab">
                            <div class="recent-items" *ngIf="!!searchResponse && searchResponse.docs.length > 0">
                                <div class="recent-item" *ngFor="let item of searchResponse.docs" (click)="openRecentActivity(item)">
                                    <span *ngIf="item.type === 'mail'">
                                        <div class="recen-item-inner">
                                            <div class="left">
                                                <div class="avatar-item">
                                                    <vp-avatar
                                                        [user]="{firstLastCharacters: item.from.charAt(0), color: getBgAvatarColor(item.from), avatarURL: getAvatarUrl(item.from)}">
                                                    </vp-avatar>
                                                </div>
                                            </div>
                                            <div class="right">
                                                <div class="title">
                                                    <span class="title-content">{{ item.title}}</span>
                                                    <span class="item-type"> •
                                                        <span *ngIf="item.type === 'mail'">
                                                            <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-email"></mat-icon>
                                                            {{ 'EMAIL_LBL' | translate }}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="time">
                                                    {{ item.createdDt | date: "mediumDate"}} <span class="time-hours"> • {{ item.createdDt | date: "hh:mm a"}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{ 'TASKS' | translate }}" *ngIf="isShowTaskOption">
                        <div class="recent-contact-tab">
                            <div class="recent-items" *ngIf="!!searchResponse && searchResponse.docs.length > 0">
                                <div class="recent-item" *ngFor="let item of searchResponse.docs" (click)="openRecentActivity(item)">
                                    <span *ngIf="item.type === 'issue' && !!item.issue_type && item.issue_type === 'task'">
                                        <div class="recen-item-inner">
                                            <div class="left">
                                                <div class="avatar-item">
                                                    <vp-avatar
                                                        [user]="{firstLastCharacters: item.from.charAt(0), color:  getBgAvatarColor(item.from), avatarURL: getAvatarUrl(item.from)}">
                                                    </vp-avatar>
                                                </div>
                                            </div>
                                            <div class="right">
                                                <div class="title">
                                                    <span class="title-content">{{ item.title}}</span>
                                                    <span class="item-type"> •
                                                        <span *ngIf="item.type === 'issue' && !!item.issue_type && item.issue_type === 'task'">
                                                            <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-clipboard"></mat-icon>
                                                            {{'TASK' | translate }}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="time">
                                                    {{ item.createdDt | date: "mediumDate"}} <span class="time-hours"> • {{ item.createdDt | date: "hh:mm a"}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{ 'TICKETS' | translate }}" *ngIf="isShowTicketOption">
                        <div class="recent-contact-tab">
                            <div class="recent-items" *ngIf="!!searchResponse && searchResponse.docs.length > 0">
                                <div class="recent-item" *ngFor="let item of searchResponse.docs" (click)="openRecentActivity(item)">
                                    <span *ngIf="item.type === 'issue' && !!item.issue_type && item.issue_type === 'ticket'">
                                        <div class="recen-item-inner">
                                            <div class="left">
                                                <div class="avatar-item">
                                                    <vp-avatar
                                                        [user]="{firstLastCharacters: item.from.charAt(0), color:  getBgAvatarColor(item.from), avatarURL: getAvatarUrl(item.from)}">
                                                    </vp-avatar>
                                                </div>
                                            </div>
                                            <div class="right">
                                                <div class="title">
                                                    <span class="title-content">{{ item.title}}</span>
                                                    <span class="item-type"> •
                                                        <span *ngIf="item.type === 'issue' && !!item.issue_type && item.issue_type === 'ticket'">
                                                            <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-ticket"></mat-icon>
                                                            {{'TICKET' | translate }}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="time">
                                                    {{ item.createdDt | date: "mediumDate"}} <span class="time-hours"> • {{ item.createdDt | date: "hh:mm a"}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{ 'INCIDENTS' | translate }}" *ngIf="isShowTicketOption">
                        <div class="recent-contact-tab">
                            <div class="recent-items" *ngIf="!!searchResponse && searchResponse.docs.length > 0">
                                <div class="recent-item" *ngFor="let item of searchResponse.docs" (click)="openRecentActivity(item)">
                                    <span *ngIf="item.type === 'issue' && !!item.issue_type && item.issue_type === 'incident'">
                                        <div class="recen-item-inner">
                                            <div class="left">
                                                <div class="avatar-item">
                                                    <vp-avatar
                                                        [user]="{firstLastCharacters: item.from.charAt(0), color:  getBgAvatarColor(item.from), avatarURL: getAvatarUrl(item.from)}">
                                                    </vp-avatar>
                                                </div>
                                            </div>
                                            <div class="right">
                                                <div class="title">
                                                    <span class="title-content">{{ item.title}}</span>
                                                    <span class="item-type"> •
                                                        <span *ngIf="item.type === 'issue' && !!item.issue_type && item.issue_type === 'incident'">
                                                            <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-clipboard-alert"></mat-icon>
                                                            {{'INCIDENT' | translate }}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="time">
                                                    {{ item.createdDt | date: "mediumDate"}} <span class="time-hours"> • {{ item.createdDt | date: "hh:mm a"}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>