
<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-contact-image-cropper-dialog contact-dialog">
    <div class="contact__dialog-header">
        <div class="header_lbl disable-select">
            {{'SELECT_PHOTO' | translate }}
        </div>
        <div class="desktop-close-button">
            <button mat-button (click)="rotateLeft()">
                <mat-icon class="mdi-20px gray-color" fontSet="mdi" fontIcon="mdi-rotate-left"></mat-icon>
            </button>
            <button mat-button (click)="rotateRight()">
                <mat-icon class="mdi-20px gray-color" fontSet="mdi" fontIcon="mdi-rotate-right"></mat-icon>
            </button>
        </div>
        <div class="mobile-back-button">
            <button mat-button (click)="rotateLeft()">
                <mat-icon class="mdi-20px gray-color" fontSet="mdi" fontIcon="mdi-rotate-left"></mat-icon>
            </button>
        </div>
        <div class="mobile-back-button">
            <button mat-button (click)="rotateRight()">
                <mat-icon class="mdi-20px gray-color" fontSet="mdi" fontIcon="mdi-rotate-right"></mat-icon>
            </button>
        </div>
        <div class="close-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="contact__dialog-body">
            <div class="user-profile__upload-avatar">
                <div id="cropper-image" class="cropper-image" *ngIf="showImage">
                    <img [src]="userAvatarURL" validate="never" (error)="imgLoadOnError()"/>
                </div>
                <div id="cropper-image" class="cropper-image" [ngStyle]="{'display': showImage ? 'none' : 'flex'}">
                    <div *ngIf="!showImage && noAvatar" class="no-avatar-item">
                        <div class="icon-div">
                            <mat-icon>person</mat-icon>
                        </div>
                    </div>
                    <span *ngIf="!isExistingImage" [ngStyle]="{'display': !showImage && !noAvatar ? 'inline' : 'none'}">
                        <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [containWithinAspectRatio]="containWithinAspectRatio"
                        [aspectRatio]="1/1"
                        [resizeToWidth]="300"
                        [cropperMinWidth]="100"
                        [cropperMinHeight]="100"
                        [onlyScaleDown]="false"
                        format="png"
                        [autoCrop]="true"
                        [roundCropper]="true"
                        [transform]="transform"
                        [alignImage]="'center'"
                        [canvasRotation]="canvasRotation"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"
                        ></image-cropper>    
                    </span>
                    <span *ngIf="isExistingImage" class="preview-img">
                        <img [src]="croppedImage" alt="Preview"/>
                    </span>
                </div>
            </div>
            <input id="custom-file-input" #cropFileChooser type="file" class="hide"
                (click)="cropFileChooser.value = null;" (change)="fileChangeListener($event)" accept="image/*">
    </div>
    <div class="contact__dialog-footer">
        <div class="footer-buttons" *ngIf="noAvatar">
            <button mat-button (click)="cropFileChooser.click()">
                {{'UPLOAD'|translate}}
            </button>
        </div>
        <div class="footer-buttons" *ngIf="!noAvatar">
            <button mat-button *ngIf="showRemove" (click)="removeUserAvtar()">
                {{'REMOVE'|translate}}
            </button>
            <button mat-button (click)="cropFileChooser.click()">
                {{'RE_UPLOAD'|translate}}
            </button>
            <button mat-button [disabled]="!isFileUploded" (click)="saveCropperImge()">
               {{ 'DONE' | translate }}
            </button>
        </div>
    </div>
</div>
<!-- <div class="action-notifier disable-select" *ngIf="isMobileView && isFileUploded">
    <div class="notifiler-title disable-select">
        {{ 'SAVE_PHOTO_NOTIFIER' | translate }}
    </div>
</div> -->
<!-- <div class="app-disabled" *ngIf="isLoading && isIos">
    <vp-custom-spinner></vp-custom-spinner>
</div> -->