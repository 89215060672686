
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, NgZone, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { Subject } from "rxjs";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ConfigService } from "src/app/common/providers/config.service";
import { CommonUtil } from "src/app/common/utils/common.utils";
import { ToastService } from "src/app/common/service/tost.service";
import { UndoChangesDialogComponent } from "src/app/contacts/components/undo-changes-dialog/undo-changes-dialog.component";
import { distinctUntilChanged, takeUntil, take } from "rxjs/operators";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogType } from "src/app/common/models/dialog.model";
import { ContactRepository } from "src/app/contacts/repository/contact.repository";
import { ContactRootState } from "src/app/contacts/store";
import { Store } from "@ngrx/store";
import { getFederatedApps, getOnlineStatus } from "src/app/reducers";
import { ExportContactDialogComponent } from "src/app/contacts/components/export-contact-dialog/export-contact-dialog.component";
import { ImportContactDialogComponent } from "src/app/contacts/components/import-contact-dialog/import-contact-dialog.component";

@Component({
  selector: "vp-mobile-actions-dialog",
  templateUrl: "./mobile-actions-dialog.component.html"
})
export class MobileActionsDialogComponent implements OnInit, OnDestroy {

  private isAlive$ = new Subject<boolean>();
  private isOnline: boolean;
  changeLog: string;
  isSyncVNCmailContacts: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<MobileActionsDialogComponent>,
    private matDialog: MatDialog,
    private mailBroadcaster: Broadcaster,
    private ngZone: NgZone,
    private configService: ConfigService,
    private toastService: ToastService,
    private contactRepo: ContactRepository,
    private changeDetectorRef: ChangeDetectorRef,
    private appStore: Store<ContactRootState>,
  ) {
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_MOBILE_ACTIONS_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });

    this.appStore.select(getOnlineStatus).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(isOnline => {
      this.isOnline = isOnline;
    });

    this.appStore.select(getFederatedApps).pipe(takeUntil(this.isAlive$)).subscribe(apps => {
      if (apps) {
        apps.forEach(product => {
          if (product.name === "vncmail") {
            this.isSyncVNCmailContacts = true;
            this.changeDetectorRef.markForCheck();
          }
        });
      }
    });
  }

  ngOnInit() {
    if (CommonUtil.isOnIOS()) {
      setTimeout(() => {
        document.querySelector(".cdk-global-overlay-wrapper").removeAttribute("style");
      }, 50);
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  close(): void {
    this.dialogRef.close();
  }

  openExportDialog(): void {
    if (!this.isOnline) {
        this.toastService.show("APP_OFFLINE");
        return;
    }

    this.matDialog.open(ExportContactDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "export_contact_dialog"
    });
  }

  futureDevelopmentMessage(): void {
    this.toastService.show("COMING_SOON");
  }

  openImportDialog(): void {
    if (!this.isOnline) {
      this.toastService.show("APP_OFFLINE");
      return;
    }

    this.matDialog.open(ImportContactDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "import_contact_dialog"
    });
  }

  openUndoDialog(): void {
    if (!this.isOnline) {
      this.toastService.show("APP_OFFLINE");
      return;
    }

    this.matDialog.open(UndoChangesDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "undo_changes_dialog"
    });
  }

  openPrintDialog(): void {
    this.futureDevelopmentMessage();
  }

  syncZimbraContacts() {
    if (!this.isOnline) {
      this.toastService.show("APP_OFFLINE");
      return;
    }

    const dlg = this.matDialog.open(ConfirmationDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "confirm_contactplus_dialog",
      data : { dialogType: ConfirmDialogType.SYNC_CONTACTS, message_title: "SYNC_MAIL_CONTACTS", message_body: "CONFIRM_SYNC_MAIL_CONTACTS"}
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
        if (!!res && res.confirmation) {
            if (res.confirmation === "yes") {
              this.contactRepo.syncZimbraContacts();
              this.close();
            }
        }
    });
  }
}
