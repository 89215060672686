
<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="contact__dialog">
    <div class="contact__dialog-header">
        <div class="header_lbl disable-select">
            {{ message_title | translate }}
        </div>
    </div>
    <div class="contact__dialog-body">
            <div class="content">
                <span>{{ message_body | translate: {'total': total, 'duplicateTag': duplicateTag | translate} }}</span>
            </div>
            <p *ngIf="isInTrash" class="inversable">
                {{ 'OPERATION_IS_INVERSABLE' | translate }}
            </p>
    </div>
    <div class="contact__dialog-footer">
        <div class="buttons-div" *ngIf="confirmationType === dialogType.SAVE_CONFIRMATION">
            <button mat-button (click)="close('no')">
                {{ 'DISCARD' | translate }}
            </button>
            <button mat-button (click)="close('yes')">
                {{ 'SAVE' | translate }}
            </button>
        </div>
        <div class="buttons-div" *ngIf="confirmationType === dialogType.SYNC_CONTACTS">
            <button mat-button (click)="close('no')">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button (click)="close('yes')">
                {{ 'IMPORT_LABEL' | translate }}
            </button>
        </div>
        <div class="buttons-div" *ngIf="confirmationType === dialogType.ADVANCE_SEARCH_REMOVE || confirmationType === dialogType.DUPLICATE_CONTACT">
            <button mat-button (click)="close('no')">
                {{ 'NO' | translate }}
            </button>
            <button mat-button (click)="close('yes')">
                {{ 'YES' | translate }}
            </button>
        </div>
        <div class="buttons-div" *ngIf="(confirmationType !== dialogType.DUPLICATE_CONTACT  && confirmationType !== dialogType.SAVE_CONFIRMATION && confirmationType !== dialogType.SYNC_CONTACTS && confirmationType !== dialogType.ADVANCE_SEARCH_REMOVE)">
            <button mat-button (click)="close('no')">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button (click)="close('yes')">
                {{ 'DELETE' | translate }}
            </button>
        </div>
    </div> 
</div>
