
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, NgZone, ChangeDetectorRef } from "@angular/core";
import { Subject } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { takeUntil } from "rxjs/operators";
import { ToastService } from "src/app/common/service/tost.service";
import { CommonUtil } from "src/app/common/utils/common.utils";
import * as moment from "moment";
import { ContactRepository } from "../../repository/contact.repository";

@Component({
    selector: "vp-contact-undo-changes-dialog",
    templateUrl: "./undo-changes-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UndoChangesDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    undoChangeTime: string = "10m";
    isChangeValue: boolean = false;
    isOnMobileDevice: boolean = CommonUtil.isOnMobileDevice();
    days: string = "";
    hours: string = "";
    minutes: string = "";
    showTimePeriodError: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<UndoChangesDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private changeDetectionRef: ChangeDetectorRef,
        private toastService: ToastService,
        private repository: ContactRepository
    ) {
    }

    ngOnInit(): void {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_UNDO_CHANGES_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    confirmChange(): void {
        const PARSE_FORMAT = "YYYY-MM-DDTHH:mm";
        const utcTime = new Date(Date.now() + (new Date().getTimezoneOffset() * 60000)).getTime();
        const todayDate: Date = new Date(utcTime);
        let sentDate: string = "";
        const changeDialog: HTMLElement = <HTMLElement>document.querySelector(".undo_changes_dialog");
        this.showTimePeriodError = false;
        this.changeDetectionRef.markForCheck();
        switch (this.undoChangeTime) {
            case "10m":
                sentDate = moment(todayDate).subtract(10, "minutes").format(PARSE_FORMAT);
                break;
            case "1h":
                sentDate = moment(todayDate).subtract(1, "hours").format(PARSE_FORMAT);
                break;
            case "yesterday":
                sentDate = moment(todayDate).subtract(1, "days").format(PARSE_FORMAT);
                break;
            case "1w":
                sentDate = moment(todayDate).subtract(1, "weeks").format(PARSE_FORMAT);
                break;
            case "custom":
                let customDate: Date = new Date(utcTime);
                if (this.days !== "") {
                    customDate = moment(customDate).subtract(parseInt(this.days, 10), "days").toDate();
                }
                if (this.hours !== "") {
                    customDate = moment(customDate).subtract(parseInt(this.hours, 10), "hours").toDate();
                }
                if (this.minutes !== "") {
                    customDate  = moment(customDate).subtract(parseInt(this.minutes, 10), "minutes").toDate();
                }
                if (this.days !== "" || this.hours !== "" || this.minutes !== "") {
                    sentDate = moment(customDate).format(PARSE_FORMAT);
                    if (changeDialog !== null && !this.isOnMobileDevice) {
                        changeDialog.style.height = "475px";
                    }
                } else {
                    this.showTimePeriodError = true;
                    if (changeDialog !== null && !this.isOnMobileDevice) {
                        changeDialog.style.height = "510px";
                    }
                    this.changeDetectionRef.markForCheck();
                }
                break;
        }
        console.log("[confirmChange][sendDate]: ", sentDate + "Z");
        if (sentDate !== "") {
            this.repository.undoChangeContact(sentDate + "Z");
        }
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    changeRadioButton(): void {
        console.log("[changeRadioButton]: ", this.undoChangeTime);
        const changeDialog: HTMLElement = <HTMLElement>document.querySelector(".undo_changes_dialog");
        if (this.undoChangeTime === "custom") {
            if (changeDialog !== null && !this.isOnMobileDevice) {
                changeDialog.style.height = "475px";
            }
        } else {
            this.showTimePeriodError = false;
            if (changeDialog !== null) {
                changeDialog.removeAttribute("style");
            }
        }
        this.isChangeValue = true;
        this.changeDetectionRef.markForCheck();
    }
}
