
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { Update } from "@ngrx/entity/src/models";
import { AppActionTypes } from "../../../actions/app";
import { Contact } from "src/app/common/models";

export class ListContactsAcTypes extends AppActionTypes {
// load Contacts
static LOAD_LIST_CONTACTS = "[Contacts] Load list Contacts";
static LOAD_LIST_CONTACTS_FAIL = "[Contacts] Load list Contacts Fail";
static LOAD_LIST_CONTACTS_SUCCESS = "[Contacts] Load list Contacts Success";

// load nex Contacts
static NEXT_LOAD_LIST_CONTACTS = "[Contacts] Next Load list Contacts";
static NEXT_LOAD_LIST_CONTACTS_FAIL = "[Contacts] Next Load list Contacts Fail";
static NEXT_LOAD_LIST_CONTACTS_SUCCESS = "[Contacts] Next Load list Contacts Success";

// create Contact
static CREATE_LIST_CONTACT = "[Contacts] Create list Contact";
static CREATE_LIST_CONTACT_FAIL = "[Contacts] Create list Contact Fail";
static CREATE_LIST_CONTACT_SUCCESS = "[Contacts] Create list Contact Success";
// update Contact
static UPDATE_LIST_CONTACT = "[Contacts] Update list Contact";
static UPDATE_LIST_CONTACT_FAIL = "[Contacts] Update list Contact Fail";
static UPDATE_LIST_CONTACT_SUCCESS = "[Contacts] Update list Contact Success";
// remove Contact
static REMOVE_LIST_CONTACT = "[Contacts] Remove list Contact";
static REMOVE_LIST_CONTACT_FAIL = "[Contacts] Remove list Contact Fail";
static REMOVE_LIST_CONTACT_SUCCESS = "[Contacts] Remove list Contact Success";

static SEARCH_TOGGLE = "[Contacts] Toggle search mode";
static REMOVE_MANY_LIST_CONTACT_SUCCESS = "[Contacts] Remove Many list Contact Success";

// remove Contact
static RESTORE_LIST_CONTACT = "[Contacts] Restore list Contact";
static RESTORE_LIST_CONTACT_FAIL = "[Contacts] Restore list Contact Fail";
static RESTORE_LIST_CONTACT_SUCCESS = "[Contacts] Restore list Contact Success";

static START_LIST_LOADING = "[Contacts] Start list Loading";
static STOP_LIST_LOADING = "[Contacts] Stop list Loading";

static STORE_LIST_SEARCHPARAMS = "[Contacts] Store list search parameter";

static EMPTY_LIST_CONTACTS_SUCCESS = "[Contacts] Empty list Contacts";

static UPDATE_MANY_LIST_CONTACT_SUCCESS = "[Contacts] Update Many list Contact Success";

}

export class LoadListContacts implements Action {
  readonly type = ListContactsAcTypes.LOAD_LIST_CONTACTS;
}

export class SetSearchMode implements Action {
  readonly type = ListContactsAcTypes.SEARCH_TOGGLE;
  constructor(public payload: boolean) { }
}

export class StoreListSearchParams implements Action {
  readonly type = ListContactsAcTypes.STORE_LIST_SEARCHPARAMS;
  constructor(public payload: any) { }
}

export class LoadListContactsFail implements Action {
  readonly type = ListContactsAcTypes.LOAD_LIST_CONTACTS_FAIL;
}

export class LoadListContactsSuccess implements Action {
  readonly type = ListContactsAcTypes.LOAD_LIST_CONTACTS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreContacts: boolean, contact: Contact[] }) { }
}

export class NextLoadListContacts implements Action {
  readonly type = ListContactsAcTypes.NEXT_LOAD_LIST_CONTACTS;
}

export class NextLoadListContactsFail implements Action {
  readonly type = ListContactsAcTypes.NEXT_LOAD_LIST_CONTACTS_FAIL;
}

export class NextLoadListContactsSuccess implements Action {
  readonly type = ListContactsAcTypes.NEXT_LOAD_LIST_CONTACTS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreContacts: boolean, contacts: Contact[] }) { }
}

export class CreateListContact implements Action {
  readonly type = ListContactsAcTypes.CREATE_LIST_CONTACT;
}

export class CreateListContactFail implements Action {
  readonly type = ListContactsAcTypes.CREATE_LIST_CONTACT_FAIL;
}

export class CreateListContactSuccess implements Action {
  readonly type = ListContactsAcTypes.CREATE_LIST_CONTACT_SUCCESS;
  constructor(public payload: Contact) { }
}

export class UpdateListContact implements Action {
  readonly type = ListContactsAcTypes.UPDATE_LIST_CONTACT;
}

export class UpdateListContactFail implements Action {
  readonly type = ListContactsAcTypes.UPDATE_LIST_CONTACT_FAIL;
}

export class UpdateListContactSuccess implements Action {
  readonly type = ListContactsAcTypes.UPDATE_LIST_CONTACT_SUCCESS;
  constructor(public payload: Update<Contact>) { }
}

export class RemoveListContact implements Action {
  readonly type = ListContactsAcTypes.REMOVE_LIST_CONTACT;
}

export class RemoveListContactFail implements Action {
  readonly type = ListContactsAcTypes.REMOVE_LIST_CONTACT_FAIL;
}

export class RemoveListContactSuccess implements Action {
  readonly type = ListContactsAcTypes.REMOVE_LIST_CONTACT_SUCCESS;
  constructor(public payload: string) { }
}

export class RemoveMultipleListContactSuccess implements Action {
  readonly type = ListContactsAcTypes.REMOVE_MANY_LIST_CONTACT_SUCCESS;
  constructor(public payload: string[]) { }
}

export class EmptyListContactSuccess implements Action {
  readonly type = ListContactsAcTypes.EMPTY_LIST_CONTACTS_SUCCESS;
  constructor() { }
}

export class RestoreListContact implements Action {
  readonly type = ListContactsAcTypes.RESTORE_LIST_CONTACT;
}

export class RestoreListContactFail implements Action {
  readonly type = ListContactsAcTypes.RESTORE_LIST_CONTACT_FAIL;
}

export class RestoreListContactSuccess implements Action {
  readonly type = ListContactsAcTypes.RESTORE_LIST_CONTACT_SUCCESS;
  constructor(public payload: Contact[]) { }
}

export class StartListLoading implements Action {
  readonly type = ListContactsAcTypes.START_LIST_LOADING;
}

export class StopListLoading implements Action {
  readonly type = ListContactsAcTypes.STOP_LIST_LOADING;
}

export class UpdateMultipleListContactSuccess implements Action {
  readonly type = ListContactsAcTypes.UPDATE_MANY_LIST_CONTACT_SUCCESS;
  constructor(public payload: Update<Contact>[]) { }
}


export type ContactsAction =
  | LoadListContacts
  | LoadListContactsFail
  | LoadListContactsSuccess
  | NextLoadListContacts
  | NextLoadListContactsFail
  | NextLoadListContactsSuccess
  | CreateListContact
  | CreateListContactFail
  | CreateListContactSuccess
  | UpdateListContact
  | UpdateListContactFail
  | UpdateListContactSuccess
  | RemoveListContact
  | RemoveListContactFail
  | RemoveListContactSuccess
  | RemoveMultipleListContactSuccess
  | SetSearchMode
  | RestoreListContact
  | RestoreListContactFail
  | RestoreListContactSuccess
  | StartListLoading
  | StopListLoading
  | StoreListSearchParams
  | EmptyListContactSuccess
  | UpdateMultipleListContactSuccess;
