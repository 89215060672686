
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy,
  ElementRef, ViewChild, NgZone, AfterViewInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { ColorControlDialogComponent } from "../color-control-dialog/color-control-dialog.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ContactRepository } from "../../repository/contact.repository";
import { ToastService } from "src/app/common/service/tost.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ContactFolder } from "../../models/create-folder.model";

@Component({
  selector: "vp-create-folder",
  templateUrl: "./create-folder.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class CreateFolderComponent implements OnDestroy, AfterViewInit {

  folderTitle: string = "";
  maxFolderTitleLength: number = 128;
  isRenameFolder: boolean = false;
  private isAlive$ = new Subject<boolean>();
  folderColor: string;
  folder: ContactFolder;
  @ViewChild("folderNameInput", {static: false}) folderNameInput: ElementRef<HTMLInputElement>;

  constructor(
    private dialogRef: MatDialogRef<CreateFolderComponent>,
    private contactRepo: ContactRepository,
    public toastService: ToastService,
    private changeDetectionRef: ChangeDetectorRef,
    private broadcaster: Broadcaster,
    private ngZone: NgZone,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isRenameFolder = this.data.isRename;
    if (this.isRenameFolder) {
      this.folderTitle = this.data.targetFolder.name;
      this.folder = this.data.targetFolder;
      if (this.folder.color_hex) {
        this.folderColor = this.folder.color_hex.toLowerCase();
        this.changeDetectionRef.markForCheck();
      }
    }

    this.broadcaster.on<any>(BroadcastKeys.HIDE_CREATE_CONTACT_FOLDER).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });

    setTimeout(() => {
      if (this.folderNameInput) {
        this.folderNameInput.nativeElement.focus();
      }
    }, 500);

    console.log("[CreateFolderComponent]");
    setTimeout(() => {
      this.changeDetectionRef.detectChanges();
    }, 50);
  }

  changeText(event) {
    if (this.folderTitle.length > this.maxFolderTitleLength) {
      this.folderTitle = this.folderTitle.substring(0, this.maxFolderTitleLength);
      this.changeDetectionRef.markForCheck();
    }
  }

  closeDialog(): void {
    this.close();
  }

  updateDefaultColor(event) {
    this.folderColor = event.value;
    this.changeDetectionRef.markForCheck();
  }

  folderAction(): void {
    if (this.folderTitle.length < 1) {
      return;
    }
    if (this.isRenameFolder) {
      this.folder.name = this.folderTitle;
      this.folder.color_hex = this.folderColor;
      this.contactRepo.updateContactList(this.folder);
    } else {
      this.contactRepo.createContactList(this.folderTitle, this.folderColor);
    }
    this.close();
  }

  openColorDialog() {
    const changeColorDialogRef = this.matDialog.open(ColorControlDialogComponent, {
      height: "auto",
      maxHeight: "70%",
      width: "99%",
      maxWidth: "244px",
      autoFocus: false,
      panelClass: "vp-color-control-dialog",
      data: { folderColor : this.folderColor }
    });
    changeColorDialogRef.afterClosed().subscribe(operation => {
      if (operation.selectedColor) {
        this.folderColor = operation.selectedColor;
      }
    });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  ngAfterViewInit() {
    this.changeDetectionRef.detectChanges();
  }

  close(): void {
    this.dialogRef.close();
  }
}
