
<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="contact-list-selection">
    <div class="header">
        <div class="header-title">{{'CHOOSE_LIST' | translate}}</div>
        <!-- <div>
            <button mat-button (click)="close()">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div> -->
    </div>

    <div class="dialog-body-data">
        <div *ngFor="let item of contactLists; index as index">
            <div class="list-item" (click)="selectUnselectItems(item)">
                <span class="list-title">{{item.name}}</span>
                <span *ngIf="item.selected">
                    <mat-icon>check</mat-icon>
                </span>
            </div>
        </div>
       
    </div>

    <div class="desktop-footer">
        <button mat-button (click)="close()">
            {{ 'CANCEL' | translate }}
        </button>
        <button mat-button (click)="onSelect()">
            {{ 'SAVE' | translate }}
        </button>
    </div>
</div>