
<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="contact-dialog">
        <div class="contact__dialog-header">
            <div class="mobile-back-button">
                <button mat-button (click)="close()">
                    <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
                </button>
            </div>
            <div class="header_lbl disable-select">
                {{ 'EXPORT_CONTACTS' | translate }}
            </div>
            <div class="desktop-close-button">
                <button mat-button (click)="close()">
                    <mat-icon class="disable-select">close</mat-icon>
                </button>
            </div>
        </div>
        <div class="contact__dialog-body">
            <div class="content">
                <div class="content-div">
                    <mat-radio-group [(ngModel)]="contact_filter">
                        <mat-radio-button [disabled]="disabledSelected" value="selected">{{ 'SELECTED_CONTACTS' | translate }}</mat-radio-button>
                        <mat-radio-button value="all" *ngIf="!hideContactOption">
                            <mat-form-field>
                                <mat-select [(ngModel)]="contact_type" (selectionChange)="contactItemSelect($event)">
                                    <mat-option value="contacts">{{ 'CONTACTS' | translate }}</mat-option>
                                    <mat-option value="global contacts">{{ 'GLOBAL_CONTACTS' | translate}}</mat-option>
                                    <mat-option value="all_contacts">{{ 'ALL_CONTACTS_LBL' | translate}}</mat-option>
                                    <mat-optgroup label="{{ 'LISTS_LBL' | translate }}" *ngIf="!!contactLists && contactLists.length > 0" class="export-group">
                                        <mat-option *ngFor="let list of contactLists" [value]="list.id">
                                            {{list.name}} ({{list.contacts_count}})
                                        </mat-option>
                                    </mat-optgroup>
                                    <mat-optgroup label="{{ 'TAGS' | translate }}" *ngIf="!!contactTags && contactTags.length > 0" class="export-group">
                                        <mat-option *ngFor="let tag of contactTags" [value]="tag.id">
                                            {{tag.name}} ({{tag.contacts_count}})
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-select>
                            </mat-form-field>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="content-div">
                    <div class="text-div"><span>{{ 'EXPORT_AS' | translate }}</span></div>
                    <mat-radio-group [(ngModel)]="export_type">
                            <mat-radio-button value="csv">{{ 'GOOGLE_CSV' | translate }}</mat-radio-button>
                            <mat-radio-button value="xls">{{ 'MICROSOFT_XLS' | translate }}</mat-radio-button>
                            <mat-radio-button value="vcf">{{ 'VCARD_FOR_IOS' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div class="contact__dialog-footer">
            <div class="desktop-footer">
                <button mat-button (click)="close()">
                    {{ 'CANCEL' | translate }}
                </button>
                <button mat-button (click)="exportContacts()">
                    {{ 'EXPORT' | translate }}
                </button>
            </div>
            <div class="mobile-footer">
                <div class="title">
                    {{ 'EXPORT' | translate }}
                </div>
                <div class="save_footer_btn">
                    <button mat-button (click)="exportContacts()">
                        <mat-icon>check</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>