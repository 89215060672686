
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Headers, Http } from "@angular/http";
import { Router } from "@angular/router";
import { ConfigService } from "./config.service";
import { environment } from "../../../environments/environment";
import { CommonUtil } from "../utils/common.utils";
import { map, take } from "rxjs/operators";
import { Observable, Subject } from "rxjs";

@Injectable()
export class AuthService {
  isCordovaOrElectron = environment.isCordova || environment.isElectron;
  private AUTH_TOKEN_KEY: string;
  constructor(private config: ConfigService,
              private router: Router,
              private http: Http) {
    this.AUTH_TOKEN_KEY = this.config.get("AUTH_TOKEN_KEY");
  }

  public getProfile() {
    let headers = new Headers({"Content-Type": "application/json"});
    if (this.isCordovaOrElectron) {
      headers = new Headers({"Content-Type": "application/json", "Authorization": localStorage.getItem("token")});
    }
    return navigator.onLine ? this.http.get(this.config.API_URL + "/api/profile", {headers: headers}).pipe(map(response => response.json())) : this.getStoredProfile();
  }

  logout() {
    return this.http.get(this.config.API_URL + "/api/call-logout", {});
  }

  addAuthHeader(headers: Headers): Headers {
    // Appends an authentication header to headers
    let authorization = localStorage.getItem(this.AUTH_TOKEN_KEY);
    if (authorization) {
      headers.append("Authorization", "Bearer " + authorization);
    }
    return headers;
  }

  setAuthToken(token: string) {
    localStorage.setItem(this.AUTH_TOKEN_KEY, token);
  }

  getAuthToken(): string {
    return localStorage.getItem(this.AUTH_TOKEN_KEY);
  }

  delAuthToken() {
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
  }

  isUserLoggedIn(): boolean {
    let apiKey = localStorage.getItem("RedmineApiKey");
    let redmineUrl = localStorage.getItem("REDMINE_URL");
    if (apiKey !== null && redmineUrl) {
      return true;
    }
    return false;
  }

  getStoredProfile(): Observable<any> {
    const response = new Subject<any>();
    try {
      const storedProfileRaw = localStorage.getItem("loggedInUser");
      const storedProfile = JSON.parse(storedProfileRaw);
      const result = {
        user: storedProfile
      };
      setTimeout(() => {
        response.next(result);
      }, 1);
    } catch (error) {
      response.error(error);
    }
    return response.asObservable().pipe(take(1));
  }

}
