
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild, OnInit } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { environment } from "../../../../environments/environment";
import { map } from "rxjs/operators";
import { ConfigService } from "src/app/common/providers/config.service";
import { AppConstants } from "src/app/common/utils/app-constants";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "vp-authentication",
  templateUrl: "./vnc-authentication.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class VNCAuthenticationComponent implements OnInit, AfterViewInit {
  backgroundImage = "url('assets/img/login-background.png')";
  serverURL = "https://contacts.vncmeet.com";
  language = "en";
  @ViewChild("urlInput", {static: false}) urlInput: ElementRef;
  constructor(private http: Http,
              private configService: ConfigService,
              private translate: TranslateService
              ) {
    this.language = navigator.language.split("-")[0];
    if (["en", "de", "fr"].indexOf(this.language) === -1) {
      this.language = "en";
    }
    const serverUrl = localStorage.getItem(AppConstants.SERVER_URL);
    if (serverUrl !== null && serverUrl !== undefined ) {
      this.serverURL = serverUrl;
    }
    setTimeout(() => {
      this.setLanguage();
    }, 2000);
  }

  static getBaseUrl() {
    const baseUrl = window.location.href;

    if (environment.isCordova) {
      return baseUrl.split("/www/")[0] + "/www";
    } else {
      return "";
    }
  }

  setLanguage() {
    this.translate.use(this.language);
    this.translate.reloadLang(this.language);
  }

  ngAfterViewInit() {
    this.setLanguage();
    console.log("[VNCAuthenticationComponent] ngAfterViewInit", new Date());
    if (typeof navigator !== "undefined" && navigator.splashscreen) {
      navigator.splashscreen.hide();
    }
    setTimeout(() => {
      this.urlInput.nativeElement.focus();
    }, 200);
  }


  getFullUrl(url: string) {
    const baseUrl = window.location.href;

    if (environment.isCordova) {
      return window.location.href.split("/www/")[0] + "/www" + url;
    } else {
      return url;
    }
  }

  ngOnInit() {
    console.log("[VNCAuthenticationComponent] ngOnInit", new Date());
  }

  loginNewServer() {
    console.log("[environment]:", environment);
    const headers = new Headers({ "Content-Type": "application/json" });
    if (this.serverURL.endsWith("/")) {
      this.serverURL = this.serverURL.substring(0, this.serverURL.lastIndexOf("/")).trim();
    }
    console.log("[Server URL] Click : ", this.serverURL);
    this.http.get(this.serverURL + "/api/config", { headers: headers }).pipe(map(response => response.json()))
      .subscribe(data => {
        if (data) {
          this.configService.API_URL = this.serverURL;
          this.setServerURL(this.configService.API_URL);
        } else {
          alert("The Server URL entered by you is wrong. Please provide correct URL");
        }
      }, () => {
        alert("The Server URL entered by you is wrong. Please provide correct URL");
      });
  }

  loginDemoServer() {
    // this.setServerURL(this.configService.DEMO_URL);
  }

  setServerURL(url) {
    if (url) {
      if (!url.startsWith("http")) {
        url = "";
      }
      this.configService.setAPIURL(url);
      let serverURL;
      // serverURL = localStorage.getItem("serverURL");
      localStorage.clear();
      localStorage.setItem(AppConstants.SERVER_URL, url.trim());
      serverURL = localStorage.getItem(AppConstants.SERVER_URL);

      if (!!serverURL && serverURL !== null) {
        console.log("Server URL config redirect to Login screen");
        this.configService.selectedServer = true;
        this.configService.loginIframe();
      } else {
        console.log("Server URL config fail");
        let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/contactplus/all")[0];
        initialHref = environment.isCordova ? initialHref.split("/contactplus")[0] : initialHref;
        window.location.href = `${initialHref}${environment.isCordova ? "/www" : ""}/index.html`;
      }
    }
  }

  backToLogin() {
    this.configService.selectedServer = true;
    this.configService.loginIframe();
  }

}
