
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export enum BroadcastKeys {
  HIDE_APP_DIALOG = "HIDE_APP_DIALOG",
  HIDE_SIDEBAR_DRAWER = "HIDE_SIDEBAR_DRAWER",
  HIDE_CREATE_CONTACT_DIALOG = "HIDE_CREATE_CONTACT_DIALOG",
  HIDE_CROPPER_DIALOG = "HIDE_CROPPER_DIALOG",
  RESET_CONTACT_LIST = "RESET_CONTACT_LIST",
  UPDATE_CONTACT = "UPDATE_CONTACT",
  HIDE_CONTACT_GROUP_CREATE_DIALOG = "HIDE_CONTACT_GROUP_CREATE_DIALOG",
  HIDE_VERSION_DIALOG = "HIDE_VERSION_DIALOG",
  HIDE_MOBILE_ABOUT_DIALOG = "HIDE_MOBILE_ABOUT_DIALOG",
  HIDE_SERVICEDESK = "HIDE_SERVICEDESK",
  HIDE_FAQ_DIALOG = "HIDE_FAQ_DIALOG",
  HIDE_LEGAL_DIALOG = "HIDE_LEGAL_DIALOG",
  HIDE_CONTACT_SETTINGS_DIALOG = "HIDE_CONTACT_SETTINGS_DIALOG",
  HIDE_PROFILE_DIALOG = "HIDE_PROFILE_DIALOG",
  RELOAD_USER_PROFILE = "RELOAD_USER_PROFILE",
  HIDE_CONTACT_DETAIL_DIALOG = "HIDE_CONTACT_DETAIL_DIALOG",
  HIDE_CONTACT_GROUP_DETAIL_DIALOG = "HIDE_CONTACT_GROUP_DETAIL_DIALOG",
  SUCCESS_CREATE_UDATE_CONTACT_REQUEST = "SUCCESS_CREATE_UDATE_CONTACT_REQUEST",
  HIDE_CONFIRMATION_DIALOG = "HIDE_CONFIRMATION_DIALOG",
  HIDE_CREATE_CONTACT_FOLDER = "HIDE_CREATE_CONTACT_FOLDER",
  HIDE_COLOR_DIALOG = "HIDE_COLOR_DIALOG",
  HIDE_EXPORT_CONTACT_DIALOG = "HIDE_EXPORT_CONTACT_DIALOG",
  HIDE_CREATE_TAG_DIALOG = "HIDE_CREATE_TAG_DIALOG",
  HIDE_UNDO_CHANGES_DIALOG = "HIDE_UNDO_CHANGES_DIALOG",
  HIDE_IMPORT_CONTACT_DIALOG = "HIDE_IMPORT_CONTACT_DIALOG",
  HIDE_SEARCH_CONTACT_COMPONENT = "HIDE_SEARCH_CONTACT_COMPONENT",
  DUPLICATE_CONTACT_COUNT = "DUPLICATE_CONTACT_COUNT",
  HIDE_GROUP_NAME_DIALOG = "HIDE_GROUP_NAME_DIALOG",
  HIDE_CONTACT_LISTS_DAILOG = "HIDE_CONTACT_LISTS_DAILOG",
  HIDE_CONTACT_LISTS_OPERATION_DAILOG = "HIDE_CONTACT_LISTS_OPERATION_DAILOG",
  HIDE_ADD_TO_LISTS_DAILOG = "HIDE_ADD_TO_LISTS_DAILOG",
  HIDE_MOBILE_ACTIONS_DIALOG = "HIDE_MOBILE_ACTIONS_DIALOG",
  HIDE_CONTACT_TAGS_DAILOG = "HIDE_CONTACT_TAGS_DAILOG",
  HIDE_SEACH_IN_APPS_DIALOG = "HIDE_SEACH_IN_APPS_DIALOG",
  HIDE_ADVANCE_SEARCH_DIALOG = "HIDE_ADVANCE_SEARCH_DIALOG",
  HIDE_SEACH_IN_LISTS_DIALOG = "HIDE_SEACH_IN_LISTS_DIALOG",
  HIDE_CONTACT_GROUP_DIALOG = "HIDE_CONTACT_GROUP_DIALOG",
  HIDE_MOBILE_CONTACT_GROUP_DETAIL_DIALOG = "HIDE_MOBILE_CONTACT_GROUP_DETAIL_DIALOG",
  SHOW_HAMBURGER_MENU = "SHOW_HAMBURGER_MENU",
  HIDE_CONTACT_LIST_DIALOG = "HIDE_CONTACT_LIST_DIALOG",
  CONTACT_CONTEXT_MENU_OPERATION = "CONTACT_CONTEXT_MENU_OPERATION",
  HIDE_CONTACT_TAG_DIALOG = "HIDE_CONTACT_TAG_DIALOG",
  HIDE_ADD_TO_TAGS_DAILOG = "HIDE_ADD_TO_TAGS_DAILOG",
  HIDE_SEACH_IN_TAGS_DIALOG = "HIDE_SEACH_IN_TAGS_DIALOG",
  HIDE_SAVE_SEARCH_NAME_DIALOG = "HIDE_SAVE_SEARCH_NAME_DIALOG",
  HIDE_SAVE_SEARCH_DAILOG = "HIDE_SAVE_SEARCH_DAILOG",
  LOAD_CONTACTS_FROM_STORE = "LOAD_CONTACTS_FROM_STORE",
  CLOSE_LIST_DETAIL_VIEW = "CLOSE_LIST_DETAIL_VIEW",
  HIDE_CONTACT_SAVE_SEARCH_DIALOG = "HIDE_CONTACT_SAVE_SEARCH_DIALOG",
  UPDATE_CONTACT_GROUP_MEMBER = "UPDATE_CONTACT_GROUP_MEMBER",
  DELETE_CONTACT_GROUP_MEMBER = "DELETE_CONTACT_GROUP_MEMBER",
  HIDE_SAVE_SEARCH_DAILOG_COMPONENT = "HIDE_SAVE_SEARCH_DAILOG_COMPONENT",
  HIDE_TFA_SETTINGS_DIALOG = "HIDE_TFA_SETTINGS_DIALOG",
  HIDE_RECENT_ACTIVITY_DIALOG = "HIDE_RECENT_ACTIVITY_DIALOG",
  SAVE_ADVANCE_SEARCH_RESULT = "SAVE_ADVANCE_SEARCH_RESULT",
  FAVORITE_UNFAVORITE_CONTACT_RESPONSE = "FAVORITE_UNFAVORITE_CONTACT_RESPONSE",
  HIDE_SHARE_CONTACT_EMAIL = "HIDE_SHARE_CONTACT_EMAIL",
  CREATE_UPDATE_REQUEST_FAIL = "CREATE_UPDATE_REQUEST_FAIL"
}
