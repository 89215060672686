
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, NgZone, ChangeDetectorRef } from "@angular/core";
import { Subject } from "rxjs";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ContactRootState, getContactFolders } from "src/app/contacts/store";
import { Store } from "@ngrx/store";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "vp-contact-lists-operation-dialog",
    templateUrl: "./contact-lists-operation-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ContactListsOperationDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    constructor(
        public dialogRef: MatDialogRef<ContactListsOperationDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_CONTACT_LISTS_OPERATION_DAILOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    close(): void {
        this.dialogRef.close();
    }

    listOperation(operation: string): void {
        this.dialogRef.close({ operation: operation });
    }
}