
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
    Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy,
    ElementRef, ViewChild, NgZone, AfterViewInit
} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";

@Component({
    selector: "vp-save-search-name-dialog",
    templateUrl: "./save-search-name-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveSearchNameDialogComponent implements OnDestroy, AfterViewInit {

    saveSearchTitle: string = "";
    private isAlive$ = new Subject<boolean>();
    @ViewChild("saveSearchInput", { static: false }) saveSearchInput: ElementRef<HTMLInputElement>;

    constructor(
        private dialogRef: MatDialogRef<SaveSearchNameDialogComponent>,
        private changeDetectionRef: ChangeDetectorRef,
        private broadcaster: Broadcaster,
        private ngZone: NgZone
    ) {

        this.broadcaster.on<any>(BroadcastKeys.HIDE_SAVE_SEARCH_NAME_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });

        setTimeout(() => {
            if (this.saveSearchInput) {
                this.saveSearchInput.nativeElement.focus();
            }
        }, 500);

        setTimeout(() => {
            this.changeDetectionRef.detectChanges();
        }, 50);
    }

    closeDialog(): void {
        this.close();
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    ngAfterViewInit() {
        this.changeDetectionRef.detectChanges();
    }

    close(): void {
        this.dialogRef.close();
    }

    createSaveSearch(): void {
        this.dialogRef.close({ saveName: this.saveSearchTitle});
    }
}
