
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, NgZone, ChangeDetectorRef, ElementRef, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ContactRootState, getContactFolders, getContactTags } from "src/app/contacts/store";
import { Store } from "@ngrx/store";
import { takeUntil, take } from "rxjs/operators";
import { ContactFolder } from "src/app/contacts/models/create-folder.model";
import { CreateFolderComponent } from "src/app/contacts/components/create-contact-folder/create-folder.component";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import * as _ from "lodash";
import { ContactTag } from "src/app/contacts/models/contact-tag.model";
import { CreateTagComponent } from "src/app/contacts/components/create-contact-tag-dialog/create-contact-tag.component";

@Component({
    selector: "vp-contact-add-to-tag-dialog",
    templateUrl: "./add-to-tag-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToTagDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    contactTags: ContactTag[] = [];
    searchText: string = "";
    selectedTags: ContactTag[] = [];
    @ViewChild("searchTextInput", { static: false }) searchTextInput: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<AddToTagDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private changeDetectionRef: ChangeDetectorRef,
        private store: Store<ContactRootState>,
        private matDialog: MatDialog
    ) { }

    ngOnInit() {
        this.store.select(getContactTags).pipe(takeUntil(this.isAlive$)).subscribe((contactTags: ContactTag[]) => {
            this.contactTags = contactTags;
            this.changeDetectionRef.markForCheck();
        });
        this.broadcaster.on<any>(BroadcastKeys.HIDE_ADD_TO_TAGS_DAILOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    addTag(): void {
        const dialog = this.matDialog.open(CreateTagComponent, {
            width: "325px",
            height: "212px",
            autoFocus: true,
            panelClass: "create-tag-dialog"
        });
        dialog.afterClosed().pipe(take(1)).subscribe(res => {
            if (!!res && res.tag && res.tag !== "") {
                this.contactTags.push({
                    id: new Date().getTime(),
                    name: res.tag,
                    contacts_count: 0
                });
                this.changeDetectionRef.markForCheck();
            }
        });
    }

    emptySearchText(): void {
        this.searchText = "";
        this.changeDetectionRef.markForCheck();
    }

    close(): void {
        this.dialogRef.close();
    }

    addListToContact(): void {
        console.log("[selectedLists]: ", this.selectedTags);
        this.dialogRef.close({ list: this.selectedTags });
    }

    isChecked(list: ContactFolder): boolean {
        let checked: boolean = false;
        if (!!this.selectedTags && this.selectedTags.length > 0) {
            const folder = this.selectedTags.filter(f => f.id === list.id)[0];
            if (!!folder) {
                checked = true;
            }
        }
        return checked;
    }

    changeItem(event: any, list: ContactFolder): void {
        if (event.checked) {
            this.selectedTags.push(list);
        } else {
            const index = _.findIndex(this.selectedTags, { id: list.id });
            if (index !== -1) {
                this.selectedTags.splice(index, 1);
            }
        }
    }
}
