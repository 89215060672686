
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, OnDestroy, ChangeDetectionStrategy, Component, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RootState, getSearchInLists } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { ContactFolder } from "src/app/contacts/models/create-folder.model";
import { getContactFolders } from "src/app/contacts/store";
import { SelectionModel } from "@angular/cdk/collections";
import { SetSearchInLists } from "src/app/actions/app";
import * as _ from "lodash";

@Component({
    selector: "vp-search-in-lists-dialog",
    templateUrl: "./search-in-lists-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchInListsDialogComponent implements OnDestroy, OnInit {

    private isAlive$ = new Subject<boolean>();
    searchLists: ContactFolder[] = [];
    allLists: ContactFolder[] = [];
    selectType = "all-list";
    isChangeValue: boolean = false;

    constructor(
        private dialogRef: MatDialogRef<SearchInListsDialogComponent>,
        private broadcaster: Broadcaster,
        private store: Store<RootState>,
        private changeDetectionRef: ChangeDetectorRef
    ) {
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_SEACH_IN_LISTS_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(presence => {
            this.close();
        });
        this.store.select(getContactFolders).pipe(takeUntil(this.isAlive$)).subscribe((contactFolders: ContactFolder[]) => {
            this.allLists = contactFolders;
            this.changeDetectionRef.markForCheck();
        });
        this.store.select(getSearchInLists).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.searchLists = res;
            if (!!this.searchLists && this.searchLists.length === 0) {
                this.selectType = "all-list";
            } else {
                this.selectType = "lists";
            }
            this.changeDetectionRef.markForCheck();
        });

    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    itemSelectionToggle(folder: ContactFolder, ev: any): void {
        if (!this.isSelected(folder)) {
            this.searchLists.push(folder);
        } else {
            const index = _.findIndex(this.searchLists, {id: folder.id});
            this.searchLists.splice(index, 1);
        }
        this.isChangeValue = true;
        this.changeDetectionRef.markForCheck();
    }

    submit(): void {
        const selectedLists = this.searchLists;
        this.broadcaster.broadcast("SEARCH_LIST_ITEMS", {
            data: selectedLists
        });
        if (this.selectType === "all-list") {
            this.store.dispatch(new SetSearchInLists([]));
        } else {
            this.store.dispatch(new SetSearchInLists(selectedLists));
        }
        this.close();
    }

    radioButtonChange(ev: any): void {
        this.isChangeValue = true;
        this.changeDetectionRef.markForCheck();
    }

    isSelected(list: ContactFolder): boolean {
        let isSelectedFolder: boolean = false;
        const folder = this.searchLists.filter(f => f.id === list.id)[0];
        if (!!folder) {
            isSelectedFolder = true;
        }
        return isSelectedFolder;
    }

}